import { useQuery } from "react-query";

import userAPIService from "../services/api/user";
import abilityService from "../services/ability";

function getPermissions() {
  return userAPIService.getPermissions();
}

export function usePermissionsQuery() {
  return useQuery(
    "permissions",
    () => {
      return getPermissions().then((permissions) => {
        abilityService.updateAbility(permissions);
        return permissions;
      });
    },
    {
      staleTime: Infinity,
      manual: true,
    },
  );
}
