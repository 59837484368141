import { all, cancel, fork, take } from "redux-saga/effects";

import { principalSaga } from "../principal";
import { skuMappingSaga } from "../skuMapping";
import { subscriptionsSaga } from "../subscriptions";
import { metadataSaga } from "../metadata";
import { offeringsSaga } from "../offerings";
import { manageUsersSaga } from "../manageUsers";
import { corporateBuySaga } from "../corporate-buy";
import { currentPrincipalSaga } from "../user";
import { accountSaga } from "../account";
import { usageSaga } from "../usage";
import { oidcSaga } from "../oidc";
import { billingHistorySaga } from "../billingHistory";
import {
  corporateBuyPlans,
  offering,
  principalDatabase,
} from "../../config/router/routes";
import { LOCATION_CHANGE, LOCATION_INITIALIZE } from "../location";

const sagas = [
  { task: principalSaga, path: principalDatabase.path },
  { task: offeringsSaga, path: offering.path },
  { task: corporateBuySaga, path: corporateBuyPlans.path },
];

function* rootSaga(): Generator {
  yield all([
    fork(metadataSaga),
    fork(currentPrincipalSaga),
    fork(subscriptionsSaga),
    fork(accountSaga),
    fork(usageSaga),
    fork(skuMappingSaga),
    fork(manageUsersSaga),
    fork(oidcSaga),
    fork(billingHistorySaga),
  ]);

  const init: any = yield take(LOCATION_INITIALIZE);
  let watcher: any;
  let task = sagas.find((item) => {
    return init.payload.pathname.toLowerCase().includes(item.path);
  })?.task;

  watcher = task ? yield fork(task) : null;

  while (true) {
    const action: any = yield take(LOCATION_CHANGE);
    const location = action.payload;

    if (watcher) {
      yield cancel(watcher);
    }

    task = sagas.find((item) => {
      return location.pathname.toLowerCase().includes(item.path);
    })?.task;
    watcher = task ? yield fork(task) : null;
  }
}

export default rootSaga;
