import { ActionTypes } from "./types";
import {
  FETCH_OPTIONS_FAILURE,
  FETCH_OPTIONS_REQUEST,
  FETCH_OPTIONS_SUCCESS,
  FETCH_OPTIONS_TRIGGER,
  UPDATE_OPTIONS_FAILURE,
  UPDATE_OPTIONS_REQUEST,
  UPDATE_OPTIONS_SUCCESS,
  UPDATE_OPTIONS_TRIGGER,
} from "./constants";
import {
  IOptionsResponse,
  IUpdateOptionsRequest,
} from "../../services/api/account/types";

export const fetchOptionsTrigger = (payload: string): ActionTypes => {
  return {
    type: FETCH_OPTIONS_TRIGGER,
    payload,
  };
};

export const fetchOptionsRequest = (): ActionTypes => {
  return {
    type: FETCH_OPTIONS_REQUEST,
  };
};

export const fetchOptionsSuccess = (payload: IOptionsResponse): ActionTypes => {
  return {
    type: FETCH_OPTIONS_SUCCESS,
    payload,
  };
};

export const fetchOptionsFailure = (payload: any): ActionTypes => {
  return {
    type: FETCH_OPTIONS_FAILURE,
    payload,
  };
};

export const updateOptionsTrigger = ({
  limit,
  firewall,
  onSuccess,
  onError,
  serviceCode,
}: IUpdateOptionsRequest): ActionTypes => {
  return {
    type: UPDATE_OPTIONS_TRIGGER,
    payload: { limit, firewall, serviceCode },
    onSuccess,
    onError,
  };
};

export const updateOptionsRequest = (): ActionTypes => {
  return {
    type: UPDATE_OPTIONS_REQUEST,
  };
};

export const updateOptionsSuccess = (
  payload: IOptionsResponse,
): ActionTypes => {
  return {
    type: UPDATE_OPTIONS_SUCCESS,
    payload,
  };
};

export const updateOptionsFailure = (payload: any): ActionTypes => {
  return {
    type: UPDATE_OPTIONS_FAILURE,
    payload,
  };
};
