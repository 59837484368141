import { ISubscriptionLabel } from "../types/common";

export const RECURLY = "RECURLY";
export const GOOGLE_PLAY_STORE = "GOOGLE_PLAY_STORE";
export const APPLE_APP_STORE = "APPLE_APP_STORE";
export const BDB_GF = "BDB_GF";

export const EXTERNAL_SP_LIST = [GOOGLE_PLAY_STORE, APPLE_APP_STORE];

export const SUBSCRIPTION_LABEL: Record<string, ISubscriptionLabel> = {
  VYPRVPN: {
    logo: "vyprvpn",
    label: "vyprvpn",
  },
  VYPRVPN_CORPORATE: {
    logo: "vyprvpn",
    label: "vyprvpn",
  },
  GIGANEWS: {
    logo: "giganews",
    label: "giganews",
  },
  TEXASNET: {
    logo: "texasnet",
    label: "texasnet",
  },
};
