import React, { ComponentType, FC } from "react";

import { GuardProps } from "./types";

export { Authenticated } from "./Authenticated";
export { Unauthenticated } from "./Unauthenticated";

export const withGuard = (
  Guard: ComponentType,
  options?: Partial<GuardProps>,
) => {
  return (Component: ComponentType) => {
    const WithGuard: FC = (props) => {
      return (
        <Guard {...options}>
          <Component {...props} />
        </Guard>
      );
    };

    return WithGuard;
  };
};
