import pickBy from "lodash/pickBy";

import { IRootState } from "../reducers";
import { IFilter, IPaging, IState } from "./types";
import { createSelector } from "reselect";
import {
  ICustomerPrincipal,
  ILicenseResponse,
} from "../../services/api/user/types";
import {
  GroupFormatter,
  RequestFormatter,
  TextRequestFormatter,
} from "../../services/request-formatter";
import {
  containsParam,
  getParam,
  getSortParams,
} from "../../services/api/utils";
import { TableHeaderSort } from "../../components/Table/components/TableHeader/types";

export const selectManageUsersState = (state: IRootState): IState => {
  return state.manageUsers;
};

export const selectCustomerPrincipalList = createSelector(
  [selectManageUsersState],
  (manageUsersState: IState): ICustomerPrincipal[] => {
    return manageUsersState.items || [];
  },
);

export const selectIsFetching = createSelector(
  [selectManageUsersState],
  (manageUsersState: IState): boolean => {
    return manageUsersState.isFetching;
  },
);

export const selectCustomerId = createSelector(
  [selectManageUsersState],
  (manageUsersState: IState): string => {
    return manageUsersState.customerId;
  },
);

export const selectLicense = createSelector(
  [selectManageUsersState],
  (manageUsersState: IState): ILicenseResponse | undefined => {
    return manageUsersState.license;
  },
);

export const selectTotalLicense = createSelector(
  [selectLicense],
  (license): number => {
    return license?.license_limit || 0;
  },
);

export const selectAssignedLicenses = createSelector(
  [selectLicense],
  (license): number => {
    if (!license) {
      return 0;
    }

    return license.licenses.length;
  },
);
export const selectAvailableLicense = createSelector(
  [selectLicense, selectAssignedLicenses],
  (license, assigned): number => {
    if (!license) {
      return 0;
    }

    return license.license_limit - assigned;
  },
);

export const selectPaging = createSelector(
  [selectManageUsersState],
  (manageUsersState: IState): IPaging => {
    return manageUsersState.paging;
  },
);

export const selectPage = createSelector(
  [selectPaging],
  (paging: IPaging): number => {
    return paging.page;
  },
);

export const selectPages = createSelector(
  [selectPaging],
  (paging: IPaging): number => {
    return paging.pages;
  },
);

export const selectSort = createSelector(
  [selectManageUsersState],
  (manageUsersState: IState): TableHeaderSort => {
    return manageUsersState.sort;
  },
);

export const selectFilter = createSelector(
  [selectManageUsersState],
  (manageUsersState: IState): IFilter => {
    return manageUsersState.filter;
  },
);

export const selectFormattedSearch = createSelector(
  [selectFilter],
  (filter): string => {
    return new RequestFormatter([
      new GroupFormatter(
        [
          new TextRequestFormatter({
            field: "name",
            value: containsParam(filter.search.value),
          }),
          new TextRequestFormatter({
            field: "email",
            value: containsParam(filter.search.value),
          }),
        ],
        "OR",
      ),
      new TextRequestFormatter({
        field: "customer_role",
        value: getParam(filter.role),
      }),
    ]).toString();
  },
);

export const selectPayload = createSelector(
  [selectFormattedSearch, selectPaging, selectSort],
  (query: string, { pageSize, page }, { field, direction }) => {
    return pickBy({
      q: encodeURIComponent(query),
      limit: pageSize,
      offset: (page + 1) * pageSize - pageSize,
      sort: getSortParams(direction, field),
    });
  },
);
