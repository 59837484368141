import { IEventEmitter, IListener } from "./types";

export class EventEmitter implements IEventEmitter {
  private listeners: Set<IListener> | null = new Set();

  subscribe(listener: IListener) {
    if (this.listeners) {
      this.listeners.add(listener);
    }
    const unsubscribe = () => {
      this.removeListener(listener);
    };
    return unsubscribe;
  }

  emit(value?: any) {
    if (this.listeners) {
      this.listeners.forEach((listener) => {
        listener(value);
      });
    }
  }

  dispose() {
    this.listeners = null;
  }

  private removeListener(listener: IListener) {
    if (this.listeners) {
      this.listeners.delete(listener);
    }
  }
}
