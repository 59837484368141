import { useMemo } from "react";

import { IBillingInfo } from "../services/api/user/types";

export function usePaymentIcon(billingInfo: IBillingInfo | undefined) {
  return useMemo(() => {
    return billingInfo?.payment_info.payment_method
      .toLowerCase()
      .split("_")
      .join("");
  }, [billingInfo]);
}
