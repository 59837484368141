import { TokenPayload } from "@recurly/recurly-js";
import { useRecurly } from "@recurly/react-recurly";

export const useRecurlyToken = () => {
  const recurly = useRecurly();

  return (form: HTMLFormElement): Promise<TokenPayload> => {
    return new Promise((resolve, reject) => {
      recurly.token(form, (error: any, token) => {
        if (error) {
          reject(error);
        } else {
          resolve(token);
        }
      });
    });
  };
};
