import { IState } from "./types";

export const METADATA_UPDATE = "metadata/update";
export const FETCH_REQUEST = "metadata/fetchRequest";
export const FETCH_SUCCESS = "metadata/fetchSuccess";
export const FETCH_FAILURE = "metadata/fetchFailure";
export const FETCH_PRODUCTS_REQUEST = "metadata/fetchProductsRequest";
export const FETCH_PRODUCTS_SUCCESS = "metadata/fetchProductsSuccess";
export const FETCH_PRODUCTS_FAILURE = "metadata/fetchProductsFailure";
export const FETCH_PRODUCT_LINES_REQUEST = "metadata/fetchProductLinesRequest";
export const FETCH_PRODUCT_LINES_SUCCESS = "metadata/fetchProductLinesSuccess";
export const FETCH_PRODUCT_LINES_FAILURE = "metadata/fetchProductLinesFailure";
export const SET_SETTINGS = "metadata/setSettings";
export const SET_CAPTCHAS = "metadata/setCaptchas";
export const SET_SPS = "metadata/setSPS";
export const SET_CURRENT_PRODUCT_LINE = "metadata/setCurrentProductLine";
export const UPDATE_CURRENT_PRODUCT_LINE = "metadata/updateCurrentProductLine";

export const initialState: IState = {
  tenantCode: "all",
  tenants: [],
  isFetching: false,
  error: undefined,
  products: [],
  productLines: [],
  CaptchasList: [],
  SPSList: [],
  currentProductLine: undefined,
  brandCode: undefined,
  brandCodeName: undefined,
  settings: undefined,
};
