import React, { FC, useEffect, useRef, useState } from "react";
import { usePopper } from "react-popper";
import isNull from "lodash/isNull";

import { TooltipProps } from "./types";

import "./styles.scss";

export const Tooltip: FC<TooltipProps> = ({
  placement = "top",
  skidding = 0,
  distance = 12,
  children,
  activator,
  showOnClick,
}) => {
  const [isActive, setIsActive] = useState(false);
  const referenceElement = useRef<HTMLSpanElement>(null);
  const popperElement = useRef<HTMLDivElement>(null);
  const [arrowElement, setArrowElement] = useState<HTMLDivElement | null>();
  const { styles, attributes } = usePopper(
    referenceElement.current,
    popperElement.current,
    {
      placement,
      strategy: "fixed",
      modifiers: [
        { name: "arrow", options: { element: arrowElement } },
        {
          name: "offset",
          options: {
            offset: [skidding, distance],
          },
        },
      ],
    },
  );

  const handleMouseOver = () => {
    if (!showOnClick) {
      setIsActive(true);
    }
  };

  const handleMouseLeave = () => {
    if (!showOnClick) {
      setIsActive(false);
    }
  };

  const handleClick = () => {
    if (!showOnClick || isActive) {
      return;
    }

    setIsActive(true);
  };

  const onClickOutside = (event: MouseEvent) => {
    if (isNull(popperElement) || isNull(popperElement.current)) {
      return;
    }

    if (!popperElement.current.contains(event.target as HTMLElement)) {
      document.removeEventListener("click", onClickOutside);
      setIsActive(false);
    }
  };

  useEffect(() => {
    if (showOnClick && isActive) {
      document.addEventListener("click", onClickOutside);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isActive, showOnClick]);

  return (
    <>
      <span
        ref={referenceElement}
        onMouseOver={handleMouseOver}
        onMouseLeave={handleMouseLeave}
        onClick={handleClick}
      >
        {activator}
      </span>

      {isActive ? (
        <div
          className="ams-tooltip__content"
          ref={popperElement}
          style={styles.popper}
          {...attributes.popper}
        >
          {children}
          <div
            className="ams-tooltip__arrow"
            ref={setArrowElement}
            style={styles.arrow}
          />
        </div>
      ) : null}
    </>
  );
};
