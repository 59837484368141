import React, { ChangeEvent, FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import Select from "../../../components/Select";
import {
  fetchDataRequest,
  selectTenantCode,
  selectTenants,
  updateMetadata,
} from "../../../store/metadata";

export const TenantSelect: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const tenantCode = useSelector(selectTenantCode);
  const tenants = useSelector(selectTenants);

  // eslint-disable-next-line @typescript-eslint/camelcase
  const options = [{ tenant_code: "all", tenant_name: t("all") }, ...tenants];

  useEffect(() => {
    dispatch(fetchDataRequest());
  }, [dispatch]);

  const selectChangeEventHandler = (event: ChangeEvent<HTMLSelectElement>) => {
    dispatch(updateMetadata({ tenantCode: event.target.value }));
  };

  return (
    <div className={"ams-header__select-wrapper"}>
      <label className={"ams-header__select-label"}>{t("tenant")}</label>
      <Select
        className="mb-0"
        onChange={selectChangeEventHandler}
        defaultValue={tenantCode}
      >
        {options.map((item: { tenant_code: string; tenant_name: string }) => {
          return (
            <option key={item.tenant_code} value={item.tenant_code}>
              {item.tenant_name}
            </option>
          );
        })}
      </Select>
    </div>
  );
};
