import { API } from "../api";
import { url as URL } from "../constants";
import {
  IDefaultOfferingResponse,
  IProductLine,
  IProductLineResponse,
} from "./types";
import { validateArgs } from "../utils";

export class ProductLineAPIService {
  constructor(private api: API) {}

  public async getAll(params?: any): Promise<IProductLine[]> {
    const { data: productLines } = await this.api.get<IProductLineResponse>(
      URL.productLine.base,
      {
        params,
      },
    );

    return productLines.items;
  }

  async setDefault(
    productLineCode: string,
    offeringCode: string,
  ): Promise<IDefaultOfferingResponse> {
    validateArgs(productLineCode);
    /* eslint-disable @typescript-eslint/camelcase */
    const { data } = await this.api.patch<IDefaultOfferingResponse>(
      URL.productLine.default.replace(":productLineCode", productLineCode),
      { default_offering_code: offeringCode },
    );

    return data;
  }
}
