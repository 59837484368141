/* eslint-disable @typescript-eslint/camelcase */
import { ActionTypes, IState } from "./types";
import {
  FETCH_CLEAR_FEATURE_DETAILS,
  FETCH_DETAILS_SUCCESS,
  FETCH_FAILURE,
  FETCH_FEATURE_DETAILS_SUCCESS,
  FETCH_REQUEST,
  FETCH_SUCCESS,
  FILTER_RESET,
  FILTER_TEXT_UPDATE,
  FILTER_UPDATE,
  PAGINATION_UPDATE,
  RESET_ALL,
  SORT_UPDATE,
  initialState,
} from "./constants";

const reducer = (prevState: IState, action: ActionTypes) => {
  const state = prevState || initialState;

  switch (action.type) {
    case FILTER_UPDATE:
      return { ...state, ...action.payload, page: 0 };
    case FILTER_TEXT_UPDATE:
      return { ...state, filterText: action.payload, page: 0 };
    case FILTER_RESET:
      return {
        ...state,
        filterText: { value: "", valid: false },
        spCode: "all",
        productCode: "all",
        status: "active",
        page: 0,
      };
    case PAGINATION_UPDATE:
      return { ...state, ...action.payload };
    case SORT_UPDATE:
      return { ...state, sort: action.payload };
    case FETCH_REQUEST:
      return { ...state, isFetching: true };
    case FETCH_SUCCESS: {
      const {
        items,
        paging: { page, per_page, total_items },
      } = action.payload;
      return {
        ...state,
        isFetching: false,
        data: items,
        page: page - 1,
        pageSize: per_page,
        pages: Math.ceil(total_items / per_page),
        error: initialState.error,
      };
    }
    case FETCH_DETAILS_SUCCESS:
      return {
        ...state,
        currentSKUDetails: action.payload,
      };
    case FETCH_FEATURE_DETAILS_SUCCESS:
      return { ...state, featureSKUDetails: action.payload };
    case FETCH_CLEAR_FEATURE_DETAILS:
      return { ...state, featureSKUDetails: undefined };
    case FETCH_FAILURE:
      return { ...state, isFetching: false, error: action.payload };
    case RESET_ALL:
      return { ...state, ...initialState };
    default:
      return state;
  }
};

export default reducer;
