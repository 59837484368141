import React, { FC } from "react";
import classnames from "classnames";

import ButtonBase from "../ButtonBase";
import { IButtonBaseProps } from "../ButtonBase/types";

import "./styles.scss";

export const ButtonPrimary: FC<IButtonBaseProps> = ({
  className,
  children,
  ...props
}) => {
  return (
    <ButtonBase
      className={classnames("ams-button--primary", className)}
      {...props}
    >
      {children}
    </ButtonBase>
  );
};
