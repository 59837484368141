import { useQuery } from "react-query";

import brandAPIService from "../services/api/brand";
import { BrandCode, TenantCode } from "../models/brand";

function fetchOriginInfoData(key: string) {
  return brandAPIService.get();
}

export function useOriginInfoQuery(tenantCode?: TenantCode | BrandCode) {
  return useQuery(["originInfo"], fetchOriginInfoData);
}
