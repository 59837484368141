import { BrandCode, BrandType } from "../../models/brand";

export const BRAND_MAP: Record<BrandCode, BrandType> = {
  GF: {
    code: "GF",
    kind: "Tenant",
  },
  GN: {
    code: "GN",
    kind: "Tenant",
  },
  TN: {
    code: "TN",
    kind: "Tenant",
  },
  ADMIN: {
    code: "ADMIN",
    kind: "Admin",
  },
};
