import React, { FC } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Header from "../../components/Header";
import BaseLayout from "../BaseLayout";
import Sidebar from "../Sidebar";
import AdminHeader from "../AdminHeader";
import { getNavItems } from "./config";
import Banner from "../../components/Banner";
import { PrincipalLayoutProps } from "./types";
import { LOCK_CUSTOMER, LOCK_PRINCIPAL } from "../../constants/common";
import { useAbility } from "../../hooks";

import "./styles.scss";

export const PrincipalLayout: FC<PrincipalLayoutProps> = ({
  children,
  customerStatus,
  principalStatus,
  isCorporate,
  onChangeStatus,
}) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const ability = useAbility();

  const navItems = getNavItems(id).filter((navItem) => {
    return navItem?.route?.isRedirectAllowed({
      ability,
    });
  });

  const isCustomerLocked = customerStatus === LOCK_CUSTOMER;
  const isPrincipalLocked = principalStatus === LOCK_PRINCIPAL;

  return (
    <BaseLayout className="ams-layout--principal">
      <AdminHeader
        customerStatus={customerStatus}
        principalStatus={principalStatus}
        isCorporate={isCorporate}
        onChangeStatus={onChangeStatus}
      />
      {isCustomerLocked ? (
        <Banner type="warning">{t("customerHasBeenLocked")}</Banner>
      ) : null}
      {isPrincipalLocked && !isCustomerLocked ? (
        <Banner type="warning">{t("principalHasBeenLocked")}</Banner>
      ) : null}
      <Header />
      <div className="ams-layout__container">
        <Sidebar className="ams-layout__sidebar" navItems={navItems} />
        <main className="ams-layout__content">{children}</main>
      </div>
    </BaseLayout>
  );
};
