import { API } from "../api";
import { url } from "../constants";
import {
  IStanding,
  IStandingHeaders,
  IStandingParams,
  IStandingRequest,
  IStandingResponse,
  IStandingResponseData,
} from "./types";
import { getETagCode, validateArgs } from "../utils";

export class StandingAPIService {
  constructor(private api: API) {}

  async getAll(params?: IStandingParams): Promise<IStandingResponseData> {
    const response = await this.api.get<IStandingResponse>(url.standing.all, {
      params,
    });

    return {
      items: response.data.items,
    };
  }

  async getStandingHeaders(standingId: string): Promise<IStandingHeaders> {
    const response = await this.api.get(
      url.standing.base.replace(":standingId", standingId),
    );

    return {
      headers: {
        eTag: response?.headers?.etag,
      },
    };
  }

  async create(data: IStandingRequest) {
    const { data: standing } = await this.api.post<IStanding>(
      url.standing.all,
      data,
    );

    return standing;
  }

  async delete(standingId: string, ifMatchOption = "") {
    validateArgs(standingId);
    const etag = getETagCode(ifMatchOption);

    if (etag) {
      this.api.addHeader("If-Match", etag);
    } else {
      this.api.addHeader("If-None-Match", "*");
    }

    const {
      data: { items },
    } = await this.api.delete(
      url.standing.base.replace(":standingId", standingId),
    );

    if (etag) {
      this.api.removeHeader("If-Match");
    } else {
      this.api.removeHeader("If-None-Match");
    }

    return items;
  }
}
