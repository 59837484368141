import React, { FC } from "react";
import { Trans, useTranslation } from "react-i18next";

import ButtonBase from "../../../../components/ButtonBase";
import { Modal } from "../../../../components/Modal/Modal";
import { IThanksModalProps } from "./types";

import "./styles.scss";

export const ThanksModal: FC<IThanksModalProps> = ({
  isOpen,
  onDismiss,
  title,
}) => {
  const { t } = useTranslation();

  return (
    <Modal
      id="save-subscription-modal"
      className="ams-thanks-modal"
      isOpen={isOpen}
    >
      <div className="ams-thanks-modal__container">
        <div className="ams-thanks-modal__thanks">
          <div className="ams-thanks-modal__thanks-logo" />

          <div className="ams-thanks-modal__thanks-content">
            <h2 className="fonts--h1-font-size">
              {title ? title : t("saveOffer.thanksForSticking")}
            </h2>

            <p>
              <Trans i18nKey="saveOffer.congratsText">
                We’re glad you decided to stay - enjoy your
                <b>25% discount!</b>
                Please let us know if there’s anything we can do your experience
                more enjoyable.
              </Trans>
            </p>

            <ButtonBase
              onClick={onDismiss}
              className="ams-button--full-width ams-button--large ams-thanks-modal__btn-full ams-thanks-modal__btn-return"
            >
              {t("saveOffer.returnToSubscription")}
            </ButtonBase>
          </div>
        </div>
      </div>
    </Modal>
  );
};
