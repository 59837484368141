import { ACTION, SUBJECT } from "../../constants/permissions";
import { Ability } from "../../models/permissions";
import { IRouteConfig, RouteVisibility } from "./types";

export const isNotEmployee = (ability: Ability): boolean => {
  return (
    ability.can(ACTION.WRITE, SUBJECT.BILLING) ||
    ability.can(ACTION.WRITE, SUBJECT.MANAGE) ||
    ability.can(ACTION.WRITE, SUBJECT.OWNER) ||
    ability.can(ACTION.WRITE, SUBJECT.ADMIN) ||
    ability.can(ACTION.READ, SUBJECT.TECHNICAL)
  );
};

export const isUserRole = (ability: Ability): boolean => {
  return !ability.rules.length;
};

export function createRouteConfig(options: {
  path: IRouteConfig["path"];
  layout: IRouteConfig["layout"];
  component: IRouteConfig["component"];
  visibility?: IRouteConfig["visibility"];
  isRedirectAllowed?: IRouteConfig["isRedirectAllowed"];
  routes?: any[];
  exact?: boolean;
}): IRouteConfig {
  return {
    visibility: RouteVisibility.All,
    exact: true,
    isRedirectAllowed() {
      return true;
    },
    ...options,
  };
}
