import { ActionTypes } from "./types";
import {
  FETCH_PLANS_FAILURE,
  FETCH_PLANS_REQUEST,
  FETCH_PLANS_SUCCESS,
} from "./constants";
import { ICustomerSKUDetails } from "../../services/api/offering/types";

export const fetchPlansFailure = (error: any): ActionTypes => {
  return {
    type: FETCH_PLANS_FAILURE,
    payload: error,
  };
};

export const fetchPlansRequest = (offeringCode: string): ActionTypes => {
  return {
    type: FETCH_PLANS_REQUEST,
    payload: offeringCode,
  };
};

export const fetchPlansSuccess = (data: ICustomerSKUDetails[]): ActionTypes => {
  return {
    type: FETCH_PLANS_SUCCESS,
    payload: data,
  };
};
