import React, { FC } from "react";
import classnames from "classnames";
import { DialogContent, DialogOverlay } from "@reach/dialog";
import "@reach/dialog/styles.css";

import { IModalProps } from "./types";
import LoaderOverlay from "../LoaderOverlay";

import "./styles.scss";

export const Modal: FC<IModalProps> = (props) => {
  const { isOpen, ariaLabel, id, onDismiss, isLoading } = props;
  return (
    <DialogOverlay
      isOpen={isOpen}
      className="flex flex-align-center flex-justify-center ams-overlay"
      onDismiss={onDismiss}
    >
      <DialogContent
        className={classnames("ams-modal", props.className)}
        id={id}
        aria-label={ariaLabel}
      >
        <>
          {isLoading && <LoaderOverlay />}
          {props.children}
        </>
      </DialogContent>
    </DialogOverlay>
  );
};

Modal.defaultProps = {
  isOpen: false,
  ariaLabel: "modal",
};
