import {
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  AxiosStatic,
} from "axios";

import { IAPIConfig, IAPIRequestConfig, IAPIResponse } from "./types";
import toaster from "../toaster";
import { RESPONSE_ERROR_KEY } from "../../utils/common/contants";

export class API {
  private instance: AxiosInstance;

  constructor(axios: AxiosStatic, config: IAPIConfig) {
    this.instance = axios.create({ baseURL: config.baseURL });
    this.initInterceptors(config);
  }

  public addHeader(name: string, value: string) {
    this.instance.defaults.headers.common[name] = value;
  }

  public removeHeader(name: string) {
    delete this.instance.defaults.headers.common[name];
  }

  public get<T = any>(
    url: string,
    config?: IAPIRequestConfig,
  ): Promise<IAPIResponse<T>> {
    return this.instance.get(url, config);
  }

  public delete(
    url: string,
    config?: IAPIRequestConfig,
  ): Promise<IAPIResponse> {
    return this.instance.delete(url, config);
  }

  public post<T = any>(
    url: string,
    data: any,
    config?: IAPIRequestConfig,
  ): Promise<IAPIResponse<T>> {
    return this.instance.post(url, data, config);
  }

  public put<T = any>(
    url: string,
    data: any,
    config?: IAPIRequestConfig,
  ): Promise<IAPIResponse<T>> {
    return this.instance.put(url, data, config);
  }

  public patch<T = any>(
    url: string,
    data: any,
    config?: IAPIRequestConfig,
  ): Promise<IAPIResponse<T>> {
    return this.instance.patch(url, data, config);
  }

  public useRequestInterceptor(
    onFulfilled?: (
      value: AxiosRequestConfig,
    ) => AxiosRequestConfig | Promise<AxiosRequestConfig>,
    onRejected?: (error: any) => any,
  ): number {
    return this.instance.interceptors.request.use(onFulfilled, onRejected);
  }

  public useResponseInterceptor(
    onFulfilled?: (
      value: AxiosResponse,
    ) => AxiosResponse | Promise<AxiosResponse>,
    onRejected?: (error: any) => any,
  ): number {
    return this.instance.interceptors.response.use(onFulfilled, onRejected);
  }

  public removeRequestInterceptor(id: number) {
    this.instance.interceptors.request.eject(id);
  }

  public removeResponseInterceptor(id: number) {
    this.instance.interceptors.response.eject(id);
  }

  private initInterceptors(apiConfig: IAPIConfig) {
    this.instance.interceptors.request.use(
      async (config) => {
        const accessToken =
          config.headers.Authorization || (await apiConfig.getAccessToken());
        if (accessToken) {
          Object.assign(config.headers, {
            Authorization: `Bearer ${accessToken}`,
          });
        }
        return config;
      },
      async (error) => {
        return Promise.reject(error);
      },
    );
    this.instance.interceptors.response.use(
      async (response) => {
        if (response.status === 403) {
          toaster.error({ content: "Forbidden" });
        }
        return response;
      },
      async (error) => {
        if (error.response.status === 401) {
          if (error.response.statusText === RESPONSE_ERROR_KEY.Unauthorized) {
            window.location.replace(
              `/error?errorCode=${error.response.statusText}`,
            );
          }
        }
        return Promise.reject(error);
      },
    );
  }
}
