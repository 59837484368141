import React, { FC } from "react";

import Overlay from "../Overlay";
import { LoaderOverlayPros } from "./types";
import { LoaderSpiner } from "../LoaderSpiner/LoaderSpiner";

export const LoaderOverlay: FC<LoaderOverlayPros> = ({ className }) => {
  return (
    <Overlay className={className}>
      <LoaderSpiner />
    </Overlay>
  );
};
