import React, { FC } from "react";

import Icon from "../Icon";
import { ToasterProps } from "./types";

import "./styles.scss";

export const Toaster: FC<ToasterProps> = ({ children, icon }) => {
  return (
    <div className="ams-toaster">
      {icon ? <Icon className="ams-toaster__icon">{icon}</Icon> : null}
      {children}
    </div>
  );
};
