import { useQuery } from "react-query";

import standingAPIService from "../services/api/standing";
import {
  GroupFormatter,
  RequestFormatter,
  TextRequestFormatter,
} from "../services/request-formatter";
import {
  IStandingIdQuery,
  IStandingParams,
} from "../services/api/standing/types";

function fetchStandings(
  key: string,
  { principalId, customerId }: IStandingIdQuery,
) {
  const params: IStandingParams = {};

  const query = new RequestFormatter([
    new GroupFormatter(
      [
        new TextRequestFormatter({
          field: "entity_id",
          value: principalId,
        }),
        new TextRequestFormatter({
          field: "entity_id",
          value: customerId,
        }),
      ],
      "OR",
    ),
  ]).toString();
  params.q = query;

  return standingAPIService.getAll(params);
}

export function useStandingByIdQuery({
  principalId,
  customerId,
}: IStandingIdQuery) {
  return useQuery(
    !!customerId && !!principalId && ["standings", { principalId, customerId }],
    fetchStandings,
  );
}
