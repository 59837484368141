import loadable from "@loadable/component";

import {
  LAYOUT,
  fallback,
  oidcFallback,
  oidcSignoutFallback,
  publicFallback,
  subscriptionFallback,
} from "./constants";
import { RouteVisibility } from "./types";
import { ACTION, SUBJECT } from "../../constants/permissions";
import { THIRD_PARTY_OFFERING } from "../../services/api/subscription/types";
import { isNotEmployee, isUserRole } from "./utils";
import { BRAND_MAP } from "../../utils/tenant/constants";
import { createRouteConfig } from "./utils";
import {
  principalDatabaseViewProfile,
  principalViewSubscription,
} from "./principal";

export const oidcSigninCallback = createRouteConfig({
  path: "/oidc-signin-callback",
  visibility: RouteVisibility.All,
  layout: LAYOUT.base,
  component: loadable(
    () => {
      return import(
        /* webpackChunkName: "oidc-signin-callback" */
        /* webpackPrefetch: true */
        "../../pages/OidcSigninCallback"
      );
    },
    { fallback: oidcFallback() },
  ),
});

export const oidcSignoutCallback = createRouteConfig({
  path: "/oidc-signout-callback",
  visibility: RouteVisibility.All,
  layout: LAYOUT.base,
  component: loadable(
    () => {
      return import(
        /* webpackChunkName: "oidc-signout-callback" */
        /* webpackPrefetch: true */
        "../../pages/OidcSignoutCallback"
      );
    },
    { fallback: oidcSignoutFallback },
  ),
});

export const oidcSilentRenew = createRouteConfig({
  path: "/oidc-silent-renew",
  visibility: RouteVisibility.All,
  layout: LAYOUT.base,
  component: loadable(
    () => {
      return import(
        /* webpackChunkName: "oidc-silent-renew" */
        /* webpackPrefetch: true */
        "../../pages/OidcSilentRenew"
      );
    },
    { fallback: oidcFallback() },
  ),
});

export const resetPassword = createRouteConfig({
  path: "/reset-password",
  visibility: RouteVisibility.Public,
  layout: LAYOUT.public,
  component: loadable(
    () => {
      return import(
        /* webpackChunkName: "reset-password" */
        /* webpackPrefetch: true */
        "../../pages/ResetPassword"
      );
    },
    { fallback: publicFallback },
  ),
});

export const confirmPasswordRecovery = createRouteConfig({
  path: "/recovery",
  visibility: RouteVisibility.Public,
  layout: LAYOUT.public,
  component: loadable(
    () => {
      return import(
        /* webpackChunkName: "confirm-password-recovery" */
        /* webpackPrefetch: true */
        "../../pages/ConfirmPasswordRecovery"
      );
    },
    { fallback: publicFallback },
  ),
});

export const accountOverview = createRouteConfig({
  path: "/overview",
  visibility: RouteVisibility.Private,
  layout: LAYOUT.private,
  component: loadable(
    () => {
      return import(
        /* webpackChunkName: "account" */
        /* webpackPrefetch: true */
        "../../pages/Account"
      );
    },
    { fallback },
  ),
  isRedirectAllowed({ ability }) {
    return isNotEmployee(ability) || isUserRole(ability);
  },
});

export const profile = createRouteConfig({
  path: "/profile",
  visibility: RouteVisibility.Private,
  layout: LAYOUT.private,
  component: loadable(
    () => {
      return import(
        /* webpackChunkName: "profile" */
        /* webpackPrefetch: true */
        "../../pages/Profile"
      );
    },
    { fallback },
  ),
  isRedirectAllowed({ ability }) {
    return isNotEmployee(ability) || isUserRole(ability);
  },
});

export const principalDatabase = createRouteConfig({
  path: "/principal-database",
  visibility: RouteVisibility.Private,
  layout: LAYOUT.private,
  component: loadable(
    () => {
      return import(
        /* webpackChunkName: "principal-database" */
        /* webpackPrefetch: true */
        "../../pages/PrincipalDB"
      );
    },
    { fallback },
  ),
  isRedirectAllowed({ ability }) {
    return ability.can(ACTION.READ, SUBJECT.USER);
  },
});

export const principalDatabaseView = createRouteConfig({
  path: "/principal-database/:id",
  visibility: RouteVisibility.Private,
  layout: LAYOUT.principal,
  routes: [principalDatabaseViewProfile, principalViewSubscription],
  component: loadable(
    () => {
      return import(
        /* webpackChunkName: "principal-database-view" */
        /* webpackPrefetch: true */
        "../../pages/Principal"
      );
    },
    { fallback },
  ),
  isRedirectAllowed({ ability }) {
    return ability.can(ACTION.READ, SUBJECT.USER);
  },
});

export const skuMapping = createRouteConfig({
  path: "/sku-mapping",
  visibility: RouteVisibility.Private,
  layout: LAYOUT.private,
  component: loadable(
    () => {
      return import(
        /* webpackChunkName: "sku-mapping" */
        /* webpackPrefetch: true */
        "../../pages/SKUMapping"
      );
    },
    { fallback },
  ),
  isRedirectAllowed({ ability }) {
    return ability.can(ACTION.READ, SUBJECT.PRODUCT);
  },
});

export const offering = createRouteConfig({
  path: "/offering",
  visibility: RouteVisibility.Private,
  layout: LAYOUT.private,
  component: loadable(
    () => {
      return import(
        /* webpackChunkName: "offering" */
        /* webpackPrefetch: true */
        "../../pages/Offering"
      );
    },
    { fallback },
  ),
  isRedirectAllowed({ ability }) {
    return ability.can(ACTION.READ, SUBJECT.PRODUCT);
  },
});

export const subscriptions = createRouteConfig({
  path: "/subscriptions",
  visibility: RouteVisibility.Private,
  layout: LAYOUT.private,
  component: loadable(
    () => {
      return import(
        /* webpackChunkName: "subscriptions" */
        /* webpackPrefetch: true */
        "../../pages/Subscriptions"
      );
    },
    { fallback: subscriptionFallback },
  ),
  isRedirectAllowed({ currentSubscription, ability }) {
    const offeringCode = currentSubscription?.offering_code || "";
    return (
      ability.can(ACTION.WRITE, SUBJECT.BILLING) &&
      !THIRD_PARTY_OFFERING.includes(offeringCode)
    );
  },
});

export const buy = createRouteConfig({
  path: "/buy",
  visibility: RouteVisibility.All,
  layout: LAYOUT.base,
  component: loadable(
    () => {
      return import(
        /* webpackChunkName: "buy" */
        /* webpackPrefetch: true */
        "../../pages/Buy"
      );
    },
    { fallback },
  ),
});

export const paymentInformation = createRouteConfig({
  path: "/payment-information",
  visibility: RouteVisibility.Private,
  layout: LAYOUT.private,
  component: loadable(
    () => {
      return import(
        /* webpackChunkName: "subscription" */
        /* webpackPrefetch: true */
        "../../pages/PaymentInformation"
      );
    },
    { fallback },
  ),
  isRedirectAllowed({ currentSubscription, ability }) {
    const offeringCode = currentSubscription?.offering_code || "";
    return (
      ability.can(ACTION.WRITE, SUBJECT.BILLING) &&
      !THIRD_PARTY_OFFERING.includes(offeringCode)
    );
  },
});

export const corporateBuyPlans = createRouteConfig({
  path: "/corporate-buy-plans",
  visibility: RouteVisibility.All,
  layout: LAYOUT.base,
  component: loadable(
    () => {
      return import(
        /* webpackChunkName: "corporate-buy-plans" */
        /* webpackPrefetch: true */
        "../../pages/CorporateBuyPlans"
      );
    },
    { fallback },
  ),
});

export const corporateBuy = createRouteConfig({
  path: "/corporate-buy",
  visibility: RouteVisibility.All,
  layout: LAYOUT.base,
  component: loadable(
    () => {
      return import(
        /* webpackChunkName: "corporate-buy-plans" */
        /* webpackPrefetch: true */
        "../../pages/CorporateBuy"
      );
    },
    { fallback },
  ),
});

export const manageUsers = createRouteConfig({
  path: "/manage-users",
  visibility: RouteVisibility.Private,
  layout: LAYOUT.private,
  component: loadable(
    () => {
      return import(
        /* webpackChunkName: "redeem" */
        /* webpackPrefetch: true */
        "../../pages/ManageUsers"
      );
    },
    { fallback },
  ),
  isRedirectAllowed({ currentSubscription, ability, customer }) {
    const offeringCode = currentSubscription?.offering_code || "";
    return (
      ability.can(ACTION.READ, SUBJECT.TECHNICAL) &&
      Boolean(customer?.is_corporate) &&
      !THIRD_PARTY_OFFERING.includes(offeringCode)
    );
  },
});

export const manageServices = createRouteConfig({
  path: "/manage-services",
  visibility: RouteVisibility.Private,
  layout: LAYOUT.private,
  component: loadable(
    () => {
      return import(
        /* webpackChunkName: "manage-services" */
        /* webpackPrefetch: true */
        "../../pages/ManageServices"
      );
    },
    { fallback },
  ),
  isRedirectAllowed({ currentSubscription, brandCode }) {
    const isExpired = currentSubscription?.will_expire_at
      ? new Date(currentSubscription?.will_expire_at).getTime() <=
        new Date().getTime()
      : false;
    return (
      brandCode === "GN" &&
      !isExpired &&
      !!currentSubscription?.is_good_standing
    );
  },
});

export const services = createRouteConfig({
  path: "/services",
  visibility: RouteVisibility.Private,
  layout: LAYOUT.private,
  component: loadable(
    () => {
      return import(
        /* webpackChunkName: "services" */
        /* webpackPrefetch: true */
        "../../pages/Services"
      );
    },
    { fallback },
  ),
  isRedirectAllowed({ brandCode }) {
    return brandCode === BRAND_MAP.GF.code;
  },
});

export const errorPage = createRouteConfig({
  path: "/error",
  visibility: RouteVisibility.Public,
  layout: LAYOUT.public,
  component: loadable(
    () => {
      return import(
        /* webpackChunkName: "error" */
        /* webpackPrefetch: true */
        "../../pages/Error"
      );
    },
    { fallback: publicFallback },
  ),
});
