import { IRequestFormatter } from "./types";

export class RequestFormatter {
  private source: IRequestFormatter[];

  constructor(source: IRequestFormatter[]) {
    this.source = source;
  }

  toString(): string {
    return this.source
      .reduce<Array<string>>((query, formatter) => {
        query.push(formatter.toString());

        return query;
      }, [])
      .filter((item) => {
        return item;
      })
      .join(",");
  }
}
