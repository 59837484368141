import React, { FC } from "react";
import classnames from "classnames";

import { IModalContentProps } from "./types";

export const ModalContent: FC<IModalContentProps> = (props) => {
  return (
    <div className={classnames("ams-modal__content", props.className)}>
      {props.children}
    </div>
  );
};
