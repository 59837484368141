import { IState } from "./types";

export const FETCH_DATA_REQUEST = "subscriptions/fetchDataRequest";
export const FETCH_DATA_FAILURE = "subscriptions/fetchDataFailure";
export const FETCH_CURRENT_SUBSCRIPTION =
  "subscriptions/fetchActiveSubscription";
export const SET_CURRENT_SUBSCRIPTION_SUCCESS =
  "subscriptions/setActiveSubscription";
export const REMOVE_ACTIVE_SUBSCRIPTION =
  "subscriptions/removeActiveSubscription";
export const SET_OFFERINGS_DETAIL = "subscriptions/setOfferingDetails";
export const FETCH_SUBSCRIPTION_LIST = "subscriptions/fetchSubscriptionList";
export const SET_SUBSCRIPTION_LIST = "subscriptions/setSubscriptionList";
export const RECYCLE_SUBSCRIPTION = "subscriptions/recycleSubscription";
export const RECYCLE_SUBSCRIPTION_SUCCESS =
  "subscriptions/recycleSubscriptionSuccess";
export const RECYCLE_SUBSCRIPTION_ERROR =
  "subscriptions/recycleSubscriptionError";
export const FETCH_OPTIONS = "subscriptions/fetchOptions";
export const FETCH_OPTIONS_SUCCESS = "subscriptions/fetchOptionsSuccess";
export const FETCH_OPTIONS_ERROR = "subscriptions/fetchOptionsError";
export const EDIT_OPTIONS = "subscriptions/editOptions";
export const EDIT_OPTIONS_SUCCESS = "subscriptions/editOptionsSuccess";
export const EDIT_OPTIONS_ERROR = "subscriptions/editOptionsError";
export const FETCH_SUBSCRIPTION_PREVIEW =
  "subscriptions/fetchSubscriptionPreview";
export const FETCH_SUBSCRIPTION_PREVIEW_TRIGGER =
  "subscriptions/fetchSubscriptionPreviewTrigger";
export const FETCH_UPDATE_SUBSCRIPTION_PREVIEW_TRIGGER =
  "subscriptions/fetchUpdateSubscriptionPreviewTrigger";
export const SET_SUBSCRIPTION_PREVIEW = "subscriptions/setSubscriptionPreview";
export const RESET_SUBSCRIPTION_PREVIEW =
  "subscriptions/resetSubscriptionPreview";
export const FETCH_SUBSCRIPTION_PREVIEW_ERROR =
  "subscriptions/setSubscriptionPreviewError";
export const UPDATE_SUBSCRIPTION = "subscriptions/updateSubscription";
export const UPDATE_SUBSCRIPTION_SUCCESS =
  "subscriptions/updateSubscriptionSuccess";
export const UPDATE_SUBSCRIPTION_ERROR =
  "subscriptions/updateSubscriptionError";
export const CREATE_SUBSCRIPTION = "subscriptions/createSubscription";
export const CREATE_SUBSCRIPTION_SUCCESS =
  "subscriptions/createSubscriptionSuccess";
export const CREATE_SUBSCRIPTION_ERROR =
  "subscriptions/createSubscriptionError";
export const CANCEL_SUBSCRIPTION = "subscriptions/cancelSubscription";
export const CANCEL_SUBSCRIPTION_SUCCESS =
  "subscriptions/cancelSubscriptionSuccess";
export const CANCEL_SUBSCRIPTION_ERROR =
  "subscriptions/cancelSubscriptionError";
export const UNCANCEL_SUBSCRIPTION = "subscriptions/uncancelSubscription";
export const UNCANCEL_SUBSCRIPTION_SUCCESS =
  "subscriptions/uncancelSubscriptionSuccess";
export const UNCANCEL_SUBSCRIPTION_ERROR =
  "subscriptions/uncancelSubscriptionError";
export const SET_NO_CURRENT_SUBSCRIPTION =
  "subscriptions/setNoCurrentSubscription";
export const CLEAR_SUBSCRIPTIONS_STATE =
  "subscriptions/clearSubscriptionsState";
export const PAUSE_SUBSCRIPTION = "subscriptions/pauseSubscription";
export const UNPAUSE_SUBSCRIPTION = "subscriptions/unpauseSubscription";
export const RESET_PAUSE_SUBSCRIPTION = "subscriptions/resetPauseSubscription";

export const initialState: IState = {
  subscriptionList: [],
  currentSubscriptionDetail: undefined,
  isFetching: false,
  isPauseSubscriptionFetching: false,
  isFetchingOptions: false,
  isPreviewFetching: false,
  isUpdateFetching: false,
  isCreateFetching: false,
  isCancelFetching: false,
  isUncancelFetching: false,
  isRecycleFetching: false,
  error: undefined,
  offeringDetails: undefined,
  subscriptionPreview: undefined,
  isCurrentSubscriptionDetailLoaded: false,
  coupon: undefined,
};
