import React, { FC } from "react";

import { LoaderProps } from "./types";

import "./styles.scss";

export const OfferingLoader: FC<LoaderProps> = ({ className }) => {
  return (
    <>
      <div className="subscription-loader__item offering-loader">
        <div className="subscription-loader__item-header loading">
          <div className="subscription-loader__item-title" />
          <div className="subscription-loader__item-button" />
        </div>
        <div className="subscription-loader__item-title-content" />
        <div className="subscription-loader__item-text-content" />
      </div>
      <div className="subscription-loader__item">
        <div className="subscription-loader__item-header loading">
          <div className="subscription-loader__item-title" />
          <div className="subscription-loader__item-button" />
        </div>
        <div className="subscription-loader__item-title-content" />
        <div className="subscription-loader__item-text-content" />
      </div>
      <div className="subscription-loader__item">
        <div className="subscription-loader__item-header loading">
          <div className="subscription-loader__item-title" />
          <div className="subscription-loader__item-button" />
        </div>
        <div className="subscription-loader__item-title-content" />
        <div className="subscription-loader__item-text-content" />
      </div>
    </>
  );
};
