import React, { FC, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";

import { useUserManager } from "../../services/auth";
import Header from "../../components/Header";
import Navbar, { Button } from "../../components/Navbar";
import BaseLayout from "../BaseLayout";
import Sidebar from "../Sidebar";
import TenantSelect from "./TenantSelect";
import { getNavItems } from "./config";
import { useAbility } from "../../hooks";
import { selectPaymentFailureStanding } from "../../store/standing";
import PaymentBanner from "./PaymentBanner";
import MenuToggle from "./MenuToggle";
import Username from "./Username";
import { supportLinks } from "./PaymentBanner/config";
import {
  fetchProductLinesRequest,
  selectBrandCode,
} from "../../store/metadata";
import { selectCustomerData, selectPrincipalData } from "../../store/user";
import RemoveLicenseBanner from "./RemoveLicenseBanner";
import {
  selectCurrentCustomerSubscription,
  selectCurrentSubscriptionDetail,
  selectFeatureLicenses,
  selectIsShouldUnassignedLicences,
} from "../../store/subscriptions";
import { selectAssignedLicenses } from "../../store/manageUsers";
import { selectProfile } from "../../store/oidc";
import { getSubscriptionStatus } from "../../utils/common";
import { SP_STATES } from "../../services/api/subscription/constants";
import { HeaderLoader } from "../../components/Loader/HeaderLoader/HeaderLoader";
import { SUBSCRIPTION_STATUS } from "../../utils/common/contants";

import "./styles.scss";

export const PrivateLayout: FC = ({ children }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const userManager = useUserManager();
  const ability = useAbility();
  const { pathname } = useLocation();
  const paymentFailureStanding = useSelector(selectPaymentFailureStanding);
  const [supportLink, setSupportLink] = useState("");
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isShowHeaderLoader, setIsShowHeaderLoader] = useState(true);
  const [isShowSidebarLoader, setIsShowSidebarLoader] = useState(true);
  const userData = useSelector(selectPrincipalData);
  const customer = useSelector(selectCustomerData);
  const profile = useSelector(selectProfile);
  const currentSubscription = useSelector(selectCurrentCustomerSubscription);
  const brand = useSelector(selectBrandCode);
  const isAdmin = brand === "ADMIN";
  const subscriptionDetails = useSelector(selectCurrentSubscriptionDetail);
  const shouldRemoveLicence = useSelector(selectIsShouldUnassignedLicences);
  const featureLicenses = useSelector(selectFeatureLicenses);
  const assignedLicenses = useSelector(selectAssignedLicenses);

  const isShowPaymentBanner = useMemo(() => {
    if (paymentFailureStanding) {
      return true;
    }

    if (subscriptionDetails) {
      const status = getSubscriptionStatus(subscriptionDetails);
      return (
        (status === SUBSCRIPTION_STATUS.active &&
          subscriptionDetails.sp.states.includes(SP_STATES.PAST_DUE)) ||
        status === SUBSCRIPTION_STATUS.paymentFailure
      );
    }

    return false;
  }, [paymentFailureStanding, subscriptionDetails]);

  useEffect(() => {
    setIsMenuOpen(false);
  }, [pathname]);

  useEffect(() => {
    if (customer) {
      dispatch(fetchProductLinesRequest());
    }
  }, [dispatch, customer]);

  const handleSignout = () => {
    localStorage.removeItem("winbackModalState");
    userManager.signoutRedirect();
  };

  const toggleMenu = () => {
    setIsMenuOpen((state) => {
      return !state;
    });
  };

  useEffect(() => {
    if (userData) {
      setSupportLink(supportLinks[userData.tenant_code]);
    }
  }, [userData]);

  useEffect(() => {
    if ((userData && profile && brand) || isAdmin) {
      setIsShowHeaderLoader(false);
    }
  }, [userData, profile, brand, isAdmin]);

  useEffect(() => {
    if ((ability && customer && brand) || isAdmin) {
      setIsShowSidebarLoader(false);
    }
  }, [ability, customer, brand, currentSubscription, isAdmin]);

  const navItems = getNavItems().filter((navItem) => {
    if (!navItem.route) {
      return true;
    }

    return navItem.route.isRedirectAllowed({
      ability,
      customer,
      brandCode: brand,
      currentSubscription: currentSubscription,
    });
  });

  return (
    <BaseLayout className="ams-layout--private">
      {shouldRemoveLicence && subscriptionDetails?.sp?.next_invoices_at ? (
        <RemoveLicenseBanner
          date={subscriptionDetails?.sp?.next_invoices_at}
          assignedLicenses={assignedLicenses}
          featureLicenses={featureLicenses}
        />
      ) : null}

      {isShowPaymentBanner && <PaymentBanner />}
      {isShowHeaderLoader ? (
        <HeaderLoader />
      ) : (
        <Header>
          <>
            {isAdmin ? <TenantSelect /> : null}
            <Navbar className="ams-header__navbar">
              <Username username={profile?.preferred_username} />
              {!isAdmin ? (
                <a
                  className="navbar__link ams-header__navbar-item"
                  href={supportLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t("help")}
                </a>
              ) : null}
              <Button
                className="ams-header__navbar-item"
                onClick={handleSignout}
              >
                {t("logout")}
              </Button>
            </Navbar>
            <MenuToggle
              className="ams-header__menu-toggle"
              onClick={toggleMenu}
              isToggled={isMenuOpen}
            />
            {isMenuOpen && (
              <div className="ams-header__mobile-menu">
                <Sidebar
                  navItems={navItems}
                  className="ams-header__menu"
                  isShowSidebarLoader={isShowSidebarLoader}
                />
                <Navbar className="ams-header__mobile-navbar">
                  <Username username={profile?.preferred_username} />
                  {!isAdmin ? (
                    <a
                      className="navbar__link ams-header__navbar-item"
                      href={supportLink}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {t("help")}
                    </a>
                  ) : null}

                  <Button
                    className="ams-header__navbar-item"
                    onClick={handleSignout}
                  >
                    {t("logout")}
                  </Button>
                </Navbar>
              </div>
            )}
          </>
        </Header>
      )}
      <div className="ams-layout__container">
        <Sidebar
          className="ams-layout__sidebar"
          navItems={navItems}
          isShowSidebarLoader={isShowSidebarLoader}
        />
        <main className="ams-layout__content">{children}</main>
      </div>
    </BaseLayout>
  );
};
