import { API } from "../api";
import { url } from "../constants";
import { IBrandResponseData } from "./types";
import { IAPIRequestConfig } from "../types";

export class BrandAPIService {
  constructor(private api: API) {}

  public async get(params?: IAPIRequestConfig) {
    const { data: brand } = await this.api.get<IBrandResponseData>(
      url.brand,
      params,
    );
    return brand.origin_info;
  }
}
