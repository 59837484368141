import { ActionTypes, IState } from "./types";
import {
  FETCH_PLANS_FAILURE,
  FETCH_PLANS_REQUEST,
  FETCH_PLANS_SUCCESS,
  initialState,
} from "./constants";

const reducer = (prevState: IState, action: ActionTypes) => {
  const state = prevState || initialState;

  switch (action.type) {
    case FETCH_PLANS_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case FETCH_PLANS_FAILURE:
      return {
        ...state,
        plans: [],
        error: action.payload,
        isFetching: false,
      };
    case FETCH_PLANS_SUCCESS:
      return {
        ...state,
        plans: action.payload,
        error: undefined,
        isFetching: false,
      };
    default:
      return state;
  }
};

export default reducer;
