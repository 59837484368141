import React, { FC } from "react";
import { Redirect } from "react-router-dom";

import { useIsAuthenticated } from "../../services/auth";
import { GuardProps } from "./types";

export const Unauthenticated: FC<GuardProps> = ({ children, fallback }) => {
  const isAuthenticated = useIsAuthenticated();

  return isAuthenticated ? <>{fallback}</> : <>{children}</>;
};

Unauthenticated.defaultProps = {
  fallback: <Redirect to="/" />,
};
