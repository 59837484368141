import { ActionTypes, IState } from "./types";
import {
  CANCEL_SUBSCRIPTION,
  CANCEL_SUBSCRIPTION_ERROR,
  CANCEL_SUBSCRIPTION_SUCCESS,
  CLEAR_SUBSCRIPTIONS_STATE,
  CREATE_SUBSCRIPTION,
  CREATE_SUBSCRIPTION_ERROR,
  CREATE_SUBSCRIPTION_SUCCESS,
  EDIT_OPTIONS,
  EDIT_OPTIONS_ERROR,
  EDIT_OPTIONS_SUCCESS,
  FETCH_DATA_FAILURE,
  FETCH_DATA_REQUEST,
  FETCH_OPTIONS,
  FETCH_OPTIONS_ERROR,
  FETCH_OPTIONS_SUCCESS,
  FETCH_SUBSCRIPTION_PREVIEW,
  FETCH_SUBSCRIPTION_PREVIEW_ERROR,
  PAUSE_SUBSCRIPTION,
  RECYCLE_SUBSCRIPTION,
  RECYCLE_SUBSCRIPTION_ERROR,
  RECYCLE_SUBSCRIPTION_SUCCESS,
  REMOVE_ACTIVE_SUBSCRIPTION,
  RESET_PAUSE_SUBSCRIPTION,
  RESET_SUBSCRIPTION_PREVIEW,
  SET_CURRENT_SUBSCRIPTION_SUCCESS,
  SET_NO_CURRENT_SUBSCRIPTION,
  SET_OFFERINGS_DETAIL,
  SET_SUBSCRIPTION_LIST,
  SET_SUBSCRIPTION_PREVIEW,
  UNCANCEL_SUBSCRIPTION,
  UNCANCEL_SUBSCRIPTION_ERROR,
  UNCANCEL_SUBSCRIPTION_SUCCESS,
  UNPAUSE_SUBSCRIPTION,
  UPDATE_SUBSCRIPTION,
  UPDATE_SUBSCRIPTION_ERROR,
  UPDATE_SUBSCRIPTION_SUCCESS,
  initialState,
} from "./constants";

const reducer = (prevState: IState, action: ActionTypes) => {
  const state = prevState || initialState;

  switch (action.type) {
    case FETCH_DATA_REQUEST:
      return { ...state, isFetching: true, error: null };
    case CREATE_SUBSCRIPTION:
      return { ...state, isCreateFetching: true };
    case CREATE_SUBSCRIPTION_SUCCESS:
    case CREATE_SUBSCRIPTION_ERROR:
      return { ...state, isCreateFetching: false };
    case UPDATE_SUBSCRIPTION:
      return { ...state, isUpdateFetching: true };
    case PAUSE_SUBSCRIPTION:
      return { ...state, isPauseSubscriptionFetching: true };
    case UNPAUSE_SUBSCRIPTION:
      return { ...state, isPauseSubscriptionFetching: true };
    case RESET_PAUSE_SUBSCRIPTION:
      return { ...state, isPauseSubscriptionFetching: false };
    case UPDATE_SUBSCRIPTION_SUCCESS:
    case UPDATE_SUBSCRIPTION_ERROR:
      return { ...state, isUpdateFetching: false };
    case RECYCLE_SUBSCRIPTION:
      return { ...state, isRecycleFetching: true };
    case RECYCLE_SUBSCRIPTION_SUCCESS:
    case RECYCLE_SUBSCRIPTION_ERROR:
      return { ...state, isRecycleFetching: false };
    case CANCEL_SUBSCRIPTION:
      return { ...state, isCancelFetching: true };
    case CANCEL_SUBSCRIPTION_SUCCESS:
    case CANCEL_SUBSCRIPTION_ERROR:
      return { ...state, isCancelFetching: false };
    case UNCANCEL_SUBSCRIPTION:
      return { ...state, isUncancelFetching: true };
    case UNCANCEL_SUBSCRIPTION_SUCCESS:
    case UNCANCEL_SUBSCRIPTION_ERROR:
      return { ...state, isUncancelFetching: false };
    case SET_SUBSCRIPTION_LIST:
      return { ...state, subscriptionList: action.payload };
    case SET_CURRENT_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        isCurrentSubscriptionDetailLoaded: true,
        currentSubscriptionDetail: action.payload,
      };
    case FETCH_OPTIONS:
    case EDIT_OPTIONS:
      return { ...state, isFetchingOptions: true };
    case FETCH_OPTIONS_SUCCESS:
      return {
        ...state,
        isFetchingOptions: false,
        subscriptionOptions: action.payload,
      };
    case FETCH_OPTIONS_ERROR:
    case EDIT_OPTIONS_SUCCESS:
    case EDIT_OPTIONS_ERROR:
      return { ...state, isFetchingOptions: false };
    case SET_OFFERINGS_DETAIL:
      return { ...state, isFetching: false, offeringDetails: action.payload };
    case REMOVE_ACTIVE_SUBSCRIPTION:
      return { ...state, offeringDetails: undefined };
    case FETCH_SUBSCRIPTION_PREVIEW:
      return { ...state, isPreviewFetching: true };
    case SET_SUBSCRIPTION_PREVIEW:
      return {
        ...state,
        subscriptionPreview: action.payload,
        isPreviewFetching: false,
      };
    case RESET_SUBSCRIPTION_PREVIEW:
      return {
        ...state,
        subscriptionPreview: initialState.subscriptionPreview,
      };
    case FETCH_SUBSCRIPTION_PREVIEW_ERROR:
      return { ...state, isPreviewFetching: false };
    case FETCH_DATA_FAILURE:
      return {
        ...state,
        error: action.payload,
        isFetching: false,
        isCurrentSubscriptionDetailLoaded: true,
      };
    case SET_NO_CURRENT_SUBSCRIPTION:
      return { ...state, isCurrentSubscriptionDetailLoaded: true };
    case CLEAR_SUBSCRIPTIONS_STATE:
      return initialState;
    default:
      return state;
  }
};

export default reducer;
