import React, { FC } from "react";

import { UsernameProps } from "./types";

export const Username: FC<UsernameProps> = ({ username }) => {
  if (!username) {
    return null;
  }

  return <span className="ams-header__navbar-item">{username}</span>;
};
