import { IState } from "./types";

export const FETCH_USAGE = "usage/fetchUsage";
export const FETCH_USAGE_SUCCESS = "usage/fetchUsageSuccess";
export const FETCH_USAGE_ERROR = "usage/fetchUsageError";

export const initialState: IState = {
  usageList: [],
  isFetching: false,
  error: undefined,
};
