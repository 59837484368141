import React, { FC } from "react";

import { LoaderProps } from "./types";

import "./styles.scss";

export const HeaderLoader: FC<LoaderProps> = () => {
  return (
    <header className="ams-header">
      <div className="ams-header__block">
        <div className="ams-header__logo-wrapper">
          <div className="ams-header__logo-loader ams-header__loader  loading" />
        </div>
        <div className="ams-header__content">
          <div className="navbar ams-header__navbar">
            <div className="ams-header__navbar-loader ams-header__loader  loading" />
          </div>
        </div>
      </div>
    </header>
  );
};
