import React, { FC } from "react";
import { Trans } from "react-i18next";
import { Link } from "react-router-dom";

import { RemoveLicenseBannerPropd } from "./types";
import Banner from "../../../components/Banner";
import { manageUsers } from "../../../config/router/routes";
import { getMonthDayYearDate } from "../../../utils/common";

export const RemoveLicenseBanner: FC<RemoveLicenseBannerPropd> = ({
  date,
  assignedLicenses,
  featureLicenses = 0,
}) => {
  const removeLicenses = assignedLicenses - featureLicenses;
  const nextInvoiceDate = getMonthDayYearDate(new Date(date));

  return (
    <Banner type="warning">
      <Trans
        i18nKey="removedAutomatically"
        tOptions={{
          date: nextInvoiceDate,
          willAvailable: featureLicenses,
          removeLicenses,
        }}
      >
        {`On ${nextInvoiceDate}, you will have ${featureLicenses} license(s) available. Please remove ${removeLicenses} license by this date or uses will be removed automatically.`}
        <Link className="pl-2xs" to={manageUsers.path}>
          Manage Users
        </Link>
      </Trans>
    </Banner>
  );
};
