import { IState } from "./types";

export const FETCH_PLANS_REQUEST = "corporate-buy/fetchPlansRequest";
export const FETCH_PLANS_FAILURE = "corporate-buy/fetchPlansFailure";
export const FETCH_PLANS_SUCCESS = "corporate-buy/fetchPlansSuccess";

export const initialState: IState = {
  plans: [],
  isFetching: false,
};
