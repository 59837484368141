import React, { FC } from "react";

import Icon from "../../../components/Icon";
import { MenuToggleProps } from "./types";
import { IconName } from "../../../components/Icon/types";

export const MenuToggle: FC<MenuToggleProps> = ({
  className,
  onClick,
  isToggled,
}) => {
  return (
    <button className={className} onClick={onClick}>
      <Icon>
        {isToggled ? "responsive-menu-close" : ("responsive-menu" as IconName)}
      </Icon>
    </button>
  );
};
