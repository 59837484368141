import { AbilityBuilder } from "@casl/ability";

import { Ability, Permissions, Subject } from "../../models/permissions";

export class AbilityService {
  private readonly ability: Ability;

  constructor(private abilityBuilder: AbilityBuilder<Ability>) {
    this.ability = this.abilityBuilder.build();
  }

  getAbility() {
    return this.ability;
  }

  updateAbility(permissions: Permissions) {
    const { can, rules } = this.abilityBuilder;

    Object.entries(permissions).forEach(([subject, actions]) => {
      if (!actions) {
        return;
      }

      actions.forEach((action) => {
        can(action, subject as Subject);
      });
    });

    this.ability.update(rules);
  }
}
