import React, { FC } from "react";
import { useTranslation } from "react-i18next";

import { ModalFooter } from "../../../../components/Modal/ModalFooter";
import { Button } from "../../../../components/Button/Button";
import { ButtonPrimary } from "../../../../components/ButtonPrimary/ButtonPrimary";
import { PAYPAL } from "../AddPaymentMethodModal/contants";
import { IPaymentInformationFooter } from "./types";

export const PaymentInformationFooter: FC<IPaymentInformationFooter> = ({
  handleCreateDismiss,
  show3DSecure,
  isAddPaymentFetching,
  paymentMethod,
  isFormValid,
  confirmButtonText,
}) => {
  const { t } = useTranslation();

  return (
    <ModalFooter>
      <Button
        onClick={handleCreateDismiss}
        className="ams-modal__action"
        id="cancel-button"
      >
        {t("cancel")}
      </Button>
      {!show3DSecure ? (
        <ButtonPrimary
          type="submit"
          disabled={!isFormValid}
          className="ams-modal__action"
          id="submit-button"
          isLoading={isAddPaymentFetching}
        >
          {paymentMethod === PAYPAL ? t("proceedToPaypal") : confirmButtonText}
        </ButtonPrimary>
      ) : null}
    </ModalFooter>
  );
};
