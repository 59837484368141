import { ActionTypes, IState } from "./types";
import {
  FETCH_FAILURE,
  FETCH_PRODUCTS_FAILURE,
  FETCH_PRODUCTS_REQUEST,
  FETCH_PRODUCTS_SUCCESS,
  FETCH_PRODUCT_LINES_FAILURE,
  FETCH_PRODUCT_LINES_REQUEST,
  FETCH_PRODUCT_LINES_SUCCESS,
  FETCH_REQUEST,
  FETCH_SUCCESS,
  METADATA_UPDATE,
  SET_CAPTCHAS,
  SET_CURRENT_PRODUCT_LINE,
  SET_SETTINGS,
  SET_SPS,
  initialState,
} from "./constants";

const reducer = (prevState: IState, action: ActionTypes) => {
  const state = prevState || initialState;

  switch (action.type) {
    case METADATA_UPDATE:
      return { ...state, ...action.payload };
    case FETCH_REQUEST:
    case FETCH_PRODUCTS_REQUEST:
    case FETCH_PRODUCT_LINES_REQUEST:
      return { ...state, isFetching: true };
    case FETCH_SUCCESS:
      return { ...state, isFetching: false, tenants: action.payload };
    case FETCH_FAILURE:
    case FETCH_PRODUCTS_FAILURE:
    case FETCH_PRODUCT_LINES_FAILURE:
      return { ...state, isFetching: false, error: action.payload };
    case FETCH_PRODUCTS_SUCCESS:
      return { ...state, isFetching: false, products: action.payload };
    case FETCH_PRODUCT_LINES_SUCCESS:
      return { ...state, productLines: action.payload, isFetching: false };
    case SET_SETTINGS:
      return { ...state, settings: action.payload };
    case SET_CAPTCHAS:
      return { ...state, CaptchasList: action.payload };
    case SET_SPS:
      return { ...state, SPSList: action.payload };
    case SET_CURRENT_PRODUCT_LINE:
      return { ...state, currentProductLine: action.payload };
    default:
      return state;
  }
};

export default reducer;
