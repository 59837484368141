import React, { FC } from "react";
import classnames from "classnames";

import { SelectProps } from "./types";

import "./styles.scss";

export const Select: FC<SelectProps> = ({ className, children, ...props }) => {
  return (
    <select className={classnames("ams-select", className)} {...props}>
      {children}
    </select>
  );
};
