import React, { FC, useEffect } from "react";
import { useSelector } from "react-redux";

import { useIsAuthenticated, useUserManager } from "../../services/auth";
import { GuardProps } from "./types";
import { useReturnToLocation } from "../../hooks/use-return-to-location";
import { selectIsUserReady } from "../../store/user";

export const Authenticated: FC<GuardProps> = ({ children, fallback }) => {
  const isAuthenticated = useIsAuthenticated();
  const isUserReady = useSelector(selectIsUserReady);
  const userManager = useUserManager();
  const { saveCurrentLocation } = useReturnToLocation();

  useEffect(() => {
    if (!isAuthenticated) {
      saveCurrentLocation();
      userManager.signinRedirect();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return isAuthenticated && isUserReady ? <>{children}</> : <>{fallback}</>;
};

Authenticated.defaultProps = {
  fallback: <div />,
};
