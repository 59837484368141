import { url as URL } from "../constants";
import { API } from "../api";
import { IPrincipalResponse } from "./types";
import { IUserResponseData } from "../user/types";

export class PrincipalAPIService {
  constructor(private api: API) {}

  public async getAll(params?: any) {
    const { data: principals } = await this.api.get<IPrincipalResponse[]>(
      URL.principal.base,
      { params },
    );
    return principals;
  }

  public async get(id: string) {
    const { data } = await this.api.get<IUserResponseData>(
      URL.principal.profile.replace(":principalId", id),
    );

    return data;
  }
}
