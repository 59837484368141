import { AxiosError } from "axios";

import {
  ActionTypes,
  ICancelSubscriptionRequestPayload,
  ICreateSubscriptionRequestPayload,
  ICurrentSubscriptionDetails,
  IEditSubscriptionOptionsPayload,
  IFetchActiveSubscriptionRequest,
  IFetchSubscriptListPayload,
  IFetchSubscriptionOptionsRequest,
  IFetchSubscriptionPreviewTriggerPayload,
  IFetchSubscriptionsData,
  IFetchUpdateSubscriptionPreviewTriggerPayload,
  IPauseSubscriptionPayload,
  IRecycleSubscriptionRequest,
  IUncancelSubscriptionPayload,
  IUpdateSubscriptionRequestPayload,
} from "./types";
import {
  CANCEL_SUBSCRIPTION,
  CANCEL_SUBSCRIPTION_ERROR,
  CANCEL_SUBSCRIPTION_SUCCESS,
  CLEAR_SUBSCRIPTIONS_STATE,
  CREATE_SUBSCRIPTION,
  CREATE_SUBSCRIPTION_ERROR,
  CREATE_SUBSCRIPTION_SUCCESS,
  EDIT_OPTIONS,
  EDIT_OPTIONS_ERROR,
  EDIT_OPTIONS_SUCCESS,
  FETCH_CURRENT_SUBSCRIPTION,
  FETCH_DATA_FAILURE,
  FETCH_DATA_REQUEST,
  FETCH_OPTIONS,
  FETCH_OPTIONS_ERROR,
  FETCH_OPTIONS_SUCCESS,
  FETCH_SUBSCRIPTION_LIST,
  FETCH_SUBSCRIPTION_PREVIEW,
  FETCH_SUBSCRIPTION_PREVIEW_ERROR,
  FETCH_SUBSCRIPTION_PREVIEW_TRIGGER,
  FETCH_UPDATE_SUBSCRIPTION_PREVIEW_TRIGGER,
  PAUSE_SUBSCRIPTION,
  RECYCLE_SUBSCRIPTION,
  RECYCLE_SUBSCRIPTION_ERROR,
  RECYCLE_SUBSCRIPTION_SUCCESS,
  REMOVE_ACTIVE_SUBSCRIPTION,
  RESET_PAUSE_SUBSCRIPTION,
  RESET_SUBSCRIPTION_PREVIEW,
  SET_CURRENT_SUBSCRIPTION_SUCCESS,
  SET_NO_CURRENT_SUBSCRIPTION,
  SET_OFFERINGS_DETAIL,
  SET_SUBSCRIPTION_LIST,
  SET_SUBSCRIPTION_PREVIEW,
  UNCANCEL_SUBSCRIPTION,
  UNCANCEL_SUBSCRIPTION_ERROR,
  UNCANCEL_SUBSCRIPTION_SUCCESS,
  UNPAUSE_SUBSCRIPTION,
  UPDATE_SUBSCRIPTION,
  UPDATE_SUBSCRIPTION_ERROR,
  UPDATE_SUBSCRIPTION_SUCCESS,
} from "./constants";
import {
  ISubscription,
  ISubscriptionOptions,
  ISubscriptionPreview,
} from "../../services/api/subscription/types";
import { IOfferingDetailsResponse } from "../../services/api/offering/types";

export const fetchDataRequest = (
  payload: IFetchSubscriptionsData,
): ActionTypes => {
  return {
    type: FETCH_DATA_REQUEST,
    payload,
  };
};

export const fetchDataFailure = (payload: any): ActionTypes => {
  return {
    type: FETCH_DATA_FAILURE,
    payload,
  };
};

export const fetchCurrentSubscription = (
  payload: IFetchActiveSubscriptionRequest,
): ActionTypes => {
  return {
    type: FETCH_CURRENT_SUBSCRIPTION,
    payload,
  };
};

export const fetchCurrentSubscriptionSuccess = (
  payload: ICurrentSubscriptionDetails,
) => {
  return {
    type: SET_CURRENT_SUBSCRIPTION_SUCCESS,
    payload,
  };
};

export const removeActiveSubscription = (): ActionTypes => {
  return {
    type: REMOVE_ACTIVE_SUBSCRIPTION,
  };
};

export const fetchSubscriptionPreviewTrigger = (
  payload: IFetchSubscriptionPreviewTriggerPayload,
  onSuccess: (payload?: ISubscriptionPreview) => void,
  onError: (error: any) => void,
): ActionTypes => {
  return {
    type: FETCH_SUBSCRIPTION_PREVIEW_TRIGGER,
    payload,
    onSuccess,
    onError,
  };
};

export const fetchUpdateSubscriptionPreviewTrigger = (
  payload: IFetchUpdateSubscriptionPreviewTriggerPayload,
  onSuccess: () => void,
  onError: (error: AxiosError) => void,
): ActionTypes => {
  return {
    type: FETCH_UPDATE_SUBSCRIPTION_PREVIEW_TRIGGER,
    payload,
    onSuccess,
    onError,
  };
};

export const fetchSubscriptionPreview = (): ActionTypes => {
  return {
    type: FETCH_SUBSCRIPTION_PREVIEW,
  };
};

export const setSubscriptionPreview = (
  payload: ISubscriptionPreview,
): ActionTypes => {
  return {
    type: SET_SUBSCRIPTION_PREVIEW,
    payload,
  };
};

export const resetSubscriptionPreview = (): ActionTypes => {
  return {
    type: RESET_SUBSCRIPTION_PREVIEW,
  };
};

export const fetchSubscriptionPreviewError = (payload?: any): ActionTypes => {
  return {
    type: FETCH_SUBSCRIPTION_PREVIEW_ERROR,
    payload,
  };
};

export const updateSubscription = (
  payload: IUpdateSubscriptionRequestPayload,
): ActionTypes => {
  return {
    type: UPDATE_SUBSCRIPTION,
    payload,
  };
};

export const updateSubscriptionSuccess = (): ActionTypes => {
  return {
    type: UPDATE_SUBSCRIPTION_SUCCESS,
  };
};

export const updateSubscriptionError = (): ActionTypes => {
  return {
    type: UPDATE_SUBSCRIPTION_ERROR,
  };
};

export const createSubscription = (
  payload: ICreateSubscriptionRequestPayload,
): ActionTypes => {
  return {
    type: CREATE_SUBSCRIPTION,
    payload,
  };
};

export const createSubscriptionSuccess = (): ActionTypes => {
  return {
    type: CREATE_SUBSCRIPTION_SUCCESS,
  };
};

export const createSubscriptionError = (): ActionTypes => {
  return {
    type: CREATE_SUBSCRIPTION_ERROR,
  };
};

export const cancelSubscription = (
  payload: ICancelSubscriptionRequestPayload,
): ActionTypes => {
  return {
    type: CANCEL_SUBSCRIPTION,
    payload,
  };
};

export const cancelSubscriptionSuccess = (): ActionTypes => {
  return {
    type: CANCEL_SUBSCRIPTION_SUCCESS,
  };
};

export const cancelSubscriptionError = (): ActionTypes => {
  return {
    type: CANCEL_SUBSCRIPTION_ERROR,
  };
};

export const uncancelSubscription = (
  payload: IUncancelSubscriptionPayload,
): ActionTypes => {
  return {
    type: UNCANCEL_SUBSCRIPTION,
    payload,
  };
};

export const uncancelSubscriptionSuccess = (): ActionTypes => {
  return {
    type: UNCANCEL_SUBSCRIPTION_SUCCESS,
  };
};

export const uncancelSubscriptionError = (): ActionTypes => {
  return {
    type: UNCANCEL_SUBSCRIPTION_ERROR,
  };
};

export const setOfferingDetails = (
  payload: IOfferingDetailsResponse,
): ActionTypes => {
  return {
    type: SET_OFFERINGS_DETAIL,
    payload,
  };
};

export const fetchSubscriptionList = (
  payload: IFetchSubscriptListPayload,
): ActionTypes => {
  return {
    type: FETCH_SUBSCRIPTION_LIST,
    payload,
  };
};

export const setSubscriptionList = (payload: ISubscription[]): ActionTypes => {
  return {
    type: SET_SUBSCRIPTION_LIST,
    payload,
  };
};

export const recycleSubscription = (
  payload: IRecycleSubscriptionRequest,
): ActionTypes => {
  return {
    type: RECYCLE_SUBSCRIPTION,
    payload,
  };
};

export const recycleSubscriptionSuccess = (): ActionTypes => {
  return {
    type: RECYCLE_SUBSCRIPTION_SUCCESS,
  };
};

export const recycleSubscriptionError = (): ActionTypes => {
  return {
    type: RECYCLE_SUBSCRIPTION_ERROR,
  };
};

export const fetchSubscriptionOptions = (
  payload: IFetchSubscriptionOptionsRequest,
): ActionTypes => {
  return {
    type: FETCH_OPTIONS,
    payload,
  };
};

export const fetchSubscriptionOptionsSuccess = (
  payload: ISubscriptionOptions,
): ActionTypes => {
  return {
    type: FETCH_OPTIONS_SUCCESS,
    payload,
  };
};

export const fetchSubscriptionOptionsError = (payload: any): ActionTypes => {
  return {
    type: FETCH_OPTIONS_ERROR,
    payload,
  };
};

export const editSubscriptionOptions = (
  payload: IEditSubscriptionOptionsPayload,
): ActionTypes => {
  return {
    type: EDIT_OPTIONS,
    payload,
  };
};

export const editSubscriptionOptionsSuccess = (): ActionTypes => {
  return {
    type: EDIT_OPTIONS_SUCCESS,
  };
};

export const editSubscriptionOptionsError = (): ActionTypes => {
  return {
    type: EDIT_OPTIONS_ERROR,
  };
};

export const setNoCurrentSubscription = (): ActionTypes => {
  return {
    type: SET_NO_CURRENT_SUBSCRIPTION,
  };
};

export const clearSubscriptionsState = (): ActionTypes => {
  return {
    type: CLEAR_SUBSCRIPTIONS_STATE,
  };
};

export const pauseSubscription = (
  payload: IPauseSubscriptionPayload,
): ActionTypes => {
  return {
    type: PAUSE_SUBSCRIPTION,
    payload,
  };
};

export const unpauseSubscription = (
  payload: IPauseSubscriptionPayload,
): ActionTypes => {
  return {
    type: UNPAUSE_SUBSCRIPTION,
    payload,
  };
};

export const resetPauseSubscription = (): ActionTypes => {
  return {
    type: RESET_PAUSE_SUBSCRIPTION,
  };
};
