import React, { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { PriceProps } from "./types";
import { getCurrencyDetails } from "../../services/currency/currency";
import { decimalsFormat, round } from "../../utils/common";

export const Price: FC<PriceProps> = ({ currencyCode, amount }) => {
  const { t } = useTranslation();

  const {
    currencySymbol,
    currency,
    isShowCurrencySymbol,
    isShowCurrencyCode,
    decimal,
  } = getCurrencyDetails(currencyCode);

  const roundedAmount = round(Math.abs(amount), decimal);
  const decimalsNumber = decimalsFormat(roundedAmount, decimal);
  const isNegativeNumber = useMemo(() => {
    return amount < 0;
  }, [amount]);

  if (!currency) {
    return null;
  }

  return (
    <span className="ams-price">
      {isNegativeNumber ? "-" : null}
      {isShowCurrencyCode && (
        <span className="ams-price_currency-code pr-2xs">
          {t(`currency.${currency}`)}
        </span>
      )}
      {isShowCurrencySymbol && (
        <span className="ams-price_currency-code">{currencySymbol}</span>
      )}
      {decimalsNumber}
    </span>
  );
};
