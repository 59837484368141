import React, { ChangeEvent, FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  CardCvvElement,
  CardMonthElement,
  CardNumberElement,
  CardNumberElementChangeEvent,
  CardYearElement,
  IndividualElementChangeEvent,
} from "@recurly/react-recurly";
import noop from "lodash/noop";

import Tooltip from "../Tooltip";
import FormGroup from "../FormGroup";
import Label from "../Label";
import Input from "../Input";
import { RECURLY_INPUT_STYLE } from "../../utils/common/contants";
import { useParsedFullName } from "../../utils/common";
import { PaymentFormContentProps } from "./types";
import Icon from "../Icon";
import { IconName } from "../Icon/types";

import "./index.scss";

export const PaymentFormContent: FC<PaymentFormContentProps> = ({
  onValidationChange,
  billingInfo,
}) => {
  const { t } = useTranslation();
  const nameOnCard =
    billingInfo && billingInfo.customer_info
      ? `${billingInfo.customer_info?.first_name} ${billingInfo.customer_info?.last_name}`
      : "";

  const [fullName, setFullName] = useState(nameOnCard);
  const [isCVVValid, setIsCVVValid] = useState(false);
  const [isMonthValid, setIsMonthValid] = useState(false);
  const [isYearValid, setIsYearValid] = useState(false);
  const [isCardValid, setIsCardValid] = useState(false);

  const onChangeName = (event: ChangeEvent<HTMLInputElement>) => {
    setFullName(event.target.value);
  };

  const [firstName = "", lastName = ""] = useParsedFullName(fullName);

  const onCVVChanged = (cvvData: IndividualElementChangeEvent) => {
    setIsCVVValid(cvvData.valid);
  };

  const onMonthChanged = (monthData: IndividualElementChangeEvent) => {
    setIsMonthValid(monthData.valid);
  };

  const onYearChanged = (yearData: IndividualElementChangeEvent) => {
    setIsYearValid(yearData.valid);
  };

  const onCardChanged = (cardData: CardNumberElementChangeEvent) => {
    setIsCardValid(cardData.valid);
  };

  const isValid =
    Boolean(firstName) &&
    Boolean(lastName) &&
    isMonthValid &&
    isYearValid &&
    isCVVValid &&
    isCardValid;

  useEffect(() => {
    onValidationChange(isValid);
  }, [isValid]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <FormGroup>
        <Label>{t("nameOnCard")}</Label>
        <Input id="input-card-name" value={fullName} onChange={onChangeName} />
      </FormGroup>

      <div className="ams-payment-form__elements--hidden">
        <Input data-recurly="first_name" value={firstName} onChange={noop} />
        <Input data-recurly="last_name" value={lastName} onChange={noop} />
      </div>

      <FormGroup className="mb-s">
        <Label>{t("cardNumber")}</Label>
        <CardNumberElement
          id="input-card-number"
          className="ams-payment-form__element"
          style={RECURLY_INPUT_STYLE}
          onChange={onCardChanged}
        />
      </FormGroup>

      <div className="ams-payment-form__row mb-l">
        <FormGroup className="mr-l">
          <Label className="ams-label__height-fixed">
            {t("expirationDate")}
          </Label>
          <div className="ams-payment-form__expiration-date">
            <CardMonthElement
              id="input-card-month"
              className="ams-payment-form__element ams-payment-form__element--short"
              inputType="select"
              style={RECURLY_INPUT_STYLE}
              onChange={onMonthChanged}
            />
            <CardYearElement
              id="input-card-year"
              className="ams-payment-form__element ams-payment-form__element--short"
              inputType="select"
              style={RECURLY_INPUT_STYLE}
              onChange={onYearChanged}
            />
          </div>
        </FormGroup>

        <FormGroup>
          <Label className="ams-label__height-fixed">
            {t("CVV")}
            <Tooltip
              showOnClick
              placement="right"
              skidding={30}
              activator={<Icon>{"help" as IconName}</Icon>}
            >
              {t("CVVHelp")}
            </Tooltip>
          </Label>
          <CardCvvElement
            id="input-card-cvv"
            className="ams-payment-form__element ams-payment-form__element--short"
            style={RECURLY_INPUT_STYLE}
            onChange={onCVVChanged}
          />
        </FormGroup>
      </div>
    </>
  );
};
