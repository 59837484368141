import {
  FETCH_USAGE,
  FETCH_USAGE_ERROR,
  FETCH_USAGE_SUCCESS,
  initialState,
} from "./constants";
import { ActionTypes, IState } from "./types";

const reducer = (prevState: IState, action: ActionTypes) => {
  const state = prevState || initialState;

  switch (action.type) {
    case FETCH_USAGE:
      return { ...state, isFetching: true };
    case FETCH_USAGE_SUCCESS:
      return { ...state, isFetching: false, usageList: action.payload };
    case FETCH_USAGE_ERROR:
      return { ...state, isFetching: false };
    default:
      return state;
  }
};

export default reducer;
