import React, { FC } from "react";
import classNames from "classnames";

import { IButtonProps } from "./types";

export const Button: FC<IButtonProps> = (props) => {
  const classes = classNames("navbar__button", props.className);

  return (
    <button className={classes} onClick={props.onClick}>
      {props.children}
    </button>
  );
};

Button.defaultProps = {
  children: null,
  onClick: () => {
    return void 0;
  },
};
