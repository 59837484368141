import React, { FC } from "react";
import classnames from "classnames";

import { IModalHeaderProps } from "./types";

export const ModalHeader: FC<IModalHeaderProps> = (props) => {
  return (
    <header className={classnames("ams-modal__header", props.className)}>
      {props.children}
    </header>
  );
};
