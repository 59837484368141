import React, { FC } from "react";
import classnames from "classnames";

import { IModalFooterProps } from "./types";

export const ModalFooter: FC<IModalFooterProps> = (props) => {
  return (
    <footer className={classnames("ams-modal__footer", props.className)}>
      {props.children}
    </footer>
  );
};
