import i18next from "i18next";

import Toaster from "../../toaster";

export type DateOperators = "<" | ">";

export const getSortParams = (direction: string, field: string): string => {
  return (direction === "asc" ? "+" : "-") + field;
};

export const getActiveParam = (status: string): string => {
  if (status === "active") {
    return "true";
  }

  if (status === "inactive") {
    return "false";
  }

  return "";
};

export const getParam = (value: string): string => {
  if (value === "all") {
    return "";
  }

  return value;
};

export const containsParam = (value: string): string => {
  if (!value) {
    return "";
  }

  return `*${value}`;
};

export const getDateParam = (value: string, option: DateOperators): string => {
  if (!value) {
    return "";
  }

  return `${option}${value}`;
};

export function validateArgs(...args: any[]) {
  const isNotValid = args.some((arg: any) => {
    return arg === null || arg === "" || arg === undefined;
  });

  if (isNotValid) {
    Toaster.error({ content: i18next.t("somethingWentWrong") });
    throw new Error("Not valid args");
  }
}

export function getETagCode(code: string) {
  if (!code || code.length <= 2) {
    return "";
  }

  if (code.startsWith("W/")) {
    return code.split("W/")[1];
  }

  return code;
}
