import { ActionTypes, IUserState } from "./types";
import {
  CLEAR_CUTOMER_DATA,
  CLEAR_PRINCIPAL_DATA,
  FETCHING_CURRENT_CUSTOMER,
  FETCHING_CURRENT_PRINCIPAL,
  FETCH_BILLING_ACCOUNT_LIST_SUCCESS,
  FETCH_BILLING_DATA_ERROR,
  FETCH_BILLING_DATA_SUCCESS,
  FETCH_CURRENT_CUSTOMER_SUCCESS,
  FETCH_CURRENT_PRINCIPAL_SUCCESS,
  REMOVE_BILLING_DATA_SUCCESS,
  SET_PAYMENT_COUNTRY,
  SET_PAYMENT_POSTAL_CODE,
  SET_PAYMENT_REGION,
  USER_FETCH_SUCCESS,
  initialState,
} from "./constants";

const reducer = (prevState: IUserState, action: ActionTypes) => {
  const state = prevState || initialState;

  switch (action.type) {
    case USER_FETCH_SUCCESS:
      return { ...state, data: action.payload, isUserReady: true };
    case FETCHING_CURRENT_CUSTOMER:
      return { ...state, isFetchingCustomer: true };
    case FETCHING_CURRENT_PRINCIPAL:
      return { ...state, isFetchingPrincipal: true };
    case FETCH_CURRENT_PRINCIPAL_SUCCESS:
      return {
        ...state,
        principalData: action.payload,
        isFetchingPrincilal: false,
      };
    case FETCH_CURRENT_CUSTOMER_SUCCESS:
      return {
        ...state,
        customerData: action.payload,
        isFetchingCustomer: false,
      };
    case FETCH_BILLING_ACCOUNT_LIST_SUCCESS:
      return {
        ...state,
        billingAccountList: action.payload,
      };
    case CLEAR_PRINCIPAL_DATA:
      return {
        ...state,
        principalData: initialState.principalData,
      };
    case CLEAR_CUTOMER_DATA:
      return {
        ...state,
        customerData: initialState.customerData,
      };
    case FETCH_BILLING_DATA_SUCCESS:
      return {
        ...state,
        billingData: action.payload,
        isBillingDataLoaded: true,
        country: undefined,
        postalCode: undefined,
        region: undefined,
      };
    case REMOVE_BILLING_DATA_SUCCESS:
    case FETCH_BILLING_DATA_ERROR:
      return {
        ...state,
        billingData: undefined,
        isBillingDataLoaded: true,
        country: undefined,
        postalCode: undefined,
        region: undefined,
      };
    case SET_PAYMENT_COUNTRY:
      return {
        ...state,
        country: action.payload,
      };
    case SET_PAYMENT_POSTAL_CODE:
      return {
        ...state,
        postalCode: action.payload,
      };
    case SET_PAYMENT_REGION:
      return {
        ...state,
        region: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
