import { API } from "../api";
import { url } from "../constants";
import { IUsageRequest, IUsageResponse } from "./types";
import { validateArgs } from "../utils";

export class UsageService {
  constructor(private api: API) {}

  async getUsageList({ customerId, subscriptionId }: IUsageRequest) {
    validateArgs(customerId, subscriptionId);
    const {
      data: { items },
    } = await this.api.get<IUsageResponse>(
      url.usage.list
        .replace(":customerId", customerId)
        .replace(":subscriptionId", subscriptionId),
    );

    return items;
  }
}
