import { IState } from "./types";

export const FETCH_TRIGGER = "offerings/fetchTrigger";
export const FETCH_REQUEST = "offerings/fetchRequest";
export const FETCH_SUCCESS = "offerings/fetchSuccess";
export const FETCH_FAILURE = "offerings/fetchFailure";
export const RESET_ALL = "offerings/resetAll";
export const PAGINATION_UPDATE = "offerings/updatePagination";
export const SORT_UPDATE = "offerings/updateSort";
export const FILTER_UPDATE = "offerings/updateFilter";
export const FILTER_TEXT_UPDATE = "offerings/updateFilterText";
export const FILTER_RESET = "offerings/resetFilter";

export const initialState: IState = {
  isFetching: false,
  data: [],
  error: undefined,
  page: 0,
  pages: 0,
  pageSize: 25,
  sort: { field: "updated_at", direction: "desc" },
  filterText: { value: "", valid: false },
  spCode: "all",
  productLineCode: "all",
};
