import React, { FC } from "react";

import { NavigationItem } from "./components/NavigationItem";
import { INavigationProps, ISidebarNavItem } from "./types";

import "./Navigation.scss";

export const Navigation: FC<INavigationProps> = (props) => {
  return (
    <nav className="navigation">
      {props.items.map((item: ISidebarNavItem, index: number) => {
        return (
          <NavigationItem
            key={item.label}
            data={item}
            topDivider={index === 0}
          />
        );
      })}
    </nav>
  );
};

Navigation.defaultProps = {
  items: [],
};
