export const NOW_TIMEFRAME = "NOW";
export const RENEWAL_TIMEFRAME = "RENEWAL";

export const FIXED_DISCOUNT_PROMOTION = "FIXED";
export const NOT_DEFINED_PROMOTION = "NOT_DEFINED";
export const PERCENT_DISCOUNT_PROMOTION = "PERCENT_DISCOUNT";
export const FREE_TRIAL_PROMOTION = "FREE_TRIAL";
export const SINGLE_USE_DURATION = "SINGLE_USE";
export const FOREVER_DURATION = "FOREVER";

export const SP_STATES: Record<string, any> = {
  CANCELED: "CANCELED",
  CHANGING: "CHANGING",
  FUTURE: "FUTURE",
  INTRO: "INTRO",
  PAST_DUE: "PAST_DUE",
  PAUSED: "PAUSED",
  PENDING: "PENDING",
  REFUNDED: "REFUNDED",
  TERMINATED: "TERMINATED",
  TRIAL: "TRIAL",
};
