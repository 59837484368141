import React, { forwardRef } from "react";
import classnames from "classnames";

import { IInputProps } from "./types";
import Alert from "../Alert";

import "./styles.scss";

export const Input = forwardRef<HTMLInputElement, IInputProps>(
  (
    {
      className,
      error,
      errorText,
      description,
      success,
      successText,
      ...props
    },
    ref,
  ) => {
    return (
      <div className={classnames("ams-input__wrapper", className)}>
        <input
          className={classnames("ams-input", {
            "ams-input--error": error,
            "ams-input__success": success,
          })}
          {...props}
          ref={ref}
        />
        {description && (
          <p className="ams-input__description ma-0 mt-xs">{description}</p>
        )}
        {!!errorText && error && (
          <Alert variant="error" className="ams-alert__form-controller-error">
            {errorText}
          </Alert>
        )}
        {!error && !!success && !!successText && (
          <Alert
            variant="success"
            className="ams-alert__form-controller-success"
          >
            {successText}
          </Alert>
        )}
      </div>
    );
  },
);
