/* eslint-disable @typescript-eslint/camelcase */
import React, { FC, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import _noop from "lodash/noop";
import { Profile } from "oidc-client";

import { IWinbackOffer } from "./type";
import { Modal } from "../../../../components/Modal/Modal";
import { ButtonBase } from "../../../../components/ButtonBase/ButtonBase";
import { ButtonText } from "../../../../components/ButtonText/ButtonText";
import { WINBACK_LIST_TEXT_KEYS } from "./constants";
import toaster from "../../../../services/toaster";
import { ModalFooter } from "../../../../components/Modal/ModalFooter";
import { Button } from "../../../../components/Button/Button";
import { ButtonPrimary } from "../../../../components/ButtonPrimary/ButtonPrimary";
import { getUserProfile } from "../../../../services/auth";
import { termsOfServiceLinks } from "../../../../layouts/PrivateLayout/PaymentBanner/config";
import { NewPlan } from "../NewPlan/NewPlan";
import {
  createSubscription,
  fetchSubscriptionPreviewTrigger,
  selectAvailableListSpListWithAddon,
  selectCurrentSubscriptionDetail,
  selectOfferingDetails,
  selectSubscriptionPreview,
} from "../../../../store/subscriptions";
import { COUPON_TYPE, PAYMENT_STATES } from "../../../../utils/common/contants";
import { selectBillingData } from "../../../../store/user";
import { EXTERNAL_SP_LIST } from "../../../../constants/spCode";
import { Title } from "../../../../components/Plan/components/Title/Title";
import { FormGroup } from "../../../../components/FormGroup/FormGroup";
import { LoaderOverlay } from "../../../../components/LoaderOverlay/LoaderOverlay";
import { selectCurrentSPS } from "../../../../store/metadata";
import { PaymentInformationHandler } from "../../../../components/PaymentInformationHandler/PaymentInformationHandler";
import Icon from "../../../../components/Icon";
import { IconName } from "../../../../components/Icon/types";
import { usePaymentIcon } from "../../../../hooks/use-payment-icon";

import "./styles.scss";

export const WinbackOffer: FC<IWinbackOffer> = ({
  onDismiss,
  isOpen,
  openThankYouModal,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const currentSPS = useSelector(selectCurrentSPS);
  const promoCode = currentSPS.winback_offer_coupon_code;

  const [profile, setProfile] = useState<Profile>();

  const subscriptionPreview = useSelector(selectSubscriptionPreview);
  const subscriptionsWithGeoCurrency = useSelector(
    selectAvailableListSpListWithAddon,
  );
  const offeringDetails = useSelector(selectOfferingDetails);

  const selectedPlan = useMemo(() => {
    return subscriptionsWithGeoCurrency[
      subscriptionsWithGeoCurrency.length - 1
    ];
  }, [subscriptionsWithGeoCurrency]);

  useEffect(() => {
    getUserProfile().then((res) => {
      setProfile(res);
    });
  }, []);

  const winbackListText = WINBACK_LIST_TEXT_KEYS.map((k) => {
    return <li key={k}>{t(`saveOffer.${k}`)}</li>;
  });

  const activeSubscription = useSelector(selectCurrentSubscriptionDetail);
  const billingInfo = useSelector(selectBillingData);

  const paymentMethodIconName = usePaymentIcon(billingInfo);

  const isExternalPayment =
    activeSubscription &&
    EXTERNAL_SP_LIST.includes(activeSubscription.sp.sp_code);
  const havePaymentMethod = billingInfo || isExternalPayment;

  const [isPaymentEdit, setIsPaymentEdit] = useState(false);

  const [paymentState, setPaymentState] = useState(
    PAYMENT_STATES.ADD_PAYMENT_METHOD,
  );

  const handleIsPaymentEdit = () => {
    setIsPaymentEdit(true);
    setPaymentState(PAYMENT_STATES.UPDATE_WINBACK_PAYMENT_METHOD);
  };
  const [isFetchingInvoices, setIsFetchingInvoices] = useState(true);
  useEffect(() => {
    offeringDetails && setIsFetchingInvoices(false);
  }, [offeringDetails]);

  const handlePaymentCreate = () => {
    setIsLoading(true);
    handleCreateSubscription();
  };

  const handlePaymentUpdate = () => {
    setIsPaymentEdit(false);
    setPaymentState(PAYMENT_STATES.ADD_PAYMENT_METHOD);
    handlePaymentCreate();
  };

  const [isLoading, setIsLoading] = useState(false);
  const onCreateSuccess = () => {
    setIsLoading(false);
    toaster.success({
      content: t("planWasPurchased"),
    });
    onDismiss();
    openThankYouModal();
  };

  const onCreateError = () => {
    setIsLoading(false);
    toaster.error({ content: t("somethingWentWrong") });
  };

  const handleCreateSubscription = () => {
    setIsLoading(true);
    const promotionToken = promoCode
      ? {
          kind: COUPON_TYPE.couponCode,
          coupon_code: promoCode,
        }
      : undefined;
    const { sku_id, geoCost } = selectedPlan;
    dispatch(
      createSubscription({
        promotionToken,
        currency: geoCost?.currency,
        skuId: sku_id,
        onSuccess: onCreateSuccess,
        onError: onCreateError,
      }),
    );
  };

  const [isRenewStarted, setIsRenewStarted] = useState(false);
  const fetchSubscriptionPreview = () => {
    if (selectedPlan) {
      const promotionToken = {
        kind: COUPON_TYPE.couponCode,
        coupon_code: promoCode,
      };

      const preview = {
        currency: selectedPlan.geoCost?.currency as string,
        skuId: selectedPlan.sku_id as string,
        offeringCode: offeringDetails?.offering_code,
        promotionToken,
      };

      dispatch(
        fetchSubscriptionPreviewTrigger(preview, _noop, () => {
          toaster.error({ content: t("paymentMethodIsRequired") });
        }),
      );
    }
  };
  const renewSubscription = () => {
    fetchSubscriptionPreview();
    setIsRenewStarted(true);
  };

  return (
    <Modal id="winback-modal" className="ams-winback-modal" isOpen={isOpen}>
      <div className="ams-winback-modal__logo-container">
        <div className="ams-winback-modal__logo-banner" />
      </div>

      <div className="ams-winback-modal__content">
        <h2>{t("saveOffer.comeBack")}</h2>

        <ul className="ams-winback-modal__list">{winbackListText}</ul>
        {isRenewStarted ? (
          <>
            {isFetchingInvoices ? (
              <LoaderOverlay className="ams-overlay--inline ams-loader--grey ams-loader--4xs" />
            ) : isPaymentEdit || !havePaymentMethod ? (
              <PaymentInformationHandler
                handlePaymentCreate={handlePaymentCreate}
                handlePaymentUpdate={handlePaymentUpdate}
                paymentState={paymentState}
                confirmButtonText={t("subscribeNow")}
                onDismiss={onDismiss}
              >
                {subscriptionPreview && (
                  <NewPlan
                    plan={selectedPlan}
                    preview={subscriptionPreview}
                    coupon={promoCode}
                    isRenew={true}
                    isWinbackDiscount={true}
                    isTitleNeeded={true}
                  />
                )}
              </PaymentInformationHandler>
            ) : (
              <>
                <Title>{t("paymentType")}</Title>
                <FormGroup className="ams-winback-modal__card">
                  <Icon className="ams-payment-icon">
                    {paymentMethodIconName as IconName}
                  </Icon>
                  {`${
                    billingInfo?.payment_info.payment_method_description ||
                    billingInfo?.payment_info.payment_method
                  } `}
                  {billingInfo?.payment_info.last_four &&
                    `....${billingInfo.payment_info.last_four}`}
                  <ButtonText onClick={handleIsPaymentEdit}>
                    {t("edit")}
                  </ButtonText>
                </FormGroup>
                {subscriptionPreview && (
                  <NewPlan
                    plan={selectedPlan}
                    preview={subscriptionPreview}
                    coupon={promoCode}
                    isRenew={true}
                    isWinbackDiscount={true}
                    isTitleNeeded={true}
                  />
                )}
                <ModalFooter>
                  <Button
                    onClick={onDismiss}
                    className="ams-modal__action"
                    id="cancel-button"
                  >
                    {t("cancel")}
                  </Button>

                  <ButtonPrimary
                    onClick={handleCreateSubscription}
                    className="ams-modal__action"
                    id="submit-button"
                    isLoading={isLoading}
                  >
                    {t("subscribeNow")}
                  </ButtonPrimary>
                </ModalFooter>
              </>
            )}
            <div className="ams-winback-modal__hint">
              {t("confirmPurchaseAgreement", { tenant: t(profile?.tenant) })}
              <a
                className="pl-2xs"
                href={termsOfServiceLinks[profile?.tenant]}
                target="_blank"
                rel="noopener noreferrer"
              >
                {t("termsOfService")}
              </a>
            </div>
          </>
        ) : (
          <div className="ams-modal--footer">
            <ButtonBase
              onClick={renewSubscription}
              className="ams-button--full-width ams-button--large ams-button--submit-discount ams-winback-modal__btn-submit"
            >
              {t("saveOffer.wantDiscount")}
            </ButtonBase>

            <ButtonText
              onClick={onDismiss}
              className="ams-winback-modal__btn-cancel"
            >
              {t("saveOffer.noThanks")}
            </ButtonText>
          </div>
        )}
      </div>
    </Modal>
  );
};
