export const FETCH_REQUEST = "manageUsers/fetchRequest";
export const FETCH_SUCCESS = "manageUsers/fetchSuccess";
export const FETCH_FAILURE = "manageUsers/fetchFailure";

export const PAGINATION_UPDATE = "manageUsers/paginationUpdate";
export const SORT_UPDATE = "manageUsers/updateSort";

export const FETCH_LICENSES_REQUEST = "manageUsers/fetchLicensesRequest";
export const FETCH_LICENSES_SUCCESS = "manageUsers/fetchLicensesSuccess";
export const FETCH_LICENSES_FAILURE = "manageUsers/fetchLicensesFailure";

export const UPDATE_FILTER = "manageUsers/updateFilter";
export const SET_CUSTOMER_ID = "manageUsers/setCustomerId";
export const UPDATE_FILTER_SEARCH = "manageUsers/updateFilterSearch";
export const RESET_FILTER = "manageUsers/resetFilter";

export const initialState = {
  isFetching: false,
  customerId: "",
  items: [],
  license: undefined,
  error: null,
  sort: { field: "name", direction: "asc" },
  filter: {
    search: { value: "", valid: false },
    role: "",
  },
  paging: {
    totalItems: 0,
    page: 0,
    pages: 0,
    pageSize: 25,
  },
};
