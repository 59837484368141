import React, { FC } from "react";
import classnames from "classnames";
import { useTranslation } from "react-i18next";

import { PeriodProps } from "./types";
import { getInterval, isOneMonth } from "../../utils/common";

export const Period: FC<PeriodProps> = ({ className, data }) => {
  const { t } = useTranslation();
  const period = data ? getInterval(data) : null;

  return period ? (
    <span className={classnames("ams-period", className)}>
      {isOneMonth(period.length)
        ? t(period.unit, { [period.unit]: period.length })
        : `${period.length} ${t(period.unit, {
            [period.unit]: period.length,
          })}`}
    </span>
  ) : null;
};
