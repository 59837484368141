import { ActionTypes, IState } from "./types";
import {
  FETCH_FAILURE,
  FETCH_PRODUCTS_FAILURE,
  FETCH_PRODUCTS_REQUEST,
  FETCH_PRODUCTS_SUCCESS,
  FETCH_PRODUCT_LINES_FAILURE,
  FETCH_PRODUCT_LINES_REQUEST,
  FETCH_PRODUCT_LINES_SUCCESS,
  FETCH_REQUEST,
  FETCH_SUCCESS,
  METADATA_UPDATE,
  SET_CAPTCHAS,
  SET_CURRENT_PRODUCT_LINE,
  SET_SETTINGS,
  SET_SPS,
  UPDATE_CURRENT_PRODUCT_LINE,
} from "./constants";
import { IProduct } from "../../services/api/product/types";
import { IProductLine } from "../../services/api/productLine/types";
import { ICaptchas, ISPS, ISettings } from "../../services/api/brand/types";

export const updateMetadata = (metadata: Partial<IState>): ActionTypes => {
  return {
    type: METADATA_UPDATE,
    payload: metadata,
  };
};

export const fetchDataRequest = (): ActionTypes => {
  return {
    type: FETCH_REQUEST,
  };
};

export const fetchDataSuccess = (payload: Array<any>): ActionTypes => {
  return {
    type: FETCH_SUCCESS,
    payload,
  };
};

export const fetchDataFailure = (payload: any): ActionTypes => {
  return {
    type: FETCH_FAILURE,
    payload,
  };
};

export const fetchProductsRequest = (): ActionTypes => {
  return {
    type: FETCH_PRODUCTS_REQUEST,
  };
};

export const fetchProductsSuccess = (payload: IProduct[]): ActionTypes => {
  return {
    type: FETCH_PRODUCTS_SUCCESS,
    payload,
  };
};

export const fetchProductsFailure = (payload: any): ActionTypes => {
  return {
    type: FETCH_PRODUCTS_FAILURE,
    payload,
  };
};

export const fetchProductLinesRequest = (): ActionTypes => {
  return {
    type: FETCH_PRODUCT_LINES_REQUEST,
  };
};

export const fetchProductLinesSuccess = (
  payload: IProductLine[],
): ActionTypes => {
  return {
    type: FETCH_PRODUCT_LINES_SUCCESS,
    payload,
  };
};

export const fetchProductLinesFailure = (payload: any): ActionTypes => {
  return {
    type: FETCH_PRODUCT_LINES_FAILURE,
    payload,
  };
};

export const setSettings = (payload: ISettings): ActionTypes => {
  return {
    type: SET_SETTINGS,
    payload,
  };
};

export const setCaptchas = (payload: ICaptchas[]): ActionTypes => {
  return {
    type: SET_CAPTCHAS,
    payload,
  };
};

export const setSPS = (payload: ISPS[]): ActionTypes => {
  return {
    type: SET_SPS,
    payload,
  };
};

export const updateCurrentProductLine = (payload: string): ActionTypes => {
  return {
    type: UPDATE_CURRENT_PRODUCT_LINE,
    payload,
  };
};

export const setCurrentProductLine = (payload: IProductLine): ActionTypes => {
  return {
    type: SET_CURRENT_PRODUCT_LINE,
    payload,
  };
};
