import { IRequestFormatter, Operations } from "./types";

export class GroupFormatter implements IRequestFormatter {
  private source: IRequestFormatter[];
  private operation: Operations;

  constructor(source: IRequestFormatter[], operation: Operations = "AND") {
    this.source = source;
    this.operation = operation;
  }

  toString(): string {
    return this.source
      .reduce<Array<string>>((query, formatter) => {
        query.push(formatter.toString());

        return query;
      }, [])
      .filter((item) => {
        return item;
      })
      .join(` ${this.operation} `);
  }
}
