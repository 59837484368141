import { all, call, fork, put, select, takeLatest } from "redux-saga/effects";

import {
  FETCH_BILLING_ACCOUNT_LIST,
  FETCH_BILLING_DATA,
  FETCH_CURRENT_PRINCIPAL,
  REMOVE_BILLING_DATA,
} from "./constants";
import userAPIService from "../../services/api/user";
import {
  fetchBillingAccountList,
  fetchBillingAccountListError,
  fetchBillingAccountListSuccess,
  fetchBillingDataError,
  fetchBillingDataSuccess,
  fetchCurrentCustomerError,
  fetchCurrentCustomerSuccess,
  fetchCurrentPrincipalError,
  fetchCurrentPrincipalSuccess,
  fetchingCurrentCustomer,
  fetchingCurrentPrincipal,
  removeBillingDataError,
  removeBillingDataSuccess,
} from "./actions";
import { selectPrincipalData } from "./selectors";
import {
  IFetchBillingAccountList,
  IFetchBillingData,
  IPrincipalFetchAction,
  IRemoveBillingData,
} from "./types";

export function* fetchPrincipal(principalId: string) {
  yield put(fetchingCurrentPrincipal());

  try {
    const principal = yield call(
      [userAPIService, userAPIService.get],
      principalId,
    );
    yield put(fetchCurrentPrincipalSuccess(principal));
  } catch (error) {
    yield put(fetchCurrentPrincipalError(error));
  }
}

export function* fetchCustomer(customerId: string) {
  yield put(fetchingCurrentCustomer());

  try {
    const principal = yield call(
      [userAPIService, userAPIService.getCustomer],
      customerId,
    );

    yield put(fetchCurrentCustomerSuccess(principal));
  } catch (error) {
    yield put(fetchCurrentCustomerError(error));
  }
}

function* fetchBillingAccounts({
  payload: customerId,
}: IFetchBillingAccountList) {
  try {
    const billingAccountList = yield call(
      [userAPIService, userAPIService.getBillingAccounts],
      customerId,
    );

    yield put(fetchBillingAccountListSuccess(billingAccountList));
  } catch (error) {
    yield put(fetchBillingAccountListError(error));
  }
}

export function* fetchPrincipalAndCustomerWithBillingAccount({
  payload,
}: IPrincipalFetchAction) {
  yield call(fetchPrincipal, payload);

  const userData = yield select(selectPrincipalData);

  yield put(fetchBillingAccountList(userData.customer_id));
  yield call(fetchCustomer, userData.customer_id);
}

function* fetchBillingData({ payload }: IFetchBillingData) {
  try {
    const billingData = yield call(
      [userAPIService, userAPIService.getBillingInfo],
      payload.spCode,
      payload.customerId,
    );

    yield put(fetchBillingDataSuccess(billingData));
  } catch (error) {
    yield put(fetchBillingDataError(error));
  }
}

function* removeBillingData({
  payload,
  onSuccess,
  onError,
  onFinally,
}: IRemoveBillingData) {
  try {
    yield call(
      [userAPIService, userAPIService.removeBillingInfo],
      payload.spCode,
      payload.customerId,
    );
    onSuccess();
    yield put(removeBillingDataSuccess());
  } catch (error) {
    onError();
    yield put(removeBillingDataError(error));
  } finally {
    if (onFinally) {
      onFinally();
    }
  }
}

export function* watchFetchPrincipal() {
  yield takeLatest(
    FETCH_CURRENT_PRINCIPAL,
    fetchPrincipalAndCustomerWithBillingAccount,
  );
}

export function* watchFetchAccountList() {
  yield takeLatest(FETCH_BILLING_ACCOUNT_LIST, fetchBillingAccounts);
}

export function* watchFetchBillingData() {
  yield takeLatest(FETCH_BILLING_DATA, fetchBillingData);
}

export function* watchRemoveBillingData() {
  yield takeLatest(REMOVE_BILLING_DATA, removeBillingData);
}

export function* currentPrincipalSaga() {
  yield all([
    fork(watchFetchPrincipal),
    fork(watchFetchAccountList),
    fork(watchFetchBillingData),
    fork(watchRemoveBillingData),
  ]);
}
