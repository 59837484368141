import React, { FC } from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { INavigationItemProps } from "../types";
import Icon from "../../Icon";

export const NavigationItem: FC<INavigationItemProps> = (props) => {
  const { t } = useTranslation();
  const { data, topDivider, bottomDivider } = props;

  return (
    <div>
      {topDivider ? <div className="navigation__divider" /> : null}
      <NavLink
        key={data.label}
        className="navigation__item"
        to={data.link}
        activeClassName="navigation__item--selected"
      >
        <Icon className="navigation__icon">{data.icon}</Icon>
        {t(data.label)}
      </NavLink>
      {bottomDivider ? <div className="navigation__divider" /> : null}
    </div>
  );
};

NavigationItem.defaultProps = {
  topDivider: true,
  bottomDivider: true,
};
