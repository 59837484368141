import { API } from "../api";
import { url } from "../constants";
import { ITenantsResponseData, Tenant } from "./types";
import { IAPIRequestConfig } from "../types";

export class TenantService {
  constructor(private api: API) {}

  async getAll(params?: IAPIRequestConfig): Promise<Tenant[]> {
    const {
      data: { items },
    } = await this.api.get<ITenantsResponseData>(url.tenants, params);

    return items;
  }
}
