import React, { FC } from "react";
import classnames from "classnames";

import { LoaderProps } from "./types";
import { HeaderLoader } from "./HeaderLoader/HeaderLoader";
import { SidebarLoader } from "./SidebarLoader/SidebarLoader";
import { ContentLoaderContainer } from "./ContentLoader/ContentLoaderContainer";

import "./styles.scss";

export const Loader: FC<LoaderProps> = ({
  className,
  isSubscriptionLoader,
}) => {
  const isUser = Object.keys(sessionStorage).filter((key) => {
    return key.startsWith("oidc.user");
  }).length;

  return (
    <>
      {isUser ? (
        <div className={classnames(className, "ams-loader")}>
          <div className="ams-layout ams-layout--private ams-layout--principal">
            <HeaderLoader />
            <div className="ams-layout__container">
              <SidebarLoader />
              <main className="ams-layout__content">
                <ContentLoaderContainer
                  isSubscriptionLoader={isSubscriptionLoader}
                />
              </main>
            </div>
          </div>
        </div>
      ) : (
        <div />
      )}
    </>
  );
};
