import React, { FC } from "react";
import classnames from "classnames";

import Icon from "../Icon";
import { IAlertProps } from "./types";
import { ALERT_ICON } from "./constants";

import "./styles.scss";

export const Alert: FC<IAlertProps> = ({
  className,
  children,
  variant,
  content,
  icon,
  ...props
}) => {
  const currentIcon = icon ? icon : ALERT_ICON[variant];
  return (
    <div
      className={classnames("ams-alert", `ams-alert--${variant}`, className)}
      {...props}
    >
      <div className="ams-alert__message">
        {currentIcon && <Icon>{currentIcon}</Icon>}
        <span className="ams-alert__text">{children}</span>
      </div>
      {content}
    </div>
  );
};
