/* eslint-disable @typescript-eslint/camelcase */
import { API } from "../api";
import { url } from "../constants";
import {
  ICancelSubscriptionRequest,
  ICancelSubscriptionResponse,
  ICreateSubscriptionRequest,
  ISubscription,
  ISubscriptionDetails,
  ISubscriptionOptions,
  ISubscriptionPreview,
  ISubscriptionRequest,
  ISubscriptionsResponse,
  IUpdateSubscriptionRequest,
  IUpdateSubscriptionResponse,
} from "./types";
import { IAPIRequestConfig } from "../types";
import { getETagCode, validateArgs } from "../utils";

export class SubscriptionAPIService {
  constructor(private api: API) {}

  async getAll(customerId: string, params?: any): Promise<ISubscription[]> {
    validateArgs(customerId);
    const {
      data: { items },
    } = await this.api.get<ISubscriptionsResponse>(
      url.subscriptions.get.replace(":customerId", customerId),
      { params },
    );

    return items;
  }

  async getDetail(
    customerId: string,
    subscriptionId: string,
  ): Promise<ISubscriptionDetails> {
    validateArgs(customerId, subscriptionId);
    const { data } = await this.api.get<ISubscriptionDetails>(
      url.subscriptions.getDetail
        .replace(":customerId", customerId)
        .replace(":subscriptionId", subscriptionId),
    );
    return data;
  }

  async previewSubscription(
    customerId: string,
    spData: ICreateSubscriptionRequest,
  ) {
    validateArgs(customerId);
    const { data } = await this.api.post<ISubscriptionPreview>(
      url.subscriptions.previewSubscription.replace(":customerId", customerId),
      spData,
    );
    return data;
  }

  async previewUpdateSubscription(
    customerId: string,
    subscriptionId: string,
    spData: IUpdateSubscriptionRequest,
  ) {
    validateArgs(customerId, subscriptionId);
    const { data } = await this.api.post<ISubscriptionPreview>(
      url.subscriptions.previewUpdate
        .replace(":customerId", customerId)
        .replace(":subscriptionId", subscriptionId),
      spData,
    );

    return data;
  }

  async create(
    subscriptionData: ICreateSubscriptionRequest,
    customerId: string,
    config?: IAPIRequestConfig,
  ) {
    validateArgs(customerId);
    const { data } = await this.api.post<ISubscriptionsResponse>(
      url.subscriptions.create.replace(":customerId", customerId),
      subscriptionData,
      config,
    );
    return data;
  }

  async updateSubscription(
    customerId: string,
    subscriptionId: string,
    spData: IUpdateSubscriptionRequest,
  ) {
    validateArgs(customerId, subscriptionId);
    const { data } = await this.api.post<IUpdateSubscriptionResponse>(
      url.subscriptions.update
        .replace(":customerId", customerId)
        .replace(":subscriptionId", subscriptionId),
      spData,
    );
    return data;
  }

  async cancel({
    customerId,
    cancelReason,
    subscriptionId,
  }: ICancelSubscriptionRequest) {
    validateArgs(customerId, subscriptionId);
    const { data } = await this.api.post<ICancelSubscriptionResponse>(
      url.subscriptions.cancel
        .replace(":customerId", customerId)
        .replace(":subscriptionId", subscriptionId),
      { cancel_reason: cancelReason },
    );

    return data;
  }

  async uncancel({ customerId, subscriptionId }: ISubscriptionRequest) {
    validateArgs(customerId, subscriptionId);
    const { data } = await this.api.post(
      url.subscriptions.uncancel
        .replace(":customerId", customerId)
        .replace(":subscriptionId", subscriptionId),
      {},
    );

    return data;
  }

  async pause({ customerId, subscriptionId }: ISubscriptionRequest) {
    validateArgs(customerId, subscriptionId);
    const { data } = await this.api.post(
      url.subscriptions.pause
        .replace(":customerId", customerId)
        .replace(":subscriptionId", subscriptionId),
      {},
    );

    return data;
  }

  async unpause({ customerId, subscriptionId }: ISubscriptionRequest) {
    validateArgs(customerId, subscriptionId);
    const { data } = await this.api.post(
      url.subscriptions.unpause
        .replace(":customerId", customerId)
        .replace(":subscriptionId", subscriptionId),
      {},
    );

    return data;
  }

  async recycle(customerId: string, subscriptionId: string) {
    validateArgs(customerId, subscriptionId);
    const { data } = await this.api.post(
      url.subscriptions.recycle
        .replace(":customerId", customerId)
        .replace(":subscriptionId", subscriptionId),
      {},
    );

    return data;
  }

  async options(customerId: string, subscriptionId: string) {
    validateArgs(customerId, subscriptionId);
    const response = await this.api.get<ISubscriptionOptions>(
      url.subscriptions.options
        .replace(":customerId", customerId)
        .replace(":subscriptionId", subscriptionId),
      {},
    );

    return {
      ...response.data,
      eTag: response.headers?.etag,
    };
  }

  async editOptions(
    customerId: string,
    subscriptionId: string,
    autoRecycle: boolean,
    ifMatchOption: string,
  ) {
    validateArgs(customerId, subscriptionId);
    const etag = getETagCode(ifMatchOption);

    if (etag) {
      this.api.addHeader("If-Match", etag);
    } else {
      this.api.addHeader("If-None-Match", "*");
    }

    const { data } = await this.api.put<ISubscriptionOptions>(
      url.subscriptions.options
        .replace(":customerId", customerId)
        .replace(":subscriptionId", subscriptionId),
      {
        auto_recycle: autoRecycle,
      },
    );
    if (etag) {
      this.api.removeHeader("If-Match");
    } else {
      this.api.removeHeader("If-None-Match");
    }

    return data;
  }
}
