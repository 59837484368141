import { BaseConditionType, SapphireDbOptions } from "sapphiredb";

import SocketBase from "..";
import { getStandingSocketUrl } from "./utils";
import { IStanding } from "./types";

export class StandingSocketService extends SocketBase {
  connect(config?: SapphireDbOptions) {
    if (this.db) {
      return;
    }

    const _config: SapphireDbOptions = {
      serverBaseUrl: getStandingSocketUrl(),
      ...config,
    };

    super.connect(_config);
  }

  subscribe(customerId: string, cb: (value: IStanding[]) => void) {
    if (!this.db) {
      throw new Error("Please connect to DB");
    }

    const condition: BaseConditionType<IStanding> = [
      "customerId",
      "==",
      customerId,
    ];

    return this.db
      .collection<IStanding>("standing")
      .where(condition)
      .values()
      .subscribe(cb);
  }
}
