import React, { ChangeEvent, FC } from "react";
import classnames from "classnames";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { AdminHeaderProps } from "./types";
import { principalDatabase } from "../../config/router/routes";
import Select from "../../components/Select";
import { UserStatus } from "../../services/api/user/types";
import { ACTIONS } from "./constants";
import { ACTION, SUBJECT } from "../../constants/permissions";
import Can from "../../components/Can";
import { selectPrincipalData } from "../../store/user";

import "./styles.scss";

export const AdminHeader: FC<AdminHeaderProps> = ({
  className,
  customerStatus,
  principalStatus,
  isCorporate,
  onChangeStatus,
}) => {
  const { t } = useTranslation();
  const principal = useSelector(selectPrincipalData);

  const onChange = (e: ChangeEvent<HTMLSelectElement>) => {
    onChangeStatus(e.target.value as UserStatus);
  };

  return (
    <div className={classnames("ams-admin-header", className)}>
      <div className="ams-admin-header__block">
        <Link className="ams-admin-header__link" to={principalDatabase.path}>
          ← {t("adminHeaderLink")}
        </Link>
        <div className="ams-admin-header__message">
          {t("adminHeaderMessage")} <b>{principal?.email}</b>
        </div>
        <Can I={ACTION.WRITE} a={SUBJECT.USER}>
          <Select
            className="ams-admin-header__select mb-0"
            id="action-select"
            value="accountActions"
            onChange={onChange}
          >
            {ACTIONS.map(({ label, value, getIsEnabled }) => {
              return (
                <option
                  className={classnames({
                    "ams-select__option-hidden": !getIsEnabled(
                      customerStatus,
                      principalStatus,
                      isCorporate,
                    ),
                  })}
                  value={value}
                  key={value}
                >
                  {t(label)}
                </option>
              );
            })}
          </Select>
        </Can>
      </div>
    </div>
  );
};
