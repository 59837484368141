import { ActionTypes, IState } from "./types";
import {
  FETCH_FAILURE,
  FETCH_REQUEST,
  FETCH_SUCCESS,
  FILTER_RESET,
  FILTER_TEXT_UPDATE,
  FILTER_UPDATE,
  PAGINATION_UPDATE,
  RESET_ALL,
  SORT_UPDATE,
  initialState,
} from "./constants";

const reducer = (prevState: IState, action: ActionTypes) => {
  const state = prevState || initialState;

  switch (action.type) {
    case FETCH_REQUEST:
      return { ...state, isFetching: true };
    case FETCH_SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: action.payload.data,
        pages: action.payload.pages,
        error: initialState.error,
      };
    case FETCH_FAILURE:
      return { ...state, isFetching: false, error: action.payload };
    case PAGINATION_UPDATE:
      return { ...state, ...action.payload };
    case SORT_UPDATE:
      return { ...state, sort: action.payload };
    case FILTER_UPDATE:
      return { ...state, ...action.payload, page: 0 };
    case FILTER_TEXT_UPDATE:
      return { ...state, filterText: action.payload, page: 0 };
    case FILTER_RESET:
      return {
        ...state,
        filterText: { value: "", valid: false },
        spCode: "all",
        productLineCode: "all",
        page: 0,
      };
    case RESET_ALL:
      return { ...state, ...initialState };
    default:
      return state;
  }
};

export default reducer;
