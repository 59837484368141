import React, { FC, useCallback, useEffect, useMemo } from "react";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import * as routes from "../../config/router/routes";
import { useAbility, useDialog } from "../../hooks";
import { SIGNUP_REDIRECTION_LINK } from "../../config/router/constants";
import {
  getLayouts,
  getRouteComponent,
  getRoutePath,
  getRoutes,
  hasLayout,
} from "./utils";
import { changeLocation, initLocation } from "../../store/location";
import { useIsAuthenticated, useUserManager } from "../../services/auth";
import standingSocketService from "../../services/socket/standing";
import { updateData } from "../../store/standing";
import { IStanding } from "../../services/socket/standing/types";
import { selectCustomerId, selectIdToken } from "../../store/oidc";
import { getNavItems } from "../../layouts/PrivateLayout/config";
import {
  selectBrandCode,
  selectBrandCodeName,
  selectCurrentSPS,
} from "../../store/metadata";
import {
  selectCustomerData,
  selectRecurlyBillingAccount,
} from "../../store/user";
import {
  selectCurrentCustomerSubscription,
  selectCurrentSubscriptionDetail,
} from "../../store/subscriptions";
import { WinbackOffer } from "../Subscriptions/components/WinbackOffer/WinbackOffer";
import { ThanksModal } from "../Subscriptions/components/ThanksModal/ThanksModal";
import { SP_STATES } from "../../services/api/subscription/constants";
import { WINBACK_MODAL_STATE } from "../PaymentInformation/contants";
import { EXTERNAL_SP_LIST, RECURLY } from "../../constants/spCode";

const routeList = Object.values(routes);
const layoutList = getLayouts(routeList);

export const Main: FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const isAuthenticated = useIsAuthenticated();
  const userManager = useUserManager();
  const token = useSelector(selectIdToken);
  const customerId = useSelector(selectCustomerId);
  const ability = useAbility();
  const customer = useSelector(selectCustomerData);
  const brandCode = useSelector(selectBrandCode);
  const brandCodeName = useSelector(selectBrandCodeName);
  const currentSubscription = useSelector(selectCurrentCustomerSubscription);
  const currentSPS = useSelector(selectCurrentSPS);
  const currentSubscriptionDetail = useSelector(
    selectCurrentSubscriptionDetail,
  );
  const recurlyBillingAccount = useSelector(selectRecurlyBillingAccount);

  const { t } = useTranslation();

  const disconnect = useCallback(() => {
    if (standingSocketService.isConnected()) {
      standingSocketService.disconnect();
    }
  }, []);

  useEffect(() => {
    dispatch(initLocation(history.location));

    const handleLocationChange = (location: any) => {
      dispatch(changeLocation(location));
    };
    const listener = history.listen(handleLocationChange);

    return () => {
      listener();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, history]);

  useEffect(() => {
    if (isAuthenticated && token && customerId) {
      standingSocketService.connect(customerId);
      standingSocketService.setAuthToken(token);
      standingSocketService.subscribe(customerId, (data: IStanding[]) => {
        dispatch(updateData({ data }));
      });
    }
  }, [dispatch, isAuthenticated, token, customerId]);

  useEffect(() => {
    userManager.events.addUserUnloaded(disconnect);

    return () => {
      userManager.events.removeUserUnloaded(disconnect);
    };
  }, [userManager, disconnect]);

  useEffect(() => {
    document.title = `${brandCodeName} | ${t("yourAccount")}`;
  }, [brandCodeName, t]);

  const [
    isShowWinbackOfferModal,
    openWinbackOfferModal,
    closeWinbackOfferModal,
  ] = useDialog();

  const handleWinbackModalState = () => {
    localStorage.setItem("winbackModalState", WINBACK_MODAL_STATE);
  };

  const handleCloseWinbackModal = () => {
    handleWinbackModalState();
    closeWinbackOfferModal();
  };

  const isWinbackOfferModalOpen = useMemo(() => {
    const isExternalPayment =
      currentSubscriptionDetail &&
      EXTERNAL_SP_LIST.includes(currentSubscriptionDetail.sp.sp_code);

    return (
      !isExternalPayment &&
      recurlyBillingAccount?.sp_code === RECURLY &&
      localStorage.getItem("winbackModalState") !== WINBACK_MODAL_STATE &&
      currentSubscriptionDetail?.sp.states[0] === SP_STATES.TERMINATED &&
      !customer?.is_corporate &&
      !!currentSPS
    );
  }, [currentSPS, currentSubscriptionDetail, customer, recurlyBillingAccount]);

  useEffect(() => {
    isWinbackOfferModalOpen && openWinbackOfferModal();
  }, [isWinbackOfferModalOpen, openWinbackOfferModal]);

  const navItems = getNavItems();

  const navigationRoutes = routeList.filter((route) => {
    return navItems.find((item) => {
      return item?.route?.path === route.path;
    });
  });

  const firstAllovedNavRoute = useMemo(() => {
    return navigationRoutes.find((routeConfig) => {
      return routeConfig.isRedirectAllowed({
        ability,
        customer,
        brandCode,
        currentSubscription,
      });
    });
  }, [ability, customer, brandCode, currentSubscription, navigationRoutes]);

  const [
    isShowThankYouModal,
    openThankYouModal,
    closeThankYouModal,
  ] = useDialog();

  return (
    <>
      <Switch>
        <Redirect from="/signup" to={SIGNUP_REDIRECTION_LINK} />
        {layoutList.map((Layout, layoutKey) => {
          const routesByLayout = getRoutes(routeList.filter(hasLayout(Layout)));
          const routesWithCurrentLayout = routesByLayout.map(getRoutePath);

          return (
            <Route exact key={layoutKey} path={routesWithCurrentLayout}>
              <Layout>
                <Switch>
                  {routesByLayout.map((route, routeKey) => {
                    const RouteComponent = getRouteComponent(route);
                    return (
                      <RouteComponent
                        exact={route.exact}
                        key={routeKey}
                        path={route.path}
                        component={route.component}
                      />
                    );
                  })}
                </Switch>
              </Layout>
            </Route>
          );
        })}
        <Redirect
          to={firstAllovedNavRoute?.path || routes.accountOverview.path}
        />
      </Switch>

      {isShowWinbackOfferModal && (
        <WinbackOffer
          onDismiss={handleCloseWinbackModal}
          isOpen={isShowWinbackOfferModal}
          openThankYouModal={openThankYouModal}
        />
      )}

      <ThanksModal
        isOpen={isShowThankYouModal}
        onDismiss={closeThankYouModal}
        title={t("saveOffer.gladToHaveYouBack")}
      />
    </>
  );
};
