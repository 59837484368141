import { ISidebarNavItem } from "../../components/Navigation/types";
import * as routes from "../../config/router/principal";

export const getNavItems: (id: string) => Array<ISidebarNavItem> = (
  id: string,
) => {
  return [
    {
      link: `/principal-database/${id}/profile`,
      label: "profile",
      icon: "profile",
      route: routes.principalDatabaseViewProfile,
    },
    {
      link: `/principal-database/${id}/subscriptions`,
      label: "subscriptions",
      icon: "subscriptions",
      route: routes.principalViewSubscription,
    },
  ];
};
