import { combineReducers } from "redux";
import { reducer as oidcReducer } from "redux-oidc";

import userReducer from "../user";
import metadataReducer from "../metadata";
import principalReducer from "../principal";
import skuMappingReducer from "../skuMapping";
import offeringsReducer from "../offerings";
import subscriptionsReducer from "../subscriptions";
import StandingReducer from "../standing";
import ManageUsersReduser from "../manageUsers";
import corporateBuyReducer from "../corporate-buy";
import accountReducer from "../account";
import usageReducer from "../usage";
import billingHistoryReducer from "../billingHistory";

export * from "./types";

const reducers = combineReducers({
  oidc: oidcReducer,
  user: userReducer,
  metadata: metadataReducer,
  principal: principalReducer,
  skuMapping: skuMappingReducer,
  offerings: offeringsReducer,
  subscriptions: subscriptionsReducer,
  standing: StandingReducer,
  manageUsers: ManageUsersReduser,
  corporateBuy: corporateBuyReducer,
  account: accountReducer,
  usage: usageReducer,
  billingHistory: billingHistoryReducer,
});

export default reducers;
