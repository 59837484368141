import {
  FETCH_FAILURE,
  FETCH_LICENSES_FAILURE,
  FETCH_LICENSES_REQUEST,
  FETCH_LICENSES_SUCCESS,
  FETCH_REQUEST,
  FETCH_SUCCESS,
  PAGINATION_UPDATE,
  RESET_FILTER,
  SET_CUSTOMER_ID,
  SORT_UPDATE,
  UPDATE_FILTER,
  UPDATE_FILTER_SEARCH,
} from "./constants";
import {
  ICustomerPrincipalListResponse,
  ILicenseResponse,
} from "../../services/api/user/types";
import { ActionTypes, IFilter, IPaging } from "./types";
import { Value } from "../../components/SearchInput";
import { TableHeaderSort } from "../../components/Table/components/TableHeader/types";

export const fetchDataRequest = (): ActionTypes => {
  return {
    type: FETCH_REQUEST,
  };
};

export const fetchDataSuccess = (
  payload: ICustomerPrincipalListResponse,
): ActionTypes => {
  return {
    type: FETCH_SUCCESS,
    payload,
  };
};

export const fetchDataFailure = (payload: any): ActionTypes => {
  return {
    type: FETCH_FAILURE,
    payload,
  };
};

export const fetchLicensesRequest = (subscriptionId: string): ActionTypes => {
  return {
    type: FETCH_LICENSES_REQUEST,
    payload: subscriptionId,
  };
};

export const fetchLicensesSuccess = (
  payload: ILicenseResponse,
): ActionTypes => {
  return {
    type: FETCH_LICENSES_SUCCESS,
    payload,
  };
};

export const fetchLicensesFailure = (payload: any): ActionTypes => {
  return {
    type: FETCH_LICENSES_FAILURE,
    payload,
  };
};

export const updateFilter = (payload: Partial<IFilter>): ActionTypes => {
  return {
    type: UPDATE_FILTER,
    payload,
  };
};

export const updateFilterSearch = (payload: Value): ActionTypes => {
  return {
    type: UPDATE_FILTER_SEARCH,
    payload,
  };
};

export const setCustomerId = (payload: string): ActionTypes => {
  return {
    type: SET_CUSTOMER_ID,
    payload,
  };
};

export const resetFilter = (): ActionTypes => {
  return {
    type: RESET_FILTER,
  };
};

export const paginationUpdate = (payload: Partial<IPaging>): ActionTypes => {
  return {
    type: PAGINATION_UPDATE,
    payload,
  };
};

export const updateSort = (payload: TableHeaderSort): ActionTypes => {
  return {
    type: SORT_UPDATE,
    payload,
  };
};
