import React from "react";
import { toast } from "react-toastify";
import i18next from "i18next";

import { IToaster, ToasterType } from "./types";
import { DEFAULT_OPTIONS } from "./constants";
import { Toaster as ToasterComponent } from "../../components/Toaster/Toaster";

const Toaster: IToaster = {
  success({ content, options, translationKey }) {
    this.showToast({
      content,
      options,
      translationKey,
      type: ToasterType.Success,
      icon: "success-inverse",
    });
  },
  error({ content, options, translationKey }) {
    this.showToast({
      content,
      options,
      translationKey,
      type: ToasterType.Error,
      icon: "error-inverse",
    });
  },
  warning({ content, options, translationKey }) {
    this.showToast({
      content,
      options,
      translationKey,
      type: ToasterType.Warning,
      icon: "warning",
    });
  },
  showToast({ type, content, options, icon, translationKey }) {
    const toastContent = translationKey ? i18next.t(translationKey) : content;

    toast[type](
      <ToasterComponent icon={icon}>{toastContent}</ToasterComponent>,
      {
        ...DEFAULT_OPTIONS,
        ...options,
      },
    );
  },
};

export default Toaster;
