import { API } from "../api";
import { url as URL } from "../constants";
import { IProductData, IProductResponse } from "./types";

export class ProductAPIService {
  constructor(private api: API) {}

  public async getAll(params?: any): Promise<IProductData> {
    const { data: products } = await this.api.get<IProductResponse>(
      URL.product.base,
      {
        params,
      },
    );
    return products.items;
  }
}
