import { ChangeEvent, useMemo } from "react";
import _isNaN from "lodash/isNaN";

import { BrandCode } from "../../models/brand";
import { IMoney } from "../../services/api/subscription/types";
import {
  IOfferingDetailsResponse,
  ISKUDetails,
} from "../../services/api/offering/types";
import {
  DEFAULT_CURRENCY,
  RESPONSE_ERROR_KEY,
  SUBSCRIPTION_STATUS,
} from "./contants";
import { getCurrencyDetails } from "../../services/currency/currency";
import { ISPS } from "../../services/api/brand/types";
import {
  APPLE_APP_STORE,
  GOOGLE_PLAY_STORE,
  RECURLY,
} from "../../constants/spCode";
import {
  ACCOUNT_TYPE_CHECKING,
  ACH_BANK_DRAFT_METHOD,
  PAYPAL_AMAZON_WALLET,
  ProblemTypes,
  SEPA_BANK_DRAFT_METHOD,
} from "../../pages/PaymentInformation/contants";
import { Interval } from "./types";
import { parse } from "iso8601-duration";
import { IPaymentInfo } from "../../services/api/user/types";
import { CREDIT_CARD } from "../../pages/PaymentInformation/components/AddPaymentMethodModal/contants";
import { SP_STATES } from "../../services/api/subscription/constants";
import { ICurrentSubscriptionDetails } from "../../store/subscriptions";

export function createChangeEventHandler(setValue: (value: string) => void) {
  return (
    event: ChangeEvent<
      HTMLTextAreaElement | HTMLSelectElement | HTMLInputElement
    >,
  ) => {
    setValue(event.target.value);
  };
}

export function useParsedFullName(fullName: string) {
  return useMemo(() => {
    const [name, ...other] = fullName.split(" ");

    return [name, other.join(" ")];
  }, [fullName]);
}

export function getUnitCostWithCurrency(
  geoCurrency: string,
  unitCost: IMoney[],
  billedCurrency?: string,
): IMoney {
  const costByGeoCurrency = unitCost.find((cost: IMoney) => {
    return cost.currency === geoCurrency;
  });

  const costByBilledCurrency = unitCost.find((cost: IMoney) => {
    return cost.currency === billedCurrency;
  });

  const defaultUnitCost = unitCost.find((cost: IMoney) => {
    return cost.currency === "USD";
  }) as IMoney;

  if (billedCurrency) {
    return costByBilledCurrency || defaultUnitCost;
  }

  if (costByGeoCurrency) {
    return costByGeoCurrency;
  }

  return defaultUnitCost;
}

export const getOfferingDetailsList = (
  offeringDetails: IOfferingDetailsResponse,
  billedCurrency?: string,
) => {
  const withGeoCurrency = offeringDetails.items.filter((item) => {
    return item.sp.unit_cost.some((unitCost) => {
      return unitCost.currency === offeringDetails.geo_currency;
    });
  });

  const isCurrencyAvailable =
    withGeoCurrency.length === offeringDetails.items.length;

  const currency = isCurrencyAvailable
    ? offeringDetails?.geo_currency
    : DEFAULT_CURRENCY;

  return offeringDetails.items.map((offering: ISKUDetails) => {
    const cost = getUnitCostWithCurrency(
      currency,
      offering.sp.unit_cost,
      billedCurrency,
    );

    return {
      ...offering,
      geoCost: cost,
    };
  });
};

export const getEmailLabel = (brandCode?: BrandCode) => {
  switch (brandCode) {
    case "TN":
      return "externalEmail";
    default:
      return "email";
  }
};

export const getUsernameLabel = (brandCode?: BrandCode) => {
  switch (brandCode) {
    case "GF":
      return "email";
    case "TN":
      return "texasNetEmail";
    default:
      return "username";
  }
};

export const getUsername = (
  username: string,
  brandCode: BrandCode | undefined,
) => {
  const TNsuffix = "@texas.net";
  username.endsWith(TNsuffix);
  const shouldAddSuffix = !username.endsWith(TNsuffix) && brandCode === "TN";

  if (shouldAddSuffix) {
    return `${username}${TNsuffix}`;
  }

  return username;
};

export const getProductLineCode = (productLineCode: string) => {
  return productLineCode.split("_")[0];
};

export function round(value: any, decimals: number): number {
  const roundedValue = Number(
    Math.round(Number(value.toString() + "e" + decimals)) + "e-" + decimals,
  );
  if (_isNaN(roundedValue)) {
    return value;
  }

  return roundedValue;
}

export const decimalsFormat = (value: number, decimals: number): string => {
  return value.toFixed(decimals);
};

export function getPrice(amount: number, currencyCode?: string) {
  if (!currencyCode) {
    return amount;
  }
  const {
    currencySymbol,
    currency,
    isShowCurrencySymbol,
    isShowCurrencyCode,
    decimal,
  } = getCurrencyDetails(currencyCode);
  const roundedAmount = round(amount, decimal);
  const decimalsNumber = decimalsFormat(roundedAmount, decimal);

  return `${isShowCurrencyCode ? `${currency} ` : ""}${
    isShowCurrencySymbol ? currencySymbol : ""
  }${decimalsNumber}`;
}

export const getMonthDayYearDate = (date: Date) => {
  const year = date.getUTCFullYear();
  const month = (1 + date.getUTCMonth()).toString().padStart(2, "0");
  const day = date.getUTCDate().toString().padStart(2, "0");

  return month + "/" + day + "/" + year;
};

export const formatNumber = (number: number) => {
  // eslint-disable-next-line prefer-named-capture-group
  return number.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
};

export const getRecurlyKey = (sps?: ISPS[]) => {
  return sps?.find((item: ISPS) => {
    return item.sp_code === RECURLY;
  })?.public_key;
};

export const capitalize = (text: string) => {
  return text[0].toUpperCase() + text.slice(1);
};

export const isExpired = (date?: string) => {
  if (!date) {
    return false;
  }
  return new Date(date).getTime() <= new Date().getTime();
};

export function isJsonString(str: string): boolean {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

export const passwordErrorHandler = (err: any) => {
  const errorContent = err.response?.data?.error_content;
  const errorContentData = isJsonString(errorContent)
    ? JSON.parse(errorContent)
    : null;
  const passwordErrorsArr = errorContentData?.Errors?.password;
  const asciiError = passwordErrorsArr.find((error: string) => {
    return error === RESPONSE_ERROR_KEY.Illegal;
  });
  return asciiError ? asciiError : "validationFailed";
};

export const checkIsETagError = (errorCode?: string) => {
  if (!errorCode) {
    return false;
  }

  return (
    errorCode === ProblemTypes.IfMatchHeaderIsRequiredButGotIfMatchException ||
    errorCode === ProblemTypes.EtagNotMatchException
  );
};

export const isSubscriptionPage = () => {
  return sessionStorage.getItem("ams:returnTo") === "/subscriptions";
};

export const getInterval = (billingPeriod: string): Interval => {
  const duration = parse(billingPeriod);

  if (duration.years) {
    return {
      length: duration.years || 0,
      unit: "years",
    };
  }

  if (duration.weeks) {
    return {
      length: duration.weeks || 0,
      unit: "weeks",
    };
  }

  if (duration.days) {
    return {
      length: duration.days || 0,
      unit: "days",
    };
  }

  return {
    length: duration.months || 0,
    unit: "months",
  };
};

export const isOneMonth = (length: number) => {
  return length <= 1;
};

export const makeCapitalise = (text: string) => {
  return text
    .split(" ")
    .map((item) => {
      return item.charAt(0).toUpperCase() + item.slice(1);
    })
    .join(" ");
};

export const getPaymentTypeTitle = (paymentInfo?: IPaymentInfo) => {
  if (!paymentInfo) {
    return "";
  }

  const paymentFamily = paymentInfo.payment_method_family
    .toLowerCase()
    .split("_")
    .join(" ");
  const paymentMethod = paymentInfo.payment_method
    .toLowerCase()
    .split("_")
    .join(" ");

  // eslint-disable-next-line @typescript-eslint/camelcase
  if (paymentInfo.payment_method === ACH_BANK_DRAFT_METHOD) {
    return paymentInfo.account_type === ACCOUNT_TYPE_CHECKING
      ? "checkingAccount"
      : "savingsAccount";
  }

  // eslint-disable-next-line @typescript-eslint/camelcase
  if (paymentInfo.payment_method === SEPA_BANK_DRAFT_METHOD) {
    return "internationalBankAccount";
  }

  if (paymentMethod === paymentFamily) {
    return makeCapitalise(paymentMethod);
  }

  if (paymentInfo.payment_method_family === CREDIT_CARD) {
    return `${makeCapitalise(paymentInfo.payment_method_description)}`;
  }

  if (paymentInfo.payment_method_family === PAYPAL_AMAZON_WALLET) {
    return `${paymentMethod}${makeCapitalise(paymentFamily)}`;
  }

  return `${makeCapitalise(paymentMethod)} ${makeCapitalise(paymentFamily)}`;
};

/* eslint-disable @typescript-eslint/camelcase*/
export const getSubscriptionStatus = (data: ICurrentSubscriptionDetails) => {
  const { states, is_active, sp_code } = data.sp;

  if (
    states.some((item) => {
      return item === SP_STATES.PAST_DUE;
    })
  ) {
    if (
      states.some((item) => {
        return item === SP_STATES.CANCELED;
      }) &&
      is_active &&
      (sp_code === APPLE_APP_STORE || sp_code === RECURLY)
    ) {
      return SUBSCRIPTION_STATUS.pendingCancellation;
    }

    if (
      sp_code === GOOGLE_PLAY_STORE &&
      ((states.some((item) => {
        return item === SP_STATES.CANCELED;
      }) &&
        data.willExpireAt) ||
        !is_active)
    ) {
      return SUBSCRIPTION_STATUS.onHold;
    }

    if (!is_active) {
      return SUBSCRIPTION_STATUS.paymentFailure;
    }
    return SUBSCRIPTION_STATUS.active;
  }

  if (
    states.some((item) => {
      return item === SP_STATES.CANCELED;
    }) &&
    data.willExpireAt
  ) {
    return SUBSCRIPTION_STATUS.pendingCancellation;
  }

  if (
    states.some((item) => {
      return item === SP_STATES.PAUSED;
    })
  ) {
    return SUBSCRIPTION_STATUS.paused;
  }

  if (
    states.some((item) => {
      return item === SP_STATES.PENDING;
    })
  ) {
    return SUBSCRIPTION_STATUS.pendingPayment;
  }

  if (
    states.some((item) => {
      return item === SP_STATES.TERMINATED;
    }) &&
    data.isTerminated
  ) {
    return SUBSCRIPTION_STATUS.expired;
  }

  if (!data.isGoodStanding) {
    return SUBSCRIPTION_STATUS.notActive;
  }
  return SUBSCRIPTION_STATUS.active;
};

export function getDateString(date: Date) {
  return date.toISOString().split("T")[0];
}
