import React from "react";

import BaseLayout from "../../layouts/BaseLayout";
import PublicLayout from "../../layouts/PublicLayout";
import PrivateLayout from "../../layouts/PrivateLayout";
import PrincipalLayout from "../../layouts/PrincipalLayout";
import {
  Authenticated,
  Unauthenticated,
  withGuard,
} from "../../components/Guards";
import { isSubscriptionPage } from "../../utils/common";
import { ContentLoaderContainer } from "../../components/Loader/ContentLoader/ContentLoaderContainer";
import { ContentLoaderSubscription } from "../../components/Loader/ContentLoader/ContentLoaderSubscription/ContentLoaderSubscription";
import { Loader } from "../../components/Loader/Loader";

export const fallback = <ContentLoaderContainer />;

export const publicFallback = <Loader />;

export const subscriptionFallback = <ContentLoaderSubscription />;

export const oidcFallback = () => {
  return <Loader isSubscriptionLoader={isSubscriptionPage()} />;
};

export const oidcSignoutFallback = <div />;

export const LAYOUT = {
  base: BaseLayout,
  public: withGuard(Unauthenticated)(PublicLayout),
  private: withGuard(Authenticated)(PrivateLayout),
  principal: withGuard(Authenticated)(PrincipalLayout),
};

export const SIGNUP_REDIRECTION_LINK =
  process.env.REACT_APP_SIGNUP_REDIRECTION_LINK || "/buy";
