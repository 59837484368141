/* eslint-disable @typescript-eslint/camelcase */
import { API } from "../api";
import { url } from "../constants";
import {
  BillingToken,
  IBillingAccount,
  IBillingAccountListResponse,
  IBillingInfo,
  ICustomer,
  ICustomerPrincipal,
  ICustomerPrincipalListResponse,
  ICustomerPrincipalResponseData,
  IGetLicenseRequest,
  ILicense,
  ILicenseResponse,
  IPrincipalCreatePayload,
  IPrincipalLicence,
  ISignUpPreviewRequest,
  ISignUpPreviewResponse,
  IUserCreatePayload,
  IUserCreateResponse,
  IUserPermissionsResponse,
  IUserResponseData,
  IUserUpdatePayload,
  IUserUpdateResponse,
  Invoice,
} from "./types";
import { getIdTokenSubject } from "../../auth";
import { IAPIRequestConfig, IParams } from "../types";
import { PERMISSION_ALIASES } from "../../../constants/permissions";
import { Action, Permissions, Subject } from "../../../models/permissions";
import { getETagCode, validateArgs } from "../utils";
import { IInvoiceResponseData } from "../../../types/common";

export class UserAPIService {
  constructor(private api: API) {}

  public async get(principalId: string) {
    if (!principalId) {
      throw Error("Principal ID is not available");
    }

    const { data } = await this.api.get<IUserResponseData>(
      url.principal.profile.replace(":principalId", principalId),
    );

    return data;
  }

  public async update(
    { currentPassword, ...user }: IUserUpdatePayload,
    principalId?: string,
  ): Promise<IUserUpdateResponse> {
    const id = principalId ? principalId : await getIdTokenSubject();

    if (!id) {
      throw Error("Principal ID is not available");
    }

    const { data } = await this.api.patch<IUserUpdateResponse>(
      url.principal.profile.replace(":principalId", id),
      user,
      {
        headers: {
          "X-Current-Password": currentPassword,
        },
      },
    );

    return data;
  }

  public async create(user: IUserCreatePayload, params?: IAPIRequestConfig) {
    const { data } = await this.api.post<IUserCreateResponse>(
      url.actions.signup,
      user,
      params,
    );

    return data;
  }

  public async delete(customerId?: string, currentPassword?: string) {
    if (!currentPassword) {
      throw Error("Current password not provided");
    }

    if (!customerId) {
      throw Error("Customer ID not provided");
    }

    const { data } = await this.api.post(
      url.customer.delete.replace(":customerId", customerId),
      { password: currentPassword },
      {},
    );

    return data;
  }

  public async getPermissions() {
    const { data } = await this.api.get<IUserPermissionsResponse>(
      url.user.getPermissions,
    );

    return data.permissions
      .map((permission) => {
        return PERMISSION_ALIASES[permission] ?? permission;
      })
      .reduce<Permissions>((permissions, permission) => {
        // Don't rely on 1 to 1 mapping between API response and app models
        const [accessType, context] = permission.split(":") as [
          Action,
          Subject,
        ];
        const accessTypes = permissions[context] ?? [];

        return {
          ...permissions,
          [context]: [...accessTypes, accessType],
        };
      }, {});
  }

  public async getBillingAccounts(
    customerId: string,
  ): Promise<IBillingAccount[]> {
    validateArgs(customerId);
    const { data } = await this.api.get<IBillingAccountListResponse>(
      url.customer.billingInfo.base.replace(":customerId", customerId),
    );
    return data.items;
  }

  public async getBillingInfo(spId: string, customerId: string) {
    validateArgs(customerId, spId);
    const { data } = await this.api.get<IBillingInfo>(
      url.customer.billingInfo.get
        .replace(":customerId", customerId)
        .replace(":spId", spId),
    );
    return data;
  }

  public async setBillingInfo(
    billingToken: BillingToken,
    customerId: string,
    country?: string,
    postalCode?: string,
    region?: string,
  ) {
    validateArgs(customerId);
    const { data } = await this.api.post<IBillingInfo>(
      url.customer.billingInfo.set.replace(":customerId", customerId),
      {
        billing_token: billingToken,
        country: country,
        postal_code: postalCode,
        region: region,
      },
    );

    return data;
  }

  public async removeBillingInfo(spCode: string, customerId: string) {
    validateArgs(customerId, spCode);
    const { data } = await this.api.post<void>(
      url.customer.billingInfo.delete
        .replace(":spCode", spCode)
        .replace(":customerId", customerId),
      {
        customer_id: customerId,
      },
    );
    return data;
  }

  public async getCustomerPrincipals(customerId: string, params?: IParams) {
    validateArgs(customerId);
    const { data } = await this.api.get<ICustomerPrincipalListResponse>(
      url.customer.principals.base.replace(":customerId", customerId),
      { params },
    );
    return data;
  }

  public async getCustomerPrincipal(
    customerId: string,
    principalId: string,
  ): Promise<ICustomerPrincipalResponseData> {
    validateArgs(customerId);
    const response = await this.api.get<ICustomerPrincipal>(
      url.customer.principals.edit
        .replace(":customerId", customerId)
        .replace(":principalId", principalId),
    );

    return {
      data: response.data,
      eTag: response.headers?.etag,
    } as any;
  }

  public async getLicense(data: IGetLicenseRequest): Promise<ILicenseResponse> {
    validateArgs(data.customerId, data.subscriptionId);
    const response = await this.api.get<ILicense>(
      url.customer.licenses
        .replace(":customerId", data.customerId)
        .replace(":subscriptionId", data.subscriptionId),
    );

    return {
      ...response.data,
      eTag: response.headers.etag,
    };
  }

  public async editLicense(
    data: IGetLicenseRequest,
    customerLicense: IPrincipalLicence[],
    eTag: string,
  ) {
    validateArgs(data.customerId, data.subscriptionId);
    const etag = getETagCode(eTag);

    if (etag) {
      this.api.addHeader("If-Match", etag);
    } else {
      this.api.addHeader("If-None-Match", "*");
    }
    const { data: license } = await this.api.put<ILicense>(
      url.customer.licenses
        .replace(":customerId", data.customerId)
        .replace(":subscriptionId", data.subscriptionId),
      customerLicense,
    );
    if (etag) {
      this.api.removeHeader("If-Match");
    } else {
      this.api.removeHeader("If-None-Match");
    }
    return license;
  }

  public async createCustomerPrincipal(
    customerId: string,
    body: IPrincipalCreatePayload,
  ) {
    validateArgs(customerId);
    const { data } = await this.api.post<ICustomerPrincipal>(
      url.customer.principals.base.replace(":customerId", customerId),
      body,
    );

    return data;
  }

  public async getCustomer(customerId: string): Promise<ICustomer> {
    validateArgs(customerId);
    const { data } = await this.api.get<ICustomer>(
      url.customer.base.replace(":customerId", customerId),
    );

    return data;
  }

  public async editCustomer(
    customerId: string | undefined,
    customerData: Partial<ICustomer>,
  ): Promise<ICustomer> {
    validateArgs(customerId);
    const { data } = await this.api.patch<ICustomer>(
      url.customer.base.replace(":customerId", customerId || ""),
      customerData,
    );

    return data;
  }

  public async editCustomerPrincipal(
    customerId: string,
    principalId: string,
    body: Partial<IPrincipalCreatePayload>,
    etag = "",
  ) {
    validateArgs(customerId, principalId);
    if (etag) {
      this.api.addHeader("If-Match", etag);
    } else {
      this.api.addHeader("If-None-Match", "*");
    }

    const { data } = await this.api.patch<ICustomerPrincipal>(
      url.customer.principals.edit
        .replace(":customerId", customerId)
        .replace(":principalId", principalId),
      body,
    );

    if (etag) {
      this.api.removeHeader("If-Match");
    } else {
      this.api.removeHeader("If-None-Match");
    }

    return data;
  }

  async signupPreview(body: ISignUpPreviewRequest, params?: IAPIRequestConfig) {
    const { data } = await this.api.post<ISignUpPreviewResponse>(
      url.actions.signupPreview,
      body,
      params,
    );

    return data;
  }

  public async getInvoices({
    customerId,
    subscriptionId,
  }: {
    customerId: string;
    subscriptionId: string;
  }): Promise<Invoice[]> {
    validateArgs(customerId, subscriptionId);

    const { data } = await this.api.get<IInvoiceResponseData>(
      url.customer.billingInfo.invoices
        .replace(":customerId", customerId)
        .replace(":subscriptionId", subscriptionId),
    );

    return data.items;
  }
}
