import React, { FC } from "react";

import { LoaderProps } from "./types";
import { OfferingLoader } from "./OfferingLoader/OfferingLoader";

import "./styles.scss";

export const ContentLoaderSubscription: FC<LoaderProps> = ({ className }) => {
  return (
    <>
      <div className="ams-layout__content-title-loader loading" />
      <div className="subscription-loader">
        <div className="subscription-loader__header" />
        <div className="ams-layout__content-loader">
          <div className="ams-layout__content-loader-inside">
            <div className="ams-layout__content-loader-title" />
            <div className="ams-layout__content-loader-content">
              <div>
                <div className="ams-layout__content-loader-left" />
                <div className="ams-layout__content-loader-right" />
              </div>
              <div>
                <div className="ams-layout__content-loader-left" />
                <div className="ams-layout__content-loader-right" />
              </div>
              <div>
                <div className="ams-layout__content-loader-left" />
                <div className="ams-layout__content-loader-right" />
              </div>
            </div>
          </div>
        </div>
        <OfferingLoader />
      </div>
    </>
  );
};
