import { createSelector } from "reselect";
import { isUndefined } from "lodash";

import { ICurrentSubscriptionDetails, IState } from "./types";
import { IRootState } from "../reducers";
import { getOfferingDetailsList } from "../../utils/common";
import {
  ICustomerSKUDetails,
  IOfferingDetailsResponse,
} from "../../services/api/offering/types";
import {
  ISubscription,
  ISubscriptionOptions,
} from "../../services/api/subscription/types";
import { selectCurrentProductLine } from "../metadata";
import { selectAssignedLicenses } from "../manageUsers";
import { selectCurrentSKU } from "../skuMapping";

export const selectSubscriptionsState = (state: IRootState): IState => {
  return state.subscriptions;
};

export const selectIsFetching = createSelector(
  [selectSubscriptionsState],
  (subscriptionsState: IState): boolean => {
    return subscriptionsState.isFetching;
  },
);

export const selectCurrentSubscriptionDetailLoaded = createSelector(
  [selectSubscriptionsState],
  (subscriptionsState: IState): boolean => {
    return subscriptionsState.isCurrentSubscriptionDetailLoaded;
  },
);

export const selectIsCreateFetching = createSelector(
  [selectSubscriptionsState],
  (subscriptionsState: IState): boolean => {
    return subscriptionsState.isCreateFetching;
  },
);

export const selectIsUpdateFetching = createSelector(
  [selectSubscriptionsState],
  (subscriptionsState: IState): boolean => {
    return subscriptionsState.isUpdateFetching;
  },
);

export const selectIsPauseSubscriptionFetching = createSelector(
  [selectSubscriptionsState],
  (subscriptionsState: IState): boolean => {
    return subscriptionsState.isPauseSubscriptionFetching;
  },
);

export const selectIsCancelFetching = createSelector(
  [selectSubscriptionsState],
  (subscriptionsState: IState): boolean => {
    return subscriptionsState.isCancelFetching;
  },
);

export const selectIsUncancelFetching = createSelector(
  [selectSubscriptionsState],
  (subscriptionsState: IState): boolean => {
    return subscriptionsState.isUncancelFetching;
  },
);

export const selectIsRecycleFetching = createSelector(
  [selectSubscriptionsState],
  (subscriptionsState: IState): boolean => {
    return subscriptionsState.isRecycleFetching;
  },
);

export const selectIsFetchingPreview = createSelector(
  [selectSubscriptionsState],
  (subscriptionsState: IState): boolean => {
    return subscriptionsState.isPreviewFetching;
  },
);

export const selectError = createSelector(
  [selectSubscriptionsState],
  (subscriptionsState: IState): any => {
    return subscriptionsState.error;
  },
);

export const selectOfferingDetails = createSelector(
  [selectSubscriptionsState],
  (subscriptionsState: IState): IOfferingDetailsResponse | undefined => {
    return subscriptionsState.offeringDetails;
  },
);

export const selectSubscriptionList = createSelector(
  [selectSubscriptionsState],
  (subscriptionsState: IState): ISubscription[] => {
    return subscriptionsState.subscriptionList;
  },
);

export const selectCurrentCustomerSubscription = createSelector(
  [selectSubscriptionList, selectCurrentProductLine],
  (subscriptionsState: ISubscription[], currentProductLine): ISubscription => {
    const sortedList = [...subscriptionsState]
      .filter((subscription) => {
        return (
          subscription?.sku?.product_line_code === currentProductLine?.code
        );
      })
      .sort((a, b) => {
        return (
          new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime()
        );
      });

    return sortedList[0];
  },
);

export const selectCurrentSubscriptionDetail = createSelector(
  [
    selectSubscriptionsState,
    selectCurrentCustomerSubscription,
    selectCurrentSKU,
  ],
  (
    { currentSubscriptionDetail }: IState,
    subscription: ISubscription,
    currentSKU,
  ): ICurrentSubscriptionDetails | undefined => {
    if (!currentSubscriptionDetail || !subscription) {
      return undefined;
    }
    const totalLicenses =
      subscription?.sku?.licenses +
      currentSubscriptionDetail?.sp?.addons?.reduce((currentValue, item) => {
        return currentValue + item.quantity;
      }, 0);

    return {
      ...currentSubscriptionDetail,
      currentSKU,
      skuId: subscription.sku_id,
      isTerminated: subscription.is_terminated,
      isGoodStanding: subscription.is_good_standing,
      licenses: subscription.sku?.licenses || 0,
      offeringCode: subscription.offering_code,
      productLineCode: subscription?.sku?.product_line_code || "",
      totalLicenses: totalLicenses || 0,
      willExpireAt: subscription.will_expire_at,
    };
  },
);

export const selectCurrentSubscriptionId = createSelector(
  [selectCurrentCustomerSubscription],
  (subscription): string | undefined => {
    return subscription?.id;
  },
);

export const selectSubscriptionWithGeoCurrency = createSelector(
  [selectOfferingDetails, selectCurrentSubscriptionDetail],
  (offeringDetails, activeSubscription): ICustomerSKUDetails[] => {
    if (!offeringDetails) {
      return [];
    }

    const currency = activeSubscription?.sp.is_active
      ? activeSubscription?.sp.next_invoice_amount?.currency
      : "USD";

    return getOfferingDetailsList(offeringDetails, currency);
  },
);

export const selectAvailableListSpListWithAddon = createSelector(
  [selectSubscriptionWithGeoCurrency],
  (availableList): ICustomerSKUDetails[] => {
    return availableList
      .filter((item) => {
        return !item.sp.is_addon;
      })
      .map((item) => {
        if (!item.addons.length) {
          return item;
        }

        return {
          ...item,
          currentAddon: availableList.find((skuDetail) => {
            return item.addons.length && item.addons[0] === skuDetail.sku_id;
          }),
        };
      });
  },
);

export const selectSubscriptionPreviewPrice = createSelector(
  [selectSubscriptionsState],
  (subscriptionsState: IState): number | undefined => {
    return subscriptionsState.subscriptionPreview?.estimate.amount;
  },
);

export const selectSubscriptionOptions = createSelector(
  [selectSubscriptionsState],
  (subscriptionsState: IState): ISubscriptionOptions | undefined => {
    return subscriptionsState.subscriptionOptions;
  },
);

export const selectIsFetchingSubscriptionOptions = createSelector(
  [selectSubscriptionsState],
  (subscriptionsState: IState): boolean => {
    return subscriptionsState.isFetchingOptions;
  },
);

export const selectSubscriptionAutoRecycle = createSelector(
  [selectSubscriptionOptions],
  (option): boolean => {
    return option?.options.auto_recycle || false;
  },
);

export const selectFeatureLicenses = createSelector(
  [selectCurrentSubscriptionDetail, selectAssignedLicenses],
  (subscriptionDetail): number | undefined => {
    const skuLicenses = subscriptionDetail?.upcoming?.sku.licenses || 0;
    const addonsLicenses =
      subscriptionDetail?.upcoming?.addons?.reduce((currentValue, item) => {
        return currentValue + item.quantity;
      }, 0) || 0;

    return skuLicenses + addonsLicenses;
  },
);

export const selectIsShouldUnassignedLicences = createSelector(
  [selectFeatureLicenses, selectAssignedLicenses],
  (featureLicenses, assignedLicense): boolean => {
    if (isUndefined(featureLicenses) || featureLicenses === 0) {
      return false;
    }

    return featureLicenses < assignedLicense;
  },
);

export const selectSubscriptionPreview = createSelector(
  [selectSubscriptionsState],
  (subscriptionsState: IState) => {
    return subscriptionsState.subscriptionPreview;
  },
);
