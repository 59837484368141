import React, { FC } from "react";
import classnames from "classnames";

import { IIconProps } from "./types";

import "./styles.scss";

export const Icon: FC<IIconProps> = ({
  className,
  children: icon,
  ...props
}) => {
  if (!icon) {
    return null;
  }

  return (
    <i
      className={classnames(
        "ams-icon",
        "ams-icon--inline",
        `ams-icon--${icon}`,
        className,
      )}
      {...props}
    />
  );
};
