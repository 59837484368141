import { all, call, fork, put, select, takeLatest } from "redux-saga/effects";

import {
  fetchDataFailure,
  fetchDataSuccess,
  fetchProductLinesFailure,
  fetchProductLinesSuccess,
  fetchProductsFailure,
  fetchProductsSuccess,
  setCurrentProductLine,
} from "./actions";
import tenantService from "../../services/api/tenant";
import {
  FETCH_PRODUCTS_REQUEST,
  FETCH_PRODUCT_LINES_REQUEST,
  FETCH_REQUEST,
  UPDATE_CURRENT_PRODUCT_LINE,
} from "./constants";
import productLineAPIService from "../../services/api/productLine";
import productAPIService from "../../services/api/product";
import {
  selectAvailableProductLines,
  selectDefaultProductLine,
} from "./selectors";
import { IProductLine } from "../../services/api/productLine/types";
import { IUpdateCurrentProductLine } from "./types";

export function* fetchTenants() {
  try {
    const data = yield call([tenantService, tenantService.getAll]);

    yield put(fetchDataSuccess(data));
  } catch (error) {
    yield put(fetchDataFailure(error));
  }
}

export function* watchFetchTenants() {
  yield takeLatest([FETCH_REQUEST], fetchTenants);
}

export function* watchGetProducts() {
  yield takeLatest(FETCH_PRODUCTS_REQUEST, getProducts);
}

export function* watchGetProductLines() {
  yield takeLatest(FETCH_PRODUCT_LINES_REQUEST, getProductLines);
}

export function* watchUpdateCurrentProductLine() {
  yield takeLatest(UPDATE_CURRENT_PRODUCT_LINE, updateProductLine);
}

export function* updateProductLine({ payload }: IUpdateCurrentProductLine) {
  const availableProductLines = yield select(selectAvailableProductLines);

  const productLine = availableProductLines.find((item: IProductLine) => {
    return item.code === payload;
  });

  if (productLine) {
    yield put(setCurrentProductLine(productLine));
  }
}

export function* getProducts() {
  try {
    const data = yield call([productAPIService, productAPIService.getAll]);
    yield put(fetchProductsSuccess(data));
  } catch (error) {
    yield put(fetchProductsFailure(error));
  }
}

export function* getProductLines() {
  try {
    const productLines = yield call([
      productLineAPIService,
      productLineAPIService.getAll,
    ]);
    yield put(fetchProductLinesSuccess(productLines));
    const defaultProductLIne = yield select(selectDefaultProductLine);
    yield put(setCurrentProductLine(defaultProductLIne));
  } catch (error) {
    yield put(fetchProductLinesFailure(error));
  }
}

export function* metadataSaga() {
  yield all([
    fork(watchFetchTenants),
    fork(watchGetProducts),
    fork(watchGetProductLines),
    fork(watchUpdateCurrentProductLine),
  ]);
}
