import { BRAND_MAP } from "../../../utils/tenant/constants";

export const supportLinks = {
  [BRAND_MAP.GN.code]: "https://support.giganews.com",
  [BRAND_MAP.GF.code]: "https://support.vyprvpn.com",
  [BRAND_MAP.TN.code]: "https://support.texas.net",
};

export const termsOfServiceLinks = {
  [BRAND_MAP.GN.code]: "https://www.giganews.com/legal/privacy.html",
  [BRAND_MAP.GF.code]: "https://www.vyprvpn.com/terms-of-service",
  [BRAND_MAP.TN.code]: "http://texas.net/disclaimers/privacy.html",
};
