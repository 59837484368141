import { LOCATION_CHANGE, LOCATION_INITIALIZE } from "./constants";

export const changeLocation = (location: any) => {
  return {
    type: LOCATION_CHANGE,
    payload: location,
  };
};

export const initLocation = (location: any) => {
  return {
    type: LOCATION_INITIALIZE,
    payload: location,
  };
};
