import React, { FC } from "react";
import classnames from "classnames";

import { IButtonBaseProps } from "./types";
import { LoaderOverlay } from "../LoaderOverlay/LoaderOverlay";

import "./styles.scss";

export const ButtonBase: FC<IButtonBaseProps> = ({
  className,
  children,
  size,
  isLoading,
  disabled,
  ...props
}) => {
  return (
    <button
      className={classnames(
        "ams-button",
        {
          "ams-button--medium": size === "medium",
          "ams-button--large": size === "large",
        },
        className,
      )}
      disabled={disabled || isLoading}
      {...props}
    >
      {children}

      {isLoading && (
        <LoaderOverlay className="ams-overlay--absolute ams-loader--2xs" />
      )}
    </button>
  );
};

ButtonBase.defaultProps = {
  size: "medium",
};
