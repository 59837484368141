import React, { FC } from "react";
import classnames from "classnames";

import Navigation from "../../components/Navigation";
import { SidebarProps } from "./types";
import { SidebarLoader } from "../../components/Loader/SidebarLoader/SidebarLoader";

import "./styles.scss";

export const Sidebar: FC<SidebarProps> = ({
  className,
  navItems,
  isShowSidebarLoader,
}) => {
  return (
    <aside className={classnames("ams-sidebar", className)}>
      {isShowSidebarLoader ? (
        <SidebarLoader />
      ) : (
        <Navigation items={navItems} />
      )}
    </aside>
  );
};
