import {
  ACH_BANK_DRAFT,
  AMAZON,
  CREDIT_CARD,
  PAYPAL,
  SEPA_BANK_DRAFT,
} from "./contants";
import {
  CommonPayload,
  IAddACHBankDraftPaymentMethod,
  IAddAmazonPaymentMethod,
  IAddCreditCardPaymentMethod,
  IAddPayPalPaymentMethod,
  PaymentMethodPayload,
  PaymentMethodTypes,
} from "./types";
import { IBillingInfo } from "../../../../services/api/user/types";
import { ACH_BANK_DRAFT_METHOD, SEPA_BANK_DRAFT_METHOD } from "../../contants";

const creditCardPayload = (
  data: CommonPayload,
): IAddCreditCardPaymentMethod => {
  return {
    type: CREDIT_CARD,
    event: data.event,
  };
};

const payPalPayload = (): IAddPayPalPaymentMethod => {
  return {
    type: PAYPAL,
  };
};

const payAmazonload = (data: CommonPayload): IAddAmazonPaymentMethod => {
  return {
    type: AMAZON,
    data: data.amazonData,
  };
};

const bankDraftPayload = (
  data: CommonPayload,
): IAddACHBankDraftPaymentMethod => {
  return {
    type: ACH_BANK_DRAFT,
    event: data.event,
  };
};

const paymentMethodMap = {
  [CREDIT_CARD]: creditCardPayload,
  [PAYPAL]: payPalPayload,
  [AMAZON]: payAmazonload,
  [ACH_BANK_DRAFT]: bankDraftPayload,
  [SEPA_BANK_DRAFT]: bankDraftPayload,
};

export const getPayload = (
  paymentType: PaymentMethodTypes,
  data: CommonPayload,
): PaymentMethodPayload => {
  return paymentMethodMap[paymentType](data);
};

export const getDefaultMethod = (
  bilingInfo: IBillingInfo,
): PaymentMethodTypes => {
  if (bilingInfo.payment_info.payment_method === ACH_BANK_DRAFT_METHOD) {
    return ACH_BANK_DRAFT;
  }

  if (bilingInfo.payment_info.payment_method === SEPA_BANK_DRAFT_METHOD) {
    return SEPA_BANK_DRAFT;
  }
  return CREDIT_CARD;
};
