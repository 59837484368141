import * as Sentry from "@sentry/react";

export function initSentryForProdVyprvpn() {
  if (window.location.host === "account.vyprvpn.com") {
    Sentry.init({
      dsn:
        "https://788986a8d60c41c68946dff66025921b@o216067.ingest.sentry.io/5689519",
    });
  }
}
