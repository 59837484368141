import { IUserState } from "./types";

export const USER_FETCH_REQUEST = "user/fetchRequest";
export const USER_FETCH_FAILURE = "user/fetchFailure";
export const USER_FETCH_SUCCESS = "user/fetchSuccess";
export const FETCH_CURRENT_PRINCIPAL = "user/fetchCurrentPrincipal";
export const FETCHING_CURRENT_PRINCIPAL = "user/fetchingCurrentPrincipal";
export const FETCH_CURRENT_PRINCIPAL_SUCCESS =
  "user/fetchCurrentPrincipalSuccess";
export const FETCH_CURRENT_PRINCIPAL_ERROR = "user/fetchCurrentPrincipalError";
export const FETCH_CURRENT_CUSTOMER = "user/fetchCurrentCustomer";
export const FETCHING_CURRENT_CUSTOMER = "user/fetchingCurrentCustomer";
export const FETCH_CURRENT_CUSTOMER_SUCCESS =
  "user/fetchCurrentCustomerSuccess";
export const FETCH_CURRENT_CUSTOMER_ERROR = "user/fetchCurrentCustomerError";
export const CLEAR_CUTOMER_DATA = "user/clearCustomerData";
export const CLEAR_PRINCIPAL_DATA = "user/clearPrincipalData";
export const FETCH_BILLING_ACCOUNT_LIST = "user/fetchBillingAccountList";
export const FETCH_BILLING_ACCOUNT_LIST_SUCCESS =
  "user/fetchBillingAccountListSuccess";
export const FETCH_BILLING_ACCOUNT_LIST_ERROR =
  "user/fetchBillingAccountListError";
export const FETCH_BILLING_DATA = "user/fetchBillingData";
export const FETCH_BILLING_DATA_SUCCESS = "user/fetchBillingDataSuccess";
export const FETCH_BILLING_DATA_ERROR = "user/fetchBillingDataError";
export const REMOVE_BILLING_DATA = "user/removeBillingData";
export const REMOVE_BILLING_DATA_SUCCESS = "user/removeBillingDataSuccess";
export const REMOVE_BILLING_DATA_ERROR = "user/removeBillingDataError";
export const SET_PAYMENT_COUNTRY = "user/setPaymentCountry";
export const SET_PAYMENT_POSTAL_CODE = "user/setPaymentPostalCode";
export const SET_PAYMENT_REGION = "user/setPaymentRegion";

export const initialState: IUserState = {
  data: {},
  principalData: undefined,
  customerData: undefined,
  isFetchingPrincilal: false,
  isFetchingCustomer: false,
  isUserReady: false,
  isBillingDataLoaded: false,
  country: undefined,
  postalCode: undefined,
  region: undefined,
};

export const MAX_INVOICES_NUMBER = 12;
