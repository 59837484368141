import { all, call, fork, put, select, takeLatest } from "redux-saga/effects";

import { FETCH_USAGE } from "./constants";
import usageService from "../../services/api/usage";
import { fetchUsageError, fetchUsageSuccess } from "./actions";
import { selectCustomerData } from "../user";
import { IFetchUsage } from "./types";

function* fetchUsageList({ payload }: IFetchUsage) {
  const customer = yield select(selectCustomerData);

  try {
    const usageList = yield call([usageService, usageService.getUsageList], {
      customerId: customer.id,
      subscriptionId: payload.subscriptionId,
    });
    yield put(fetchUsageSuccess(usageList));
  } catch (e) {
    yield put(fetchUsageError(e));
  }
}

function* watchFetchUsage() {
  yield takeLatest(FETCH_USAGE, fetchUsageList);
}

export function* usageSaga() {
  yield all([fork(watchFetchUsage)]);
}
