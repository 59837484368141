import React, { FC, useEffect } from "react";
import { RouteProps } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { useIsAuthenticated, useUserManager } from "../../services/auth";
import ProtectedRoute from "../ProtectedRoute";
import { usePermissionsQuery, useReturnToLocation } from "../../hooks";
import {
  fetchCurrentPrincipal,
  selectIsFetchingPrincipalAndCustomer,
  selectPrincipalData,
} from "../../store/user";
import { selectBrandCode } from "../../store/metadata";
import { selectIsCurrentSubscriptionReady } from "../../store/common/selectors";
import { ContentLoaderContainer } from "../Loader/ContentLoader/ContentLoaderContainer";
import { isSubscriptionPage } from "../../utils/common";
import { selectOIDCUserId } from "../../store/oidc";

export const PrivateRoute: FC<RouteProps> = (props) => {
  const dispatch = useDispatch();
  const isAuthenticated = useIsAuthenticated();
  const userManager = useUserManager();
  const { saveCurrentLocation } = useReturnToLocation();
  const permissionsQuery = usePermissionsQuery();
  const userData = useSelector(selectPrincipalData);
  const isPrincipalDataLoading = useSelector(
    selectIsFetchingPrincipalAndCustomer,
  );
  const isCurrentSubscriptionReady = useSelector(
    selectIsCurrentSubscriptionReady,
  );
  const brandCode = useSelector(selectBrandCode);
  const OIDCPrincipalId = useSelector(selectOIDCUserId);

  useEffect(() => {
    if (isAuthenticated) {
      permissionsQuery.refetch();
      return;
    }

    saveCurrentLocation();
    userManager.signinRedirect();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!userData && brandCode && brandCode !== "ADMIN" && OIDCPrincipalId) {
      dispatch(fetchCurrentPrincipal(OIDCPrincipalId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [brandCode, userData, OIDCPrincipalId]);

  const isRouteReady =
    isAuthenticated &&
    Boolean(permissionsQuery.data) &&
    !isPrincipalDataLoading &&
    isCurrentSubscriptionReady;

  return isRouteReady ? (
    <ProtectedRoute {...props} />
  ) : (
    <>
      <ContentLoaderContainer isSubscriptionLoader={isSubscriptionPage()} />
    </>
  );
};
