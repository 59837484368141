import brandAPIService from "../../services/api/brand";
import brandService from "../../services/brand";
import i18n from "../../i18n";
import { config as i18nConfig } from "../../i18n/constants";
import { IAppData } from "./types";
import store from "../../store";
import { setCaptchas, setSPS, setSettings } from "../../store/metadata";
import { initCurrencyList } from "../../services/currency/currency";

export default async function bootstrap() {
  await initServices();

  const appData = await fetchAppData();

  storeAppData(appData);
  initCurrencyList();

  return appData;
}

async function initServices() {
  await i18n.init(i18nConfig);
}

async function fetchAppData(): Promise<IAppData> {
  const originInfo = await brandAPIService.get();

  return { originInfo };
}

function storeAppData(data: IAppData) {
  brandService.set(data.originInfo.tenant_code || data.originInfo.kind);
  store.dispatch(setSettings(data.originInfo.settings));
  if (data.originInfo.sps) {
    store.dispatch(setSPS(data.originInfo.sps));
  }
  if (data.originInfo.captchas) {
    store.dispatch(setCaptchas(data.originInfo.captchas));
  }
}
