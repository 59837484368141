import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import noop from "lodash/noop";

import { REGIONS } from "../../../../components/AddressFormContent/contants";
import FormGroup from "../../../../components/FormGroup";
import Label from "../../../../components/Label";
import Input from "../../../../components/Input";
import Select from "../../../../components/Select";
import { createChangeEventHandler } from "../../../../utils/common";
import { ACHFormProps } from "./types";
import { ACCOUNT_TYPES } from "./contants";

import "./styles.scss";

export const ACHForm: FC<ACHFormProps> = ({
  onValidationChange,
  billingInfo,
}) => {
  const { t } = useTranslation();
  const accountName = billingInfo?.customer_info
    ? `${billingInfo.customer_info.first_name} ${billingInfo.customer_info.last_name}`
    : "";

  const [routingNumber, setRoutingNumber] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [confirmAccountNumber, setConfirmAccountNumber] = useState("");
  const defaultAccountType =
    billingInfo?.payment_info.account_type || ACCOUNT_TYPES[0];
  const [accountType, setAccountType] = useState(defaultAccountType);
  const [nameOnAccount, setNameOnAccount] = useState(accountName);
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [postalCode, setPostalCode] = useState("");

  const isValid =
    Boolean(routingNumber) &&
    Boolean(accountNumber) &&
    Boolean(confirmAccountNumber) &&
    Boolean(accountType) &&
    Boolean(nameOnAccount) &&
    Boolean(address) &&
    Boolean(city) &&
    Boolean(state) &&
    Boolean(postalCode);

  useEffect(() => {
    onValidationChange(isValid);
  }, [isValid]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <FormGroup>
        <Label>{t("routingNumber")}</Label>
        <Input
          id="input-routing_number"
          data-recurly="routing_number"
          value={routingNumber}
          onChange={createChangeEventHandler(setRoutingNumber)}
        />
      </FormGroup>

      <FormGroup>
        <Label>{t("accountNumber")}</Label>
        <Input
          id="input-account_number"
          data-recurly="account_number"
          value={accountNumber}
          onChange={createChangeEventHandler(setAccountNumber)}
        />
      </FormGroup>

      <FormGroup>
        <Label>{t("confirmAccountNumber")}</Label>
        <Input
          id="input-account_number_confirmation"
          data-recurly="account_number_confirmation"
          value={confirmAccountNumber}
          onChange={createChangeEventHandler(setConfirmAccountNumber)}
        />
      </FormGroup>

      <Input
        className="ams-input__country"
        id="input-country"
        data-recurly="country"
        value="US"
        onChange={noop}
      />

      <FormGroup>
        <Label>{t("accountType")}</Label>
        <Select
          id="input-account_type"
          data-recurly="account_type"
          value={accountType}
          onChange={createChangeEventHandler(setAccountType)}
        >
          {ACCOUNT_TYPES.map((accountTypeData) => {
            return (
              <option value={accountTypeData} key={accountTypeData}>
                {t(accountTypeData)}
              </option>
            );
          })}
        </Select>
      </FormGroup>

      <FormGroup>
        <Label>{t("nameOnAccount")}</Label>
        <Input
          id="input-name_on_account"
          data-recurly="name_on_account"
          value={nameOnAccount}
          onChange={createChangeEventHandler(setNameOnAccount)}
        />
      </FormGroup>

      <FormGroup>
        <Label>{t("address")}</Label>
        <Input
          id="input-address"
          data-recurly="address1"
          value={address}
          onChange={createChangeEventHandler(setAddress)}
        />
      </FormGroup>

      <FormGroup>
        <Label>{t("city")}</Label>
        <Input
          id="input-city"
          data-recurly="city"
          value={city}
          onChange={createChangeEventHandler(setCity)}
        />
      </FormGroup>

      <FormGroup>
        <Label>{t("state")}</Label>
        <Select
          id="input-state"
          data-recurly="state"
          value={state}
          onChange={createChangeEventHandler(setState)}
        >
          <option value="">{t("chooseState")}</option>
          {REGIONS.US.map((key: string) => {
            return (
              <option value={key} key={key}>
                {t(`regions.US.${key}`)}
              </option>
            );
          })}
        </Select>
      </FormGroup>

      <FormGroup>
        <Label>{t("postalCode")}</Label>
        <Input
          id="input-postal-code"
          data-recurly="postal_code"
          value={postalCode}
          onChange={createChangeEventHandler(setPostalCode)}
        />
      </FormGroup>
      <input type="hidden" value="US" data-recurly="country" />
    </>
  );
};
