import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import FormGroup from "../../../../components/FormGroup";
import Label from "../../../../components/Label";
import Input from "../../../../components/Input";
import { SEPAFormProps } from "./types";
import { createChangeEventHandler } from "../../../../utils/common";

export const SEPAForm: FC<SEPAFormProps> = ({
  onValidationChange,
  billingInfo,
}) => {
  const { t } = useTranslation();
  const accountName = billingInfo?.customer_info
    ? `${billingInfo.customer_info.first_name} ${billingInfo.customer_info.last_name}`
    : "";

  const [iban, setIban] = useState("");
  const [nameOnAccount, setNameOnAccount] = useState(accountName);
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");

  const isValid =
    Boolean(iban) &&
    Boolean(nameOnAccount) &&
    Boolean(address) &&
    Boolean(city);

  useEffect(() => {
    onValidationChange(isValid);
  }, [isValid]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <FormGroup>
        <Label>{t("nameOnAccount")}</Label>
        <Input
          data-recurly="name_on_account"
          id="input-name_on_account"
          value={nameOnAccount}
          onChange={createChangeEventHandler(setNameOnAccount)}
        />
      </FormGroup>
      <FormGroup>
        <Label>{t("iban")}</Label>
        <Input
          data-recurly="iban"
          id="input-iban"
          value={iban}
          onChange={createChangeEventHandler(setIban)}
        />
      </FormGroup>
      <FormGroup>
        <Label>{t("address")}</Label>
        <Input
          id="input-address"
          data-recurly="address1"
          value={address}
          onChange={createChangeEventHandler(setAddress)}
        />
      </FormGroup>
      <FormGroup>
        <Label>{t("city")}</Label>
        <Input
          id="input-city"
          data-recurly="city"
          value={city}
          onChange={createChangeEventHandler(setCity)}
        />
      </FormGroup>
    </>
  );
};
