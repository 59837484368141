import {
  all,
  call,
  debounce,
  fork,
  put,
  select,
  takeLatest,
} from "redux-saga/effects";

import {
  cancelSubscriptionError,
  cancelSubscriptionSuccess,
  createSubscriptionError,
  createSubscriptionSuccess,
  editSubscriptionOptionsError,
  editSubscriptionOptionsSuccess,
  fetchCurrentSubscriptionSuccess,
  fetchDataFailure,
  fetchDataRequest,
  fetchSubscriptionList,
  fetchSubscriptionOptions,
  fetchSubscriptionOptionsError,
  fetchSubscriptionOptionsSuccess,
  fetchSubscriptionPreview,
  fetchSubscriptionPreviewError,
  recycleSubscriptionError,
  recycleSubscriptionSuccess,
  setNoCurrentSubscription,
  setOfferingDetails,
  setSubscriptionList,
  setSubscriptionPreview,
  uncancelSubscriptionError,
  uncancelSubscriptionSuccess,
  updateSubscriptionError,
  updateSubscriptionSuccess,
} from "./actions";
import {
  CANCEL_SUBSCRIPTION,
  CREATE_SUBSCRIPTION,
  EDIT_OPTIONS,
  FETCH_CURRENT_SUBSCRIPTION,
  FETCH_DATA_REQUEST,
  FETCH_OPTIONS,
  FETCH_SUBSCRIPTION_PREVIEW_TRIGGER,
  FETCH_UPDATE_SUBSCRIPTION_PREVIEW_TRIGGER,
  PAUSE_SUBSCRIPTION,
  RECYCLE_SUBSCRIPTION,
  UNCANCEL_SUBSCRIPTION,
  UNPAUSE_SUBSCRIPTION,
  UPDATE_SUBSCRIPTION,
} from "./constants";
import {
  ICancelSubscription,
  ICreateSubscription,
  IEditSubscriptionOptions,
  IFetchDataRequestAction,
  IFetchSubscriptionOptions,
  IFetchSubscriptionPreviewTrigger,
  IFetchUpdateSubscriptionPreviewTrigger,
  IPauseSubscription,
  IRecycleSubscription,
  IUncancelSubscription,
  IUpdateSubscription,
} from "./types";
import offeringAPIService from "../../services/api/offering";
import subscriptionAPIService from "../../services/api/subscription";
import { ISubscription } from "../../services/api/subscription/types";
import {
  selectCurrentCustomerSubscription,
  selectCurrentSubscriptionDetail,
  selectSubscriptionOptions,
} from "./selectors";
import { EXTERNAL_SP_LIST } from "../../constants/spCode";
import {
  SET_CURRENT_PRODUCT_LINE,
  selectCurrentProductLine,
} from "../metadata";
import { selectPrincipalData } from "../user";
import { fetchUsage } from "../usage";
import {
  fetchClearFeatureDetailsAction,
  fetchDetailsAction,
  fetchFeatureDetailsAction,
} from "../skuMapping";
import { fetchLicensesRequest } from "../manageUsers";
import abilityService from "../../services/ability";
import { ACTION, SUBJECT } from "../../constants/permissions";
import { getSubscriptionStatus } from "../../utils/common";
import { SUBSCRIPTION_STATUS } from "../../utils/common/contants";

export function* getCustomersSubscriptionsData({ payload }: any) {
  try {
    const customerSubscriptions: ISubscription[] = yield call(
      [subscriptionAPIService, subscriptionAPIService.getAll],
      payload.customerId,
      {
        embed: "(sku)",
      },
    );

    yield put(setSubscriptionList(customerSubscriptions));
    yield call(getCurrentSubscriptionDetail);
  } catch (e) {
    yield put(fetchDataFailure(e));
  }
}

export function* getCurrentSubscriptionDetail() {
  const currentSubscription = yield select(selectCurrentCustomerSubscription);

  if (currentSubscription) {
    try {
      const currentSubscriptionDetail = yield call(
        [subscriptionAPIService, subscriptionAPIService.getDetail],
        currentSubscription.customer_id,
        currentSubscription.id,
      );

      yield put(fetchCurrentSubscriptionSuccess(currentSubscriptionDetail));
      yield put(fetchDetailsAction({ skuId: currentSubscription.sku_id }));

      if (abilityService.getAbility().can(ACTION.READ, SUBJECT.TECHNICAL)) {
        yield put(fetchLicensesRequest(currentSubscription.id));
      }

      if (
        currentSubscriptionDetail.upcoming &&
        currentSubscription.sku_id !== currentSubscriptionDetail.upcoming.sku_id
      ) {
        yield put(
          fetchFeatureDetailsAction({
            skuId: currentSubscriptionDetail.upcoming.sku_id,
          }),
        );
      }
    } catch (e) {
      yield put(fetchDataFailure(e));
    }
  } else {
    yield put(setNoCurrentSubscription());
  }
}

export function* getOfferingsData() {
  const subscription = yield select(selectCurrentCustomerSubscription);
  const subscriptionDetails = yield select(selectCurrentSubscriptionDetail);
  const currentProductLine = yield select(selectCurrentProductLine);

  const status =
    subscriptionDetails && getSubscriptionStatus(subscriptionDetails);
  const isShowDefault = !subscription || status === SUBSCRIPTION_STATUS.expired;

  try {
    if (
      (isShowDefault ||
        EXTERNAL_SP_LIST.includes(subscriptionDetails?.sp.sp_code)) &&
      currentProductLine
    ) {
      yield call(
        fetchDefaultOfferings,
        currentProductLine.default_offering_code,
      );
      return;
    }

    const offeringDetailsData = yield call(
      [offeringAPIService, offeringAPIService.getDetails],
      subscription?.offering_code,
    );
    yield put(setOfferingDetails(offeringDetailsData));
  } catch (e) {
    yield put(fetchDataFailure(e));
  }
}

export function* fetchDefaultOfferings(offeringCode: string) {
  try {
    const offeringDetailsData = yield call(
      [offeringAPIService, offeringAPIService.getDetails],
      offeringCode,
    );
    yield put(setOfferingDetails(offeringDetailsData));
  } catch (e) {
    yield put(fetchDataFailure(e));
  }
}

export function* getData({ payload }: IFetchDataRequestAction) {
  yield call(
    getCustomersSubscriptionsData,
    fetchSubscriptionList({ customerId: payload.customerId }),
  );
  yield call(getOfferingsData);
}

function* handleUpdateCurrentProductLine() {
  const principal = yield select(selectPrincipalData);
  if (principal) {
    yield put(fetchDataRequest({ customerId: principal.customer_id }));
  }
}

/* eslint-disable @typescript-eslint/camelcase */
function* getSubscriptionPreview({
  payload,
  onSuccess,
  onError,
}: IFetchSubscriptionPreviewTrigger) {
  yield put(fetchSubscriptionPreview());
  const principal = yield select(selectPrincipalData);

  try {
    const { skuId, currency, promotionToken, offeringCode } = payload;
    const subscriptionPreview = yield call(
      [subscriptionAPIService, subscriptionAPIService.previewSubscription],
      principal.customer_id,
      {
        sku_id: skuId,
        currency,
        promotion_token: promotionToken,
        offering_code: offeringCode,
      },
    );
    yield put(setSubscriptionPreview(subscriptionPreview));
    yield call(onSuccess, subscriptionPreview);
  } catch (error) {
    yield put(fetchSubscriptionPreviewError(error));
    yield call(onError, error);
  }
}

/* eslint-disable @typescript-eslint/camelcase */
function* updateSubscriptionPreview({
  payload,
  onSuccess,
  onError,
}: IFetchUpdateSubscriptionPreviewTrigger) {
  yield put(fetchSubscriptionPreview());
  const currentSubscriptionDetail = yield select(
    selectCurrentSubscriptionDetail,
  );
  const principal = yield select(selectPrincipalData);

  try {
    const { skuId, addons } = payload;

    const subscriptionPreview = yield call(
      [
        subscriptionAPIService,
        subscriptionAPIService.previewUpdateSubscription,
      ],
      principal.customer_id,
      currentSubscriptionDetail.subscription_id,
      { addons, sku_id: skuId },
    );
    yield put(setSubscriptionPreview(subscriptionPreview));
    yield call(onSuccess);
  } catch (error) {
    yield put(fetchSubscriptionPreviewError(error));
    yield call(onError, error);
  }
}

function* updateSubscription({ payload }: IUpdateSubscription) {
  const {
    subscriptionId,
    promotionToken,
    skuId,
    addons,
    onSuccess,
    onError,
  } = payload;
  const principal = yield select(selectPrincipalData);

  try {
    const { timeframe } = yield call(
      [subscriptionAPIService, subscriptionAPIService.updateSubscription],
      principal.customer_id,
      subscriptionId,
      { addons, promotion_token: promotionToken, sku_id: skuId },
    );
    yield put(updateSubscriptionSuccess());
    yield put(fetchClearFeatureDetailsAction());
    yield put(fetchDataRequest({ customerId: principal.customer_id }));
    onSuccess && onSuccess(timeframe);
  } catch (e) {
    yield put(updateSubscriptionError());
    onError && onError(e);
  }
}

function* createSubscription({ payload }: ICreateSubscription) {
  const principal = yield select(selectPrincipalData);
  const currentProductLine = yield select(selectCurrentProductLine);
  const {
    currency,
    skuId,
    promotionToken,
    captchaToken,
    onSuccess,
    onError,
    billingInfo,
  } = payload;

  try {
    yield call(
      [subscriptionAPIService, subscriptionAPIService.create],
      {
        currency,
        sku_id: skuId,
        offering_code: currentProductLine.default_offering_code,
        billing_token: billingInfo,
        promotion_token: promotionToken,
        captcha_token: captchaToken,
      },
      principal.customer_id,
    );
    yield put(createSubscriptionSuccess());
    yield put(fetchDataRequest({ customerId: principal.customer_id }));
    onSuccess && onSuccess();
  } catch (error) {
    yield put(createSubscriptionError());
    onError && onError(error);
  }
}

function* cancelSubscription({ payload }: ICancelSubscription) {
  const { cancelReason, onSuccess, onError } = payload;
  const principal = yield select(selectPrincipalData);
  const currentSubscriptionDetail = yield select(
    selectCurrentSubscriptionDetail,
  );

  try {
    yield call([subscriptionAPIService, subscriptionAPIService.cancel], {
      customerId: principal.customer_id,
      subscriptionId: currentSubscriptionDetail.subscription_id,
      cancelReason: cancelReason,
    });

    yield put(cancelSubscriptionSuccess());
    yield put(fetchDataRequest({ customerId: principal.customer_id }));
    onSuccess && onSuccess();
  } catch (e) {
    yield put(cancelSubscriptionError());
    onError && onError();
  }
}

function* uncancelSubscription({ payload }: IUncancelSubscription) {
  const { onSuccess, onError } = payload;
  const principal = yield select(selectPrincipalData);
  const currentSubscriptionDetail = yield select(
    selectCurrentSubscriptionDetail,
  );
  try {
    yield call([subscriptionAPIService, subscriptionAPIService.uncancel], {
      customerId: principal.customer_id,
      subscriptionId: currentSubscriptionDetail.subscription_id,
    });

    yield put(uncancelSubscriptionSuccess());
    yield put(fetchDataRequest({ customerId: principal.customer_id }));
    onSuccess && onSuccess();
  } catch (e) {
    yield put(uncancelSubscriptionError());
    onError && onError();
  }
}

export function* debouncePreview() {
  yield debounce(
    400,
    (action: any) => {
      return action.type === FETCH_SUBSCRIPTION_PREVIEW_TRIGGER;
    },
    getSubscriptionPreview,
  );
}

function* pauseSubscription({ payload }: IPauseSubscription) {
  const { onSuccess, onError } = payload;
  const principal = yield select(selectPrincipalData);
  const currentSubscriptionDetail = yield select(
    selectCurrentSubscriptionDetail,
  );
  try {
    yield call([subscriptionAPIService, subscriptionAPIService.pause], {
      customerId: principal.customer_id,
      subscriptionId: currentSubscriptionDetail.subscription_id,
    });

    yield put(fetchDataRequest({ customerId: principal.customer_id }));
    onSuccess && onSuccess();
  } catch (e) {
    onError && onError();
  }
}

function* unpauseSubscription({ payload }: IPauseSubscription) {
  const { onSuccess, onError } = payload;
  const principal = yield select(selectPrincipalData);
  const currentSubscriptionDetail = yield select(
    selectCurrentSubscriptionDetail,
  );
  try {
    yield call([subscriptionAPIService, subscriptionAPIService.unpause], {
      customerId: principal.customer_id,
      subscriptionId: currentSubscriptionDetail.subscription_id,
    });

    yield put(fetchDataRequest({ customerId: principal.customer_id }));
    onSuccess && onSuccess();
  } catch (e) {
    onError && onError();
  }
}

export function* debounceUpdatePreview() {
  yield debounce(
    400,
    (action: any) => {
      return action.type === FETCH_UPDATE_SUBSCRIPTION_PREVIEW_TRIGGER;
    },
    updateSubscriptionPreview,
  );
}

function* recycleSubscription({ payload }: IRecycleSubscription) {
  const principal = yield select(selectPrincipalData);
  const currentCustomerSubscription = yield select(
    selectCurrentCustomerSubscription,
  );
  const { subscriptionId, onError, onSuccess } = payload;

  try {
    yield call(
      [subscriptionAPIService, subscriptionAPIService.recycle],
      principal.customer_id,
      subscriptionId,
    );
    onSuccess && onSuccess();

    yield put(fetchDataRequest({ customerId: principal.customer_id }));
    yield put(recycleSubscriptionSuccess());
    yield put(
      fetchUsage({
        subscriptionId: currentCustomerSubscription.id,
      }),
    );
  } catch (e) {
    yield put(recycleSubscriptionError());
    yield put(fetchDataFailure(e));
    onError && onError();
  }
}

function* fetchOptions({ payload }: IFetchSubscriptionOptions) {
  const principal = yield select(selectPrincipalData);

  try {
    const options = yield call(
      [subscriptionAPIService, subscriptionAPIService.options],
      principal.customer_id,
      payload.subscriptionId,
    );
    yield put(fetchSubscriptionOptionsSuccess(options));
  } catch (e) {
    yield put(fetchSubscriptionOptionsError(e));
  }
}

function* editOptions({ payload }: IEditSubscriptionOptions) {
  const principal = yield select(selectPrincipalData);
  const options = yield select(selectSubscriptionOptions);
  const { subscriptionId, autoRecycle, onError, onSuccess } = payload;

  try {
    yield call(
      [subscriptionAPIService, subscriptionAPIService.editOptions],
      principal.customer_id,
      subscriptionId,
      autoRecycle,
      options?.eTag,
    );
    yield put(editSubscriptionOptionsSuccess());
    yield put(fetchSubscriptionOptions({ subscriptionId: subscriptionId }));
    onSuccess && onSuccess();
  } catch (error) {
    yield put(editSubscriptionOptionsError());
    onError && onError(error);
  }
}

function* watchGetData() {
  yield takeLatest([FETCH_DATA_REQUEST], getData);
}

function* watchUpdateProductLineData() {
  yield takeLatest([SET_CURRENT_PRODUCT_LINE], handleUpdateCurrentProductLine);
}

function* watchFetchSubscriptionDetail() {
  yield takeLatest(FETCH_CURRENT_SUBSCRIPTION, getCustomersSubscriptionsData);
}

function* watchRecycle() {
  yield takeLatest(RECYCLE_SUBSCRIPTION, recycleSubscription);
}

function* watchFetchSubscriptionOptions() {
  yield takeLatest(FETCH_OPTIONS, fetchOptions);
}

function* watchEditSubscriptionOptions() {
  yield takeLatest(EDIT_OPTIONS, editOptions);
}

function* watchUpdateSubscription() {
  yield takeLatest(UPDATE_SUBSCRIPTION, updateSubscription);
}

function* watchCreateSubscription() {
  yield takeLatest(CREATE_SUBSCRIPTION, createSubscription);
}

function* watchCancelSubscription() {
  yield takeLatest(CANCEL_SUBSCRIPTION, cancelSubscription);
}

function* watchUncancelSubscription() {
  yield takeLatest(UNCANCEL_SUBSCRIPTION, uncancelSubscription);
}

function* watchPauseSubscription() {
  yield takeLatest(PAUSE_SUBSCRIPTION, pauseSubscription);
}

function* watchUnpauseSubscription() {
  yield takeLatest(UNPAUSE_SUBSCRIPTION, unpauseSubscription);
}

export function* subscriptionsSaga() {
  yield all([
    fork(watchGetData),
    fork(watchFetchSubscriptionDetail),
    fork(watchUpdateProductLineData),
    fork(watchRecycle),
    fork(watchFetchSubscriptionOptions),
    fork(watchEditSubscriptionOptions),
    fork(debouncePreview),
    fork(debounceUpdatePreview),
    fork(watchUpdateSubscription),
    fork(watchCreateSubscription),
    fork(watchCancelSubscription),
    fork(watchUncancelSubscription),
    fork(watchPauseSubscription),
    fork(watchUnpauseSubscription),
  ]);
}
