import React, { FC } from "react";
import { Elements, RecurlyProvider, useRecurly } from "@recurly/react-recurly";

import {
  RecurlyFraudConfigWrapperProp,
  WrappedRecurlyProviderProps,
} from "./types";

const RecurlyFraudConfigWrapper: FC<RecurlyFraudConfigWrapperProp> = ({
  children,
  publicKey,
}) => {
  const recurlyJS = useRecurly();

  const fraud = {
    kount: {
      dataCollector: true,
    },
  };

  recurlyJS.configure({
    publicKey: publicKey,
    fraud: fraud,
  });

  return <>{children}</>;
};

export const WrappedRecurlyProvider: FC<WrappedRecurlyProviderProps> = ({
  children,
  publicKey,
}) => {
  if (publicKey) {
    return (
      <RecurlyProvider publicKey={publicKey}>
        <Elements>
          <RecurlyFraudConfigWrapper publicKey={publicKey}>
            {children}
          </RecurlyFraudConfigWrapper>
        </Elements>
      </RecurlyProvider>
    );
  }

  return <>{children}</>;
};
