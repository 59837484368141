import React, { useEffect } from "react";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import { ReactQueryConfigProvider, useQuery } from "react-query";
import { OidcProvider, loadUser } from "redux-oidc";
import { ToastContainer } from "react-toastify";
import * as Sentry from "@sentry/react";

import { queryConfig } from "./config/react-query";
import { configureAppUserManager, createAppUserManager } from "./services/auth";
import store from "./store";
import bootstrap from "./utils/bootstrap";
import { IAppData } from "./utils/bootstrap/types";
import Main from "./pages/Main";
import { AbilityProvider } from "./components/Can";
import { updateMetadata } from "./store/metadata";
import { BRAND_MAP } from "./utils/tenant/constants";
import i18n from "./i18n";
import WrappedRecurlyProvider from "./components/WrappedRecurlyProvider";
import { getRecurlyKey, isSubscriptionPage } from "./utils/common";
import { Loader } from "./components/Loader/Loader";

import "./styles.scss";

export default function App() {
  const { status, data } = useQuery<IAppData, string>("appData", bootstrap, {
    retry: 3,
  });
  const brandCode = data?.originInfo.tenant_code || data?.originInfo.kind;
  const brandCodeName = data?.originInfo.tenant_name || data?.originInfo.kind;
  const recurlyPublicKey = getRecurlyKey(data?.originInfo?.sps);

  const userManager = createAppUserManager();

  useEffect(() => {
    if (status === "success") {
      if (brandCode) {
        store.dispatch(updateMetadata({ brandCode, brandCodeName }));

        if (brandCode === BRAND_MAP.ADMIN.code) {
          i18n.changeLanguage("en");
        }
      }

      configureAppUserManager(data?.originInfo?.oidc);
      loadUser(store, userManager);
    }
  }, [brandCode, brandCodeName, data, status, userManager]);

  if (status !== "success") {
    return (
      <Loader className="App" isSubscriptionLoader={isSubscriptionPage()} />
    );
  }

  return (
    <Sentry.ErrorBoundary>
      <ReactQueryConfigProvider config={queryConfig}>
        <Provider store={store}>
          <OidcProvider store={store} userManager={userManager}>
            <WrappedRecurlyProvider publicKey={recurlyPublicKey}>
              <AbilityProvider>
                <Router>
                  <Main />
                  <ToastContainer />
                </Router>
              </AbilityProvider>
            </WrappedRecurlyProvider>
          </OidcProvider>
        </Provider>
      </ReactQueryConfigProvider>
    </Sentry.ErrorBoundary>
  );
}
