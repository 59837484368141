import React, { FC } from "react";
import { Redirect, Route, RouteProps } from "react-router-dom";

import { accountOverview } from "../../config/router/routes";
import { useIsAuthenticated } from "../../services/auth";

export const PublicRoute: FC<RouteProps> = (props) => {
  const isAuthenticated = useIsAuthenticated();

  return isAuthenticated ? (
    <Redirect to={accountOverview.path} />
  ) : (
    <Route {...props} />
  );
};
