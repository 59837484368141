import { createSelector } from "reselect";
import pickBy from "lodash/pickBy";

import { IRootState } from "../reducers";
import { ISKU, IState } from "./types";
import { TableHeaderSort } from "../../components/Table/components/TableHeader/types";
import {
  containsParam,
  getActiveParam,
  getParam,
  getSortParams,
} from "../../services/api/utils";
import {
  RequestFormatter,
  TextRequestFormatter,
} from "../../services/request-formatter";
import { ISKUDetailResponse } from "../../services/api/SKU/types";
import { ISKUDetails } from "../../services/api/offering/types";

export const selectSkuMappingState = (state: IRootState): IState => {
  return state.skuMapping;
};

export const selectPage = createSelector(
  [selectSkuMappingState],
  (skuMappingState: IState): number => {
    return skuMappingState.page;
  },
);

export const selectPages = createSelector(
  [selectSkuMappingState],
  (skuMappingState: IState): number => {
    return skuMappingState.pages;
  },
);

export const selectSort = createSelector(
  [selectSkuMappingState],
  (skuMappingState: IState): TableHeaderSort => {
    return skuMappingState.sort;
  },
);

export const selectSearch = createSelector(
  [selectSkuMappingState],
  (skuMappingState: IState): string => {
    return skuMappingState.filterText.valid
      ? skuMappingState.filterText.value
      : "";
  },
);

export const selectSubscriptionProvider = createSelector(
  [selectSkuMappingState],
  (skuMappingState: IState): string => {
    return skuMappingState.spCode;
  },
);

export const selectProductCode = createSelector(
  [selectSkuMappingState],
  (skuMappingState: IState): string => {
    return skuMappingState.productCode;
  },
);

export const selectStatus = createSelector(
  [selectSkuMappingState],
  (skuMappingState: IState): string => {
    return skuMappingState.status;
  },
);

export const selectData = createSelector(
  [selectSkuMappingState],
  (skuMappingState: IState): ISKU[] => {
    return skuMappingState.data;
  },
);

export const selectPageSize = createSelector(
  [selectSkuMappingState],
  (skuMappingState: IState): number => {
    return skuMappingState.pageSize;
  },
);

export const selectTenantCode = createSelector(
  [selectSkuMappingState],
  (skuMappingState: IState): string => {
    return skuMappingState.tenantCode;
  },
);

export const selectFormattedSeatch = createSelector(
  [
    selectSearch,
    selectSubscriptionProvider,
    selectProductCode,
    selectStatus,
    selectTenantCode,
  ],
  (filterText: string, spCode, product, status, tenantCode): string => {
    return new RequestFormatter([
      new TextRequestFormatter({
        field: "sp_sku_code",
        value: containsParam(filterText),
      }),
      new TextRequestFormatter({
        field: "sp_code",
        value: getParam(spCode),
      }),
      new TextRequestFormatter({
        field: "product__code",
        value: getParam(product),
      }),
      new TextRequestFormatter({
        field: "is_active",
        value: getActiveParam(status),
      }),
      new TextRequestFormatter({
        field: "product_line__tenant_code",
        value: getParam(tenantCode),
      }),
    ]).toString();
  },
);

export const selectSKUPayload = createSelector(
  [selectSort, selectPage, selectPageSize, selectFormattedSeatch],
  (
    { field, direction }: TableHeaderSort,
    page: number,
    limit: number,
    query: string,
  ) => {
    return pickBy({
      q: encodeURIComponent(query),
      limit,
      sort: getSortParams(direction, field),
      offset: (page + 1) * limit - limit,
    });
  },
);

export const selectIsFetching = createSelector(
  [selectSkuMappingState],
  (skuMappingState: IState): boolean => {
    return skuMappingState.isFetching;
  },
);

export const selectCurrentSKUDetails = createSelector(
  [selectSkuMappingState],
  (skuMappingState: IState): ISKUDetailResponse | undefined => {
    return skuMappingState.currentSKUDetails;
  },
);

export const selectCurrentSKU = createSelector(
  [selectCurrentSKUDetails],
  (currentSKUDetails) => {
    return currentSKUDetails?.items.find((item) => {
      return !item.sp.is_addon;
    });
  },
);

export const selectCurrentSKUName = createSelector(
  [selectCurrentSKU],
  (currentSKU): string => {
    return currentSKU?.sp.name || "";
  },
);

export const selectFeatureSKUDetails = createSelector(
  [selectSkuMappingState],
  (skuMappingState: IState): ISKUDetailResponse | undefined => {
    return skuMappingState.featureSKUDetails;
  },
);

export const selectFeatureSKUDetail = createSelector(
  [selectFeatureSKUDetails],
  (featureSKUDetails): ISKUDetails | undefined => {
    return featureSKUDetails?.items.find((item) => {
      return !item.sp.is_addon;
    });
  },
);

export const selectError = createSelector(
  [selectSkuMappingState],
  (skuMappingState: IState): any => {
    return skuMappingState.error;
  },
);
