import {
  FETCH_USAGE,
  FETCH_USAGE_ERROR,
  FETCH_USAGE_SUCCESS,
} from "./constants";
import { IUsage } from "../../services/api/usage/types";
import { ActionTypes, IFetchUsageRequest } from "./types";

export const fetchUsage = (payload: IFetchUsageRequest): ActionTypes => {
  return {
    type: FETCH_USAGE,
    payload,
  };
};

export const fetchUsageSuccess = (payload: IUsage[]): ActionTypes => {
  return {
    type: FETCH_USAGE_SUCCESS,
    payload,
  };
};

export const fetchUsageError = (payload: any): ActionTypes => {
  return {
    type: FETCH_USAGE_ERROR,
    payload,
  };
};
