import { Route } from "react-router-dom";

import { IRouteConfig, RouteVisibility } from "../../config/router/types";
import PrivateRoute from "../../components/PrivateRoute";
import PublicRoute from "../../components/PublicRoute";

export function getRoutePath(route: IRouteConfig) {
  return route.path;
}

export function hasLayout(layout: IRouteConfig["layout"]) {
  return (route: IRouteConfig) => {
    return route.layout === layout;
  };
}

export function getLayouts(routes: IRouteConfig[]) {
  return Array.from(
    new Set(
      routes.map((route) => {
        return route.layout;
      }),
    ),
  );
}

export function getRouteComponent(route: IRouteConfig) {
  switch (route.visibility) {
    case RouteVisibility.Private: {
      return PrivateRoute;
    }
    case RouteVisibility.Public: {
      return PublicRoute;
    }
    case RouteVisibility.All:
    default: {
      return Route;
    }
  }
}

export function getRoutes(routerList: IRouteConfig[]): IRouteConfig[] {
  const routes: IRouteConfig[] = [];
  routerList.forEach((route) => {
    routes.push(route);

    if (route.routes) {
      getRoutes(route.routes).forEach((currentRoute) => {
        routes.push(currentRoute);
      });
    }
  });
  return routes;
}
