import { IState } from "./types";

export const FETCH_OPTIONS_TRIGGER = "account/fetchOptionsTrigger";
export const FETCH_OPTIONS_REQUEST = "account/fetchOptionsRequest";
export const FETCH_OPTIONS_SUCCESS = "account/fetchOptionsSuccess";
export const FETCH_OPTIONS_FAILURE = "account/fetchOptionsFailure";

export const UPDATE_OPTIONS_TRIGGER = "account/updateOptionsTrigger";
export const UPDATE_OPTIONS_REQUEST = "account/updateOptionsRequest";
export const UPDATE_OPTIONS_SUCCESS = "account/updateOptionsSuccess";
export const UPDATE_OPTIONS_FAILURE = "account/updateOptionsFailure";

export const initialState: IState = {
  options: {
    isFetching: false,
    data: undefined,
    error: undefined,
  },
};
