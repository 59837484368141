import {
  all,
  call,
  debounce,
  fork,
  put,
  select,
  takeEvery,
} from "redux-saga/effects";

import { selectPrincipalPayload } from "./selectors";
import {
  fetchDataFailure,
  fetchDataRequest,
  fetchDataSuccess,
} from "./actions";
import principalAPIService from "../../services/api/principal";
import {
  FETCH_TRIGGER,
  FILTER_RESET,
  FILTER_TEXT_UPDATE,
  FILTER_UPDATE,
  PAGINATION_UPDATE,
  SORT_UPDATE,
} from "./constants";

export function* getSearchResults() {
  try {
    yield put(fetchDataRequest());

    const params = yield select(selectPrincipalPayload);
    const data = yield call(
      [principalAPIService, principalAPIService.getAll],
      params,
    );
    const pages = Math.ceil(data.paging.total_items / params.limit);

    yield put(fetchDataSuccess({ data: data.items, pages }));
  } catch (error) {
    yield put(fetchDataFailure(error));
  }
}

export function* watchGetSearchResults() {
  yield takeEvery(
    [
      FILTER_UPDATE,
      FILTER_RESET,
      PAGINATION_UPDATE,
      SORT_UPDATE,
      FETCH_TRIGGER,
    ],
    getSearchResults,
  );
}

export function* debounceSearch() {
  yield debounce(
    500,
    (action: any) => {
      return action.type === FILTER_TEXT_UPDATE && action.payload.valid;
    },
    getSearchResults,
  );
}

export function* principalSaga() {
  yield all([fork(watchGetSearchResults), fork(debounceSearch)]);
}
