import { createSelector } from "reselect";

import { IRootState } from "../reducers";
import { IBillingHistoryState } from "./types";
import { Invoice } from "../../types/common";
import { RECURLY } from "../../constants/spCode";

export const selectBillingHistoryState = (
  state: IRootState,
): IBillingHistoryState => {
  return state.billingHistory;
};

export const selectInvoices = createSelector(
  [selectBillingHistoryState],
  (billingHistoryState: IBillingHistoryState): Invoice[] => {
    const sortedInvoices = billingHistoryState?.data.sort((a, b) => {
      return Date.parse(b.created_at) - Date.parse(a.created_at);
    });

    return sortedInvoices
      .filter((invoice) => {
        return invoice.sp_code === RECURLY;
      })
      .slice(0, 12); // as designed most recent 12 invoices should be displayed
  },
);

export const selectIsFetchingInvoices = createSelector(
  [selectBillingHistoryState],
  (billingHistoryState: IBillingHistoryState): boolean => {
    return billingHistoryState?.isFetchingInvoices;
  },
);
