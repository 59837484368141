import { createSelector } from "reselect";

import { IRootState } from "../reducers";
import { IState } from "./types";
import { Tenant } from "../../services/api/tenant/types";
import { IProductLine } from "../../services/api/productLine/types";
import { IProduct } from "../../services/api/product/types";
import { selectCustomerData } from "../user/selectors";
import { ICaptchas, ISPS } from "../../services/api/brand/types";

const selectMetadataState = (state: IRootState): IState => {
  return state.metadata;
};

export const selectTenantCode = createSelector(
  [selectMetadataState],
  (metadata: IState): string => {
    return metadata.tenantCode;
  },
);

export const selectIsFetching = createSelector(
  [selectMetadataState],
  (metadata: IState): boolean => {
    return metadata.isFetching;
  },
);

export const selectTenants = createSelector(
  [selectMetadataState],
  (metadata: IState): Tenant[] => {
    return metadata.tenants;
  },
);

export const selectError = createSelector(
  [selectMetadataState],
  (metadata: IState): any => {
    return metadata.error;
  },
);

export const selectProducts = createSelector(
  [selectMetadataState],
  (metadata: IState): IProduct[] => {
    return metadata.products;
  },
);

export const selectProductLines = createSelector(
  [selectMetadataState],
  (metadata: IState): IProductLine[] => {
    return metadata.productLines;
  },
);

export const selectAvailableProductLines = createSelector(
  [selectProductLines, selectCustomerData],
  (productLines: IProductLine[], customer): IProductLine[] => {
    // eslint-disable-next-line @typescript-eslint/camelcase
    return productLines.filter(({ is_corporate, tenant_code }) => {
      return (
        // eslint-disable-next-line @typescript-eslint/camelcase
        is_corporate === customer?.is_corporate &&
        // eslint-disable-next-line @typescript-eslint/camelcase
        tenant_code === customer.tenant_code
      );
    });
  },
);

export const selectCurrentProductLine = createSelector(
  [selectMetadataState],
  (metadata: IState): IProductLine | undefined => {
    return metadata.currentProductLine;
  },
);

export const selectDefaultProductLine = createSelector(
  [selectAvailableProductLines],
  (productLines: IProductLine[]): IProductLine => {
    // We support only subscription with one product line
    return productLines[0];
  },
);

export const selectBrandCode = createSelector(
  [selectMetadataState],
  (metadata: IState) => {
    return metadata.brandCode;
  },
);

export const selectBrandCodeName = createSelector(
  [selectMetadataState],
  (metadata: IState) => {
    return metadata.brandCodeName;
  },
);

export const selectOriginSettings = createSelector(
  [selectMetadataState],
  (metadata: IState) => {
    return metadata?.settings?.user_context;
  },
);

export const selectCaptchasList = createSelector(
  [selectMetadataState],
  (metadata: IState) => {
    return metadata?.CaptchasList ?? [];
  },
);

export const selectRecapchaV2Captcha = createSelector(
  [selectCaptchasList],
  (CaptchasList: ICaptchas[]) => {
    return CaptchasList.filter((el) => {
      return el.kind === "RECAPTCHA_V2";
    });
  },
);

export const selectSPSList = createSelector(
  [selectMetadataState],
  (metadata: IState) => {
    return metadata.SPSList;
  },
);

export const selectCurrentSPS = createSelector(
  [selectSPSList],
  (SPSList: ISPS[]) => {
    return SPSList[0];
  },
);
