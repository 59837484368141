import React, { FC } from "react";

import { ContentLoaderProps } from "./types";
import { CommonContentLoader } from "./CommonContentLoader/CommonContentLoader";
import { ContentLoaderSubscription } from "./ContentLoaderSubscription/ContentLoaderSubscription";

export const ContentLoaderContainer: FC<ContentLoaderProps> = ({
  isSubscriptionLoader,
}) => {
  return (
    <div className="container-loader">
      {isSubscriptionLoader ? (
        <ContentLoaderSubscription />
      ) : (
        <CommonContentLoader />
      )}
    </div>
  );
};
