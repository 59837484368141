import { Offering } from "../../services/api/offering/types";
import { IProductLine } from "../../services/api/productLine/types";
import { IAddOfferingDefaultProp } from "./types";

export const addOfferingDefaultProp: IAddOfferingDefaultProp = (
  offerings: Offering[],
  productLines: IProductLine[] | [] = [],
): Offering[] => {
  return offerings.map((offering: Offering) => {
    const isDefault = productLines.some((productLine) => {
      return productLine.default_offering_code === offering.code;
    });
    return { ...offering, isDefault };
  });
};
