import React, { FC, useEffect, useState } from "react";
import classnames from "classnames";

import { AmazonCommonResponse, IPayWithAmazonProps } from "./types";
import { payWithAmazonConfig } from "./config";

import "./styles.scss";

declare global {
  // eslint-disable-next-line @typescript-eslint/interface-name-prefix
  interface Window {
    PayWithAmazon: any;
  }
}

export const PayWithAmazon: FC<IPayWithAmazonProps> = ({
  sellerId,
  clientId,
  isProduction,
  onSuccess,
  orError,
}) => {
  const [isButtonDisplay, setIsButtonDisplay] = useState(true);
  const classes = classnames({
    "pay-with-amazon__hide": !isButtonDisplay,
  });

  useEffect(() => {
    const payWithAmazon = new window.PayWithAmazon({
      ...payWithAmazonConfig,
      sellerId,
      clientId,
      production: isProduction,
    });

    payWithAmazon.on("change", (data: AmazonCommonResponse) => {
      if (data.consent) {
        onSuccess(data);
      } else {
        orError && orError(data);
      }
    });

    payWithAmazon.on("login", () => {
      setIsButtonDisplay(false);
    });
  }, [sellerId, clientId, isProduction, onSuccess, orError]);

  return (
    <>
      <div id="pay-with-amazon" className={classes}></div>
      <div id="wallet"></div>
      <div id="consent"></div>
    </>
  );
};
