import { useRecurly } from "@recurly/react-recurly";

import { ISKUDetails } from "../../services/api/offering/types";
import { NOW_TIMEFRAME } from "../../services/api/subscription/constants";
import { ISubscriptionPreview } from "../../services/api/subscription/types";

export const generateAddons = (
  featureLicenses: number,
  licensesLicenses: number,
  currentSKUAddon: ISKUDetails,
) => {
  return featureLicenses - licensesLicenses > 0
    ? [
        {
          // eslint-disable-next-line @typescript-eslint/camelcase
          sku_id: currentSKUAddon.sku_id,
          quantity: featureLicenses - licensesLicenses,
        },
      ]
    : null;
};

export function useBMRecurly() {
  try {
    return useRecurly();
  } catch (e) {
    return null;
  }
}

export const isTimeframeNow = (preview: ISubscriptionPreview) => {
  return preview.timeframe === NOW_TIMEFRAME;
};

export const calcCouponDiscount = ({
  details,
  estimate,
}: ISubscriptionPreview): number => {
  return details.discount?.amount || details.subtotal.amount - estimate.amount;
};
