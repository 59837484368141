import React, { FC } from "react";
import classnames from "classnames";

import { OverlayProps } from "./types";

import "./styles.scss";

export const Overlay: FC<OverlayProps> = ({ className, children }) => {
  return <div className={classnames("ams-overlay", className)}>{children}</div>;
};
