import { AxiosError } from "axios";
import { ActionTypes, IBillingDataPayload, IUser } from "./types";
import {
  CLEAR_CUTOMER_DATA,
  CLEAR_PRINCIPAL_DATA,
  FETCHING_CURRENT_CUSTOMER,
  FETCHING_CURRENT_PRINCIPAL,
  FETCH_BILLING_ACCOUNT_LIST,
  FETCH_BILLING_ACCOUNT_LIST_ERROR,
  FETCH_BILLING_ACCOUNT_LIST_SUCCESS,
  FETCH_BILLING_DATA,
  FETCH_BILLING_DATA_ERROR,
  FETCH_BILLING_DATA_SUCCESS,
  FETCH_CURRENT_CUSTOMER,
  FETCH_CURRENT_CUSTOMER_ERROR,
  FETCH_CURRENT_CUSTOMER_SUCCESS,
  FETCH_CURRENT_PRINCIPAL,
  FETCH_CURRENT_PRINCIPAL_ERROR,
  FETCH_CURRENT_PRINCIPAL_SUCCESS,
  REMOVE_BILLING_DATA,
  REMOVE_BILLING_DATA_ERROR,
  REMOVE_BILLING_DATA_SUCCESS,
  SET_PAYMENT_COUNTRY,
  SET_PAYMENT_POSTAL_CODE,
  SET_PAYMENT_REGION,
  USER_FETCH_SUCCESS,
} from "./constants";
import {
  IBillingAccount,
  IBillingInfo,
  ICustomer,
  IUserResponseData,
} from "../../services/api/user/types";

export const userLoadSuccess = (user: IUser): ActionTypes => {
  return {
    type: USER_FETCH_SUCCESS,
    payload: user,
  };
};

export const fetchCurrentPrincipal = (principalId: string): ActionTypes => {
  return {
    type: FETCH_CURRENT_PRINCIPAL,
    payload: principalId,
  };
};

export const fetchCurrentPrincipalSuccess = (
  user: IUserResponseData,
): ActionTypes => {
  return {
    type: FETCH_CURRENT_PRINCIPAL_SUCCESS,
    payload: user,
  };
};

export const fetchCurrentPrincipalError = (error: any): ActionTypes => {
  return {
    type: FETCH_CURRENT_PRINCIPAL_ERROR,
    payload: error,
  };
};

export const fetchCurrentCustomer = (customerId: string): ActionTypes => {
  return {
    type: FETCH_CURRENT_CUSTOMER,
    payload: customerId,
  };
};

export const fetchCurrentCustomerSuccess = (
  customer: ICustomer,
): ActionTypes => {
  return {
    type: FETCH_CURRENT_CUSTOMER_SUCCESS,
    payload: customer,
  };
};

export const fetchCurrentCustomerError = (error: any): ActionTypes => {
  return {
    type: FETCH_CURRENT_CUSTOMER_ERROR,
    payload: error,
  };
};

export const fetchingCurrentPrincipal = (): ActionTypes => {
  return {
    type: FETCHING_CURRENT_PRINCIPAL,
  };
};

export const fetchingCurrentCustomer = (): ActionTypes => {
  return {
    type: FETCHING_CURRENT_CUSTOMER,
  };
};

export const clearCustomerData = (): ActionTypes => {
  return {
    type: CLEAR_CUTOMER_DATA,
  };
};

export const clearPrincipalData = (): ActionTypes => {
  return {
    type: CLEAR_PRINCIPAL_DATA,
  };
};

export const fetchBillingAccountList = (customerId: string): ActionTypes => {
  return {
    type: FETCH_BILLING_ACCOUNT_LIST,
    payload: customerId,
  };
};

export const fetchBillingAccountListSuccess = (
  billingAccountList: IBillingAccount[],
): ActionTypes => {
  return {
    type: FETCH_BILLING_ACCOUNT_LIST_SUCCESS,
    payload: billingAccountList,
  };
};

export const fetchBillingAccountListError = (error: any): ActionTypes => {
  return {
    type: FETCH_BILLING_ACCOUNT_LIST_ERROR,
    payload: error,
  };
};

export const fetchBillingData = (data: IBillingDataPayload): ActionTypes => {
  return {
    type: FETCH_BILLING_DATA,
    payload: data,
  };
};

export const fetchBillingDataSuccess = (
  billingInfo: IBillingInfo,
): ActionTypes => {
  return {
    type: FETCH_BILLING_DATA_SUCCESS,
    payload: billingInfo,
  };
};

export const fetchBillingDataError = (error?: any): ActionTypes => {
  return {
    type: FETCH_BILLING_DATA_ERROR,
    payload: error,
  };
};

export const removeBillingData = (
  payload: IBillingDataPayload,
  onSuccess: () => void,
  onError: (error: AxiosError) => void,
  onFinally: () => void,
): ActionTypes => {
  return {
    type: REMOVE_BILLING_DATA,
    payload,
    onSuccess,
    onError,
    onFinally,
  };
};

export const removeBillingDataSuccess = (): ActionTypes => {
  return {
    type: REMOVE_BILLING_DATA_SUCCESS,
  };
};

export const removeBillingDataError = (error?: any): ActionTypes => {
  return {
    type: REMOVE_BILLING_DATA_ERROR,
    payload: error,
  };
};

export const setPaymentCountry = (payload: string): ActionTypes => {
  return {
    type: SET_PAYMENT_COUNTRY,
    payload,
  };
};

export const setPaymentPostalCode = (payload: string): ActionTypes => {
  return {
    type: SET_PAYMENT_POSTAL_CODE,
    payload,
  };
};

export const setPaymentRegion = (payload: string): ActionTypes => {
  return {
    type: SET_PAYMENT_REGION,
    payload,
  };
};
