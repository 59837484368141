import React, { FC } from "react";

import Banner from "../../../components/Banner";
import { PaymentIssueMessage } from "../../../components/PaymentIssueMessage/PaymentIssueMessage";

export const PaymentBanner: FC = () => {
  return (
    <Banner type="error">
      <PaymentIssueMessage />
    </Banner>
  );
};
