import { createSelector } from "reselect";
import pickBy from "lodash/pickBy";

import { IRootState } from "../reducers";
import { IDateRange, IPrincipalCommonData, IState } from "./types";
import { TableHeaderSort } from "../../components/Table/components/TableHeader/types";
import { getDateRange } from "./utils";
import {
  GroupFormatter,
  RequestFormatter,
  TextRequestFormatter,
} from "../../services/request-formatter";
import {
  containsParam,
  getDateParam,
  getParam,
  getSortParams,
} from "../../services/api/utils";
import { IconName } from "../../components/Icon/types";

export const selectPrincipalState = (state: IRootState): IState => {
  return state.principal;
};

export const selectSearch = createSelector(
  [selectPrincipalState],
  (principal: IState): string => {
    return principal.filterText.valid ? principal.filterText.value : "";
  },
);

export const selectTenantCode = createSelector(
  [selectPrincipalState],
  (principalState: IState): string => {
    return principalState.tenantCode;
  },
);

export const selectDateRanges = createSelector(
  [selectPrincipalState],
  (principalState: IState): IDateRange[] => {
    return principalState.dateRanges;
  },
);

export const selectPageSize = createSelector(
  [selectPrincipalState],
  (principalState: IState): number => {
    return principalState.pageSize;
  },
);

export const selectFilterDateRange = createSelector(
  [selectPrincipalState],
  (principalState: IState): string => {
    return principalState.filterDateRange.type;
  },
);

export const selectDateRange = createSelector(
  [selectPrincipalState],
  (principalState: IState): any => {
    return getDateRange(principalState.filterDateRange);
  },
);

export const selectPage = createSelector(
  [selectPrincipalState],
  (principalState: IState): number => {
    return principalState.page;
  },
);

export const selectPages = createSelector(
  [selectPrincipalState],
  (principalState: IState): number => {
    return principalState.pages;
  },
);

export const selectSort = createSelector(
  [selectPrincipalState],
  (principalState: IState): TableHeaderSort => {
    return principalState.sort;
  },
);

export const selectData = createSelector(
  [selectPrincipalState],
  (principalState: IState): IPrincipalCommonData[] => {
    return principalState.data.map((item) => {
      return {
        ...item,
        tenantIcon: ("tenant-" + item.tenant_code) as IconName,
        tenantName: item.tenant_code,
      };
    });
  },
);

export const selectIsFetching = createSelector(
  [selectPrincipalState],
  (principalState: IState): boolean => {
    return principalState.isFetching;
  },
);

export const selectError = createSelector(
  [selectPrincipalState],
  (principalState: IState): any => {
    return principalState.error;
  },
);

export const selectFormattedSearch = createSelector(
  [selectSearch, selectTenantCode, selectDateRange],
  (filterText: string, tenantCode, dateRange): string => {
    return new RequestFormatter([
      new GroupFormatter(
        [
          new TextRequestFormatter({
            field: "username",
            value: containsParam(filterText),
          }),
          new TextRequestFormatter({
            field: "name",
            value: containsParam(filterText),
          }),
          new TextRequestFormatter({
            field: "email",
            value: containsParam(filterText),
          }),
        ],
        "OR",
      ),
      new TextRequestFormatter({
        field: "tenant_code",
        value: getParam(tenantCode),
      }),
      new TextRequestFormatter({
        field: "created_at",
        value: getDateParam(dateRange.startDate, ">"),
      }),
      new TextRequestFormatter({
        field: "created_at",
        value: getDateParam(dateRange.endDate, "<"),
      }),
    ]).toString();
  },
);

export const selectPrincipalPayload = createSelector(
  [selectSort, selectPageSize, selectPage, selectFormattedSearch],
  (
    { direction, field }: TableHeaderSort,
    limit: number,
    page: number,
    query: string,
  ) => {
    return pickBy({
      q: encodeURIComponent(query),
      sort: getSortParams(direction, field),
      limit,
      offset: (page + 1) * limit - limit,
    });
  },
);
