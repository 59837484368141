import React, { ChangeEvent, FC, FormEvent, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import FormGroup from "../../../../components/FormGroup";
import Label from "../../../../components/Label";
import Select from "../../../../components/Select";
import AddressFormContent from "../../../../components/AddressFormContent";
import PaymentFormContent from "../../../../components/PaymentFormContent";
import PayWithAmazon from "../../../../components/PayWithAmazon";
import { AmazonResponse } from "../../../../components/PayWithAmazon/types";
import { ACHForm } from "../ACHForm/ACHForm";
import { SEPAForm } from "../SEPAForm/SEPAForm";
import { selectCurrentSPS } from "../../../../store/metadata";
import {
  selectCurrentSubscriptionDetail,
  selectSubscriptionWithGeoCurrency,
} from "../../../../store/subscriptions";
import { CURRENCY_DATA } from "../../../../services/currency/constants";
import { getSubscriptionStatus } from "../../../../utils/common";
import { getDefaultMethod, getPayload } from "../AddPaymentMethodModal/utils";
import { PaymentMethodTypes } from "../AddPaymentMethodModal/types";
import {
  ACH_BANK_DRAFT,
  AMAZON,
  CREDIT_CARD,
  PAYMENT_TYPE_LIST,
  PAYPAL,
  SEPA_BANK_DRAFT,
} from "../AddPaymentMethodModal/contants";
import { IPaymentInformationForm } from "./types";
import { SUBSCRIPTION_STATUS } from "../../../../utils/common/contants";
import {
  setPaymentCountry,
  setPaymentPostalCode,
  setPaymentRegion,
} from "../../../../store/user";

export const PaymentInformationForm: FC<IPaymentInformationForm> = ({
  children,
  onSubmit,
  billingInfo,
  onChangePaymentType,
  show3DSecure,
  setFormPaymentMethod,
  setIsFormValid,
  isWinbackPaymentEdit,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const defaultMethod = useMemo(() => {
    if (isWinbackPaymentEdit) {
      return undefined;
    }

    return billingInfo ? getDefaultMethod(billingInfo) : undefined;
  }, [isWinbackPaymentEdit, billingInfo]);
  const [paymentMethod, setPaymentMethod] = useState<
    PaymentMethodTypes | undefined
  >(defaultMethod);
  const [amazonData, setAmazonData] = useState<AmazonResponse>({
    id: "",
    consent: false,
  });
  const sps = useSelector(selectCurrentSPS);
  const currentSubscription = useSelector(selectCurrentSubscriptionDetail);
  const subscriptionStatus = useMemo(() => {
    return currentSubscription && getSubscriptionStatus(currentSubscription);
  }, [currentSubscription]);
  const subscriptionListWithGeoCurrency = useSelector(
    selectSubscriptionWithGeoCurrency,
  );

  const currency = useMemo(() => {
    if (
      subscriptionStatus !== SUBSCRIPTION_STATUS.expired &&
      currentSubscription?.sp?.next_invoice_amount?.currency
    ) {
      return currentSubscription.sp.next_invoice_amount.currency;
    }

    if (subscriptionListWithGeoCurrency?.length > 0) {
      return subscriptionListWithGeoCurrency[0].geoCost?.currency ?? "USD";
    }

    return CURRENCY_DATA.USD.currency;
  }, [
    currentSubscription,
    subscriptionStatus,
    subscriptionListWithGeoCurrency,
  ]);

  const availablePaymentMethodList = useMemo(() => {
    return PAYMENT_TYPE_LIST.filter((item) => {
      return item.isAvalible(currency);
    });
  }, [currency]);
  const onValidationChange = (isValid: boolean) => {
    setIsFormValid(isValid);
  };

  const onPaymentTypeChanged = (event: ChangeEvent<HTMLSelectElement>) => {
    const { value } = event.target;

    setPaymentMethod(value as PaymentMethodTypes);

    if (onChangePaymentType) {
      onChangePaymentType(value);
      setFormPaymentMethod(value);
    }

    if (value === CREDIT_CARD) {
      setIsFormValid(false);
      return;
    }

    if (value === PAYPAL) {
      setIsFormValid(true);
      return;
    }

    if (value === AMAZON) {
      setIsFormValid(false);
      return;
    }

    if (value === ACH_BANK_DRAFT) {
      setIsFormValid(false);
    }
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (paymentMethod) {
      onSubmit(getPayload(paymentMethod, { event, amazonData }));
    }
  };

  const handleAmazonSuccess = (amazonResponse: AmazonResponse) => {
    setIsFormValid(true);
    setAmazonData(amazonResponse);
  };

  const onCountryChanged = (event: ChangeEvent<HTMLSelectElement>) => {
    dispatch(setPaymentCountry(event.target.value));
  };

  const onPostalCodeChanged = (event: ChangeEvent<HTMLInputElement>) => {
    dispatch(setPaymentPostalCode(event.target.value));
  };

  const onRegionChanged = (event: ChangeEvent<HTMLSelectElement>) => {
    dispatch(setPaymentRegion(event.target.value));
  };

  return (
    <form onSubmit={handleSubmit}>
      <FormGroup>
        <Label>{t("paymentType")}</Label>
        <Select
          id="payment-method"
          value={paymentMethod}
          onChange={onPaymentTypeChanged}
        >
          <option value="">{t("choosePaymentType")}</option>
          {availablePaymentMethodList.map((type) => {
            return (
              <option key={type.value} value={type.value}>
                {t(type.label)}
              </option>
            );
          })}
        </Select>
      </FormGroup>

      {paymentMethod === CREDIT_CARD ? (
        <PaymentFormContent
          billingInfo={billingInfo}
          onValidationChange={onValidationChange}
        />
      ) : null}
      {paymentMethod === AMAZON && (
        <PayWithAmazon
          sellerId={sps.amazon_merchant_id}
          clientId={sps.amazon_client_id}
          isProduction={sps.amazon_production}
          onSuccess={handleAmazonSuccess}
        />
      )}

      {paymentMethod === ACH_BANK_DRAFT ? (
        <ACHForm
          billingInfo={billingInfo}
          onValidationChange={onValidationChange}
        />
      ) : null}

      {paymentMethod === SEPA_BANK_DRAFT ? (
        <SEPAForm
          billingInfo={billingInfo}
          onValidationChange={onValidationChange}
        />
      ) : null}

      {paymentMethod === ACH_BANK_DRAFT ? null : (
        <AddressFormContent
          onValidationChange={onValidationChange}
          onCountryChangeCallback={onCountryChanged}
          onPostalCodeChangeCallback={onPostalCodeChanged}
          onRegionChangeCallback={onRegionChanged}
        />
      )}

      {show3DSecure ? <div id="ams-3d-secure" /> : null}

      {children}
    </form>
  );
};
