import { createSelector } from "reselect";

import { IRootState } from "../reducers";
import { IState } from "./types";
import { ICustomerSKUDetails } from "../../services/api/offering/types";

const selectCorporateBuyState = (state: IRootState): IState => {
  return state.corporateBuy;
};

export const selectPlans = createSelector(
  [selectCorporateBuyState],
  (subscriptionsState: IState): ICustomerSKUDetails[] => {
    return subscriptionsState.plans;
  },
);

export const selectIsFetching = createSelector(
  [selectCorporateBuyState],
  (subscriptionsState: IState): boolean => {
    return subscriptionsState.isFetching;
  },
);

export const selectError = createSelector(
  [selectCorporateBuyState],
  (subscriptionsState: IState): any => {
    return subscriptionsState.error;
  },
);
