import React, { FC } from "react";
import classNames from "classnames";

import { LogoProps } from "./types";

import "./styles.scss";

export const Logo: FC<LogoProps> = ({ className, ...props }) => {
  return <i className={classNames("logo", className)} {...props} />;
};
