import { Direction } from "../../components/Table/components/TableHeader/types";

export const SEPA_BANK_DRAFT_METHOD = "SEPA";
export const ACH_BANK_DRAFT_METHOD = "ACH";
export const BANK = "BANK";
export const PAYPAL_AMAZON_WALLET = "WALLET";
export const ACCOUNT_TYPE_CHECKING = "checking";
export const ACCOUNT_TYPE_SAVING = "savings";

export const DEFAULT_SORT = {
  direction: "desc" as Direction,
  field: "created_at",
};

export enum ProblemTypes {
  IfMatchHeaderIsRequiredButGotIfMatchException = "IfMatchHeaderIsRequiredButGotIfMatchException",
  EtagNotMatchException = "EtagNotMatchException",
}

export const WINBACK_MODAL_STATE = "closed";
