import { ComponentType } from "react";
import { LoadableComponent } from "@loadable/component";
import { RouteComponentProps } from "react-router-dom";

import { Ability } from "../../models/permissions";
import { ICustomer } from "../../services/api/user/types";
import { BrandCode } from "../../models/brand";
import { ISubscription } from "../../services/api/subscription/types";

export enum RouteVisibility {
  All,
  Private,
  Public,
}

interface IRedirectData {
  ability: Ability;
  customer?: ICustomer;
  brandCode?: BrandCode | undefined;
  currentSubscription?: ISubscription;
}

export interface IRouteConfig {
  path: string;
  exact?: boolean;
  visibility: RouteVisibility;
  layout: ComponentType;
  component: ComponentType<RouteComponentProps> | LoadableComponent<any>;
  isRedirectAllowed: (data: IRedirectData) => boolean;
  routes?: IRouteConfig[];
}
