import { API } from "../api";
import { ISKUDetailResponse, ISKUPayload, ISKUResponse } from "./types";
import { ISKU } from "../../../store/skuMapping";
import { url as URL } from "../constants";
import { validateArgs } from "../utils";

export class SKUAPIService {
  constructor(private api: API) {}

  public async getAll(params: any): Promise<ISKUResponse> {
    const { data: skuData } = await this.api.get<ISKUResponse>(URL.SKU.base, {
      params,
    });
    return skuData;
  }

  public async create(data: ISKUPayload): Promise<ISKU> {
    const { data: sku } = await this.api.post<ISKU>(URL.SKU.base, data);
    return sku;
  }

  public async update(id: string, data: Partial<ISKUPayload>): Promise<ISKU> {
    validateArgs(id);
    const { data: sku } = await this.api.patch<ISKU>(
      `${URL.SKU.base}/${id}`,
      data,
    );
    return sku;
  }

  public async get(skuId: string): Promise<ISKUResponse> {
    validateArgs(skuId);
    const { data: skuData } = await this.api.get<ISKUResponse>(
      URL.SKU.get.replace(":skuId", skuId),
    );
    return skuData;
  }

  public async getDetails(skuId: string) {
    validateArgs(skuId);
    const { data: skuData } = await this.api.get<ISKUDetailResponse>(
      URL.SKU.details.replace(":skuId", skuId),
    );
    return skuData;
  }
}
