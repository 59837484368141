import { SPCode } from "../../../types/common";
import { NOW_TIMEFRAME, RENEWAL_TIMEFRAME } from "./constants";
import { BillingToken, ICaptchaToken, IPromotionCode } from "../user/types";

export interface ISubscriptionUpcoming {
  sku_id: string;
  applies_at: string;
  addons: ISubscriptionAddon[];
  sku: ISubscriptionEmbeddedSku;
}

export interface ISpSubscriptionUpcoming {
  sp_sku_code: string;
  sp_sku_name: string;
  applies_at: string;
}

export interface ISpSubscriptionBasic {
  sp_code: SPCode;
  sp_customer_id: string;
  sp_subscription_id: string;
  is_active: boolean;
}

interface ISubscriptionPromotionDetail {
  kind: string;
  duration: string;
}

export interface IPirCentDetail extends ISubscriptionPromotionDetail {
  percent: number;
}

export interface IFixedDetail extends ISubscriptionPromotionDetail {
  amount: IMoney;
}

export interface IFreeDetail extends ISubscriptionPromotionDetail {
  length: string;
  is_trial_expired: boolean;
}

export interface ISpSubscriptionPromotion {
  code: string;
  detail: IFreeDetail | IPirCentDetail | IFixedDetail;
  is_active: boolean;
}

export interface ISpSubscription extends ISpSubscriptionBasic {
  billing_period: string;
  next_invoice_amount: IMoney | null;
  upcoming: ISpSubscriptionUpcoming | null;
  promotion?: ISpSubscriptionPromotion;
  canceled_at?: string;
  terminated_at?: string;
  invoiced_at: string;
  next_invoices_at: string;
  created_at: string;
  updated_at: string;
  addons?: any[];
  states: ISpSubscriptionStates[];
  paused_at?: string;
  will_resume_at?: string;
}

export enum ISpSubscriptionStates {
  canceled = "CANCELED",
  changing = "CHANGING",
  future = "FUTURE",
  intro = "INTRO",
  pastDue = "PAST_DUE",
  paused = "PAUSED",
  pending = "PENDING",
  refunded = "REFUNDED",
  terminated = "TERMINATED",
  trial = "TRIAL",
}

export interface ISubscriptionDetails {
  customer_id: string;
  subscription_id: string;
  sp: ISpSubscription;
  upcoming?: ISubscriptionUpcoming | null;
  addons?: any[];
}

export interface ISubscriptionAddon {
  subscription_id: string;
  sku_id: string;
  created_at: string;
  updated_at: string;
  quantity: number;
  sku: ISubscriptionEmbeddedSku;
}

export interface IProductEntitlement {
  product_code: string;
  feature_code: string;
  service_code: string;
  value: number;
}

export interface IProduct {
  code: string;
  entitlements: IProductEntitlement[];
  created_at: string;
  updated_at: string;
}

export interface ISubscriptionEmbeddedSku {
  licenses: number;
  product: IProduct;
  product_line_code: string;
}

export interface ISubscription {
  id: string;
  customer_id: string;
  principal_id?: string;
  is_terminated: boolean;
  is_good_standing: boolean;
  is_active: boolean;
  offering_code: string;
  sku_id: string;
  sp_code: SPCode;
  sp_subscription_id: string;
  will_change_at?: string;
  will_expire_at?: string;
  created_at: string;
  updated_at: string;
  sku?: ISubscriptionEmbeddedSku;
  addons?: ISubscriptionAddon[];
  product_line_code?: string;
}

export interface ISubscriptionsResponse {
  items: ISubscription[];
}

export interface IMoney {
  amount: number;
  currency: string;
}

export interface IMoneyTax extends IMoney {
  tax: number;
}

export interface ICreateSubscriptionRequest {
  sku_id: string;
  offering_code?: string;
  currency?: string;
  billing_token?: BillingToken;
  promotion_token?: IPromotionCode;
  captcha_token?: ICaptchaToken;
}

export interface ISpAddon {
  sku_id: string;
  quantity: number;
}

export interface ISubscriptionRequest {
  customerId: string;
  subscriptionId: string;
}

export interface IUpdateSubscriptionRequest {
  sku_id: string;
  addons?: ISpAddon[] | null;
  promotion_token?: IPromotionCode;
}

export interface IUpdateSubscriptionResponse {
  timeframe: string;
}

export interface ICancelSubscriptionRequest {
  customerId: string;
  subscriptionId: string;
  cancelReason: string;
}

export interface ICancelSubscriptionResponse {
  timeframe: typeof NOW_TIMEFRAME | typeof RENEWAL_TIMEFRAME;
  applies_at?: string;
}

interface IOptionSchema {
  description: string;
  type: string;
  properties: any;
  required: string[];
}

export interface ISubscriptionOptions {
  options: { auto_recycle: true };
  created_at: string;
  updated_at: string;
  schema: IOptionSchema;
  eTag: string;
}

export interface ISubscriptionPreviewDetails {
  credit_applied: IMoney;
  subtotal: IMoney;
  paid: IMoney;
  prorated_credit: IMoney;
  discount: IDiscount;
}

export interface ISubscriptionPreview {
  estimate: IMoneyTax;
  is_credit?: boolean;
  timeframe?: typeof NOW_TIMEFRAME | typeof RENEWAL_TIMEFRAME;
  details: ISubscriptionPreviewDetails;
}

export interface IDiscount {
  amount: number;
  currency: string;
}

export const THIRD_PARTY_OFFERING: Array<string> = ["VYPRVPN_SEALAND_2024Q3"];
