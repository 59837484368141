import { ISidebarNavItem } from "../../components/Navigation/types";
import routes from "../../config/router";

export const getNavItems: () => Array<ISidebarNavItem> = () => {
  return [
    {
      link: "overview",
      label: "account_overview",
      icon: "overview",
      route: routes.accountOverview,
    },
    {
      link: "profile",
      label: "profile",
      icon: "profile",
      route: routes.profile,
    },
    {
      link: "payment-information",
      label: "paymentInformation",
      icon: "payment",
      route: routes.paymentInformation,
    },
    {
      link: "subscriptions",
      label: "subscriptions",
      icon: "subscriptions",
      route: routes.subscriptions,
    },
    {
      link: "principal-database",
      label: "principalDatabase",
      icon: "profile",
      route: routes.principalDatabase,
    },
    {
      link: "sku-mapping",
      label: "sku_mapping",
      icon: "sku-mapping",
      route: routes.skuMapping,
    },
    {
      link: "offering",
      label: "offerings",
      icon: "subscriptions",
      route: routes.offering,
    },
    {
      link: "manage-users",
      label: "manageUsers",
      icon: "manage-users",
      route: routes.manageUsers,
    },
    {
      link: "manage-services",
      label: "manageServicesTab",
      icon: "manage-services",
      route: routes.manageServices,
    },
    {
      link: routes.services.path,
      label: "manageServicesTab",
      icon: "manage-services",
      route: routes.services,
    },
  ];
};
