import React, { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";

import Plan, { Group, Label, Line, Value } from "../../../../components/Plan";
import Price from "../../../../components/Price";
import Period from "../../../../components/Period";
import { calcCouponDiscount, isTimeframeNow } from "../../utils";
import { getInterval } from "../../../../utils/common";
import { Tip } from "../../../../components/Plan/components/Tip/Tip";
import { Title } from "../../../../components/Plan/components/Title/Title";
import { INewPlanProps } from "./types";

import "./styles.scss";

export const NewPlan: FC<INewPlanProps> = ({
  plan,
  preview,
  coupon,
  isRenew,
  isWinbackDiscount,
  isTitleNeeded,
  isPromoCodeShown,
}) => {
  const { t } = useTranslation();
  const isBilledNow = useMemo(() => {
    return isTimeframeNow(preview);
  }, [preview]);
  const couponDiscount = useMemo(() => {
    return calcCouponDiscount(preview);
  }, [preview]);
  const discountTitle = useMemo(() => {
    return isWinbackDiscount ? "discount25" : "couponApplied";
  }, [isWinbackDiscount]);

  const billingPeriodLength = getInterval(plan.sp.billing_period).length;
  const currentDate = new Date();
  const renewDate = new Date(
    currentDate.setMonth(currentDate.getMonth() + billingPeriodLength),
  );
  const enUSDateOptions = { month: "2-digit", day: "2-digit", year: "numeric" };
  const enUSDateFormat = renewDate.toLocaleDateString("en-US", enUSDateOptions);

  return (
    <>
      {isTitleNeeded && <Title>{t("orderSummary")}</Title>}

      <Plan>
        <Group>
          <Label>{plan.sp.name}</Label>
          <Value>
            <Price
              currencyCode={plan.geoCost.currency}
              amount={plan.geoCost.amount}
            />
            /
            <Period data={plan.sp.billing_period} />
          </Value>
        </Group>
        {!isBilledNow ? (
          <Group>
            <Label className="ams-plan-label__highlight">{t("tax")}</Label>
            <Value>
              <Price
                currencyCode={preview.estimate.currency}
                amount={preview.estimate.tax}
              />
            </Value>
          </Group>
        ) : null}
        <Line />
        {isBilledNow && (
          <>
            <Group>
              <Label className="ams-plan-label__highlight">
                {t("subtotal")}
              </Label>
              <Value>
                <Price
                  currencyCode={preview.details.subtotal.currency}
                  amount={preview.details.subtotal.amount}
                />
              </Value>
            </Group>
            <Group>
              <Label className="ams-plan-label__highlight">{t("tax")}</Label>
              <Value>
                <Price
                  currencyCode={preview.estimate.currency}
                  amount={preview.estimate.tax}
                />
              </Value>
            </Group>
            {!!preview.details.credit_applied.amount && (
              <Group>
                <Label className="ams-plan-label__highlight">
                  {t("creditApplied")}
                </Label>
                <Value>
                  -
                  <Price
                    currencyCode={preview.details.credit_applied.currency}
                    amount={preview.details.credit_applied.amount}
                  />
                </Value>
              </Group>
            )}
            {(isPromoCodeShown || !!coupon) && couponDiscount > 0 && (
              <Group>
                <Label className="ams-plan-label__highlight">
                  {t(discountTitle)}
                </Label>
                <Value
                  className={
                    isWinbackDiscount ? "ams-plan-value__highlight" : ""
                  }
                >
                  -
                  <Price
                    currencyCode={preview.estimate.currency}
                    amount={couponDiscount}
                  />
                </Value>
              </Group>
            )}
            <Line />
          </>
        )}
        <Group>
          <Label className="ams-billed-today ams-plan-label__highlight">
            {t("billedToday")}
          </Label>
          <Value>
            <Price
              currencyCode={preview.details.paid.currency}
              amount={isBilledNow ? preview.details.paid.amount : 0}
            />
          </Value>
          {isRenew && (
            <Tip>
              {t("subscriptionWillRenew", {
                renewDate: enUSDateFormat,
              })}
            </Tip>
          )}
        </Group>
      </Plan>
    </>
  );
};
