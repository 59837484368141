export const LOCK_CUSTOMER = "lockCustomer";
export const UNLOCK_CUSTOMER = "unlockCustomer";
export const LOCK_PRINCIPAL = "lockPrincipal";
export const UNLOCK_PRINCIPAL = "unlockPrincipal";

export const PROBLEM_TYPE = "/problems/sp/recurly/3ds2-action-required";

export const PLANS_FEATURES = [
  [
    "multipleUserManagement",
    "globalBusinessServers",
    "dedicatedAccountManager",
  ],
  ["dedicatedIP", "ownDedicatedServer", "fastAndEasyDeployment"],
];

export const RECURLY_PAYMENT_METHOD_ERRORS = [
  [
    "first_name",
    "last_name",
    "country",
    "vat_number",
    "routing_number",
    "account_number",
    "name_on_account",
    "address1",
    "city",
    "state",
    "postal_code",
  ],
  [
    "recurlyErrors.incorrectFirstName",
    "recurlyErrors.incorrectLastName",
    "recurlyErrors.incorrectCountry",
    "recurlyErrors.incorrectVAT",
    "recurlyErrors.incorrectRoutingNumber",
    "recurlyErrors.incorrectAccountNumber",
    "recurlyErrors.incorrectNameOnAccount",
    "recurlyErrors.incorrectAddress",
    "recurlyErrors.incorrectCity",
    "recurlyErrors.incorrectState",
    "recurlyErrors.incorrectPostalCode",
  ],
];
