import { ActionTypes, IBillingHistoryState } from "./types";
import {
  FETCH_INVOICES_ERROR,
  FETCH_INVOICES_SUCCESS,
  FETCH_INVOICE_TRIGGER,
  initialState,
} from "./constants";

const reducer = (prevState: IBillingHistoryState, action: ActionTypes) => {
  const state = prevState || initialState;

  switch (action.type) {
    case FETCH_INVOICE_TRIGGER:
      return { ...state, isFetchingInvoices: true };
    case FETCH_INVOICES_SUCCESS:
      return {
        ...state,
        data: action.payload,
        error: initialState.error,
        isFetchingInvoices: false,
      };
    case FETCH_INVOICES_ERROR:
      return {
        ...state,
        error: action.payload,
        data: initialState.data,
        isFetchingInvoices: false,
      };
    default:
      return state;
  }
};

export default reducer;
