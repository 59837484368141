import EventEmitter from "../../utils/event-emitter";
import { BrandCode } from "../../models/brand";
import { IBrandService } from "./types";

export class BrandService extends EventEmitter implements IBrandService {
  private brandId?: BrandCode;

  constructor(private rootHTMLElement: HTMLElement) {
    super();
  }

  get() {
    return this.brandId;
  }

  set(brand: BrandCode) {
    this.brandId = brand;
    this.rootHTMLElement.setAttribute("data-brand", brand);
    this.rootHTMLElement
      .querySelector("#tab-logo")
      ?.setAttribute("href", `/brand/${brand}.ico`);
    this.emit();
  }
}
