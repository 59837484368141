import React, { FC } from "react";
import { NavLink } from "react-router-dom";
import classnames from "classnames";

import Logo from "./components/Logo";
import { HeaderProps } from "./types";

import "./styles.scss";

export const Header: FC<HeaderProps> = ({ className, children }) => {
  return (
    <header className={classnames("ams-header", className)}>
      <div className="ams-header__block">
        <div className="ams-header__logo-wrapper">
          <NavLink className="ams-header__link" to="/">
            <Logo />
          </NavLink>
        </div>
        <div className="ams-header__content">{children}</div>
      </div>
    </header>
  );
};
