import { IDateRange, IState } from "./types";

export const FILTER_UPDATE = "principal/updateFilter";
export const FILTER_TEXT_UPDATE = "principal/updateFilterText";
export const FILTER_RESET = "principal/resetFilter";
export const PAGINATION_UPDATE = "principal/updatePagination";
export const SORT_UPDATE = "principal/updateSort";
export const FETCH_TRIGGER = "principal/fetchTrigger";
export const FETCH_REQUEST = "principal/fetchRequest";
export const FETCH_SUCCESS = "principal/fetchSuccess";
export const FETCH_FAILURE = "principal/fetchFailure";
export const RESET_ALL = "principal/resetAll";

export const dateRanges: IDateRange[] = [
  {
    label: "All Time",
    value: "all",
  },
  {
    label: "1 Month",
    value: "1",
  },
  {
    label: "3 Months",
    value: "3",
  },
  {
    label: "6 Months",
    value: "6",
  },
  {
    label: "1 Year",
    value: "12",
  },
  {
    label: "Custom",
    value: "custom",
  },
];

export const initialState: IState = {
  isFetching: false,
  data: [],
  error: undefined,
  page: 0,
  pages: 0,
  pageSize: 25,
  dateRanges,
  filterText: { value: "", valid: false },
  filterDateRange: { type: "all" },
  sort: { field: "created_at", direction: "desc" },
  tenantCode: "",
};
