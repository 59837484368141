import { IState } from "./types";

export const PAGINATION_UPDATE = "skuMapping/updatePagination";
export const FETCH_TRIGGER = "skuMapping/fetchTrigger";
export const FETCH_REQUEST = "skuMapping/fetchRequest";
export const FETCH_SUCCESS = "skuMapping/fetchSuccess";
export const FETCH_FAILURE = "skuMapping/fetchFailure";
export const SORT_UPDATE = "skuMapping/updateSort";
export const FILTER_UPDATE = "skuMapping/updateFilter";
export const FILTER_TEXT_UPDATE = "skuMapping/updateFilterText";
export const FILTER_RESET = "skuMapping/resetFilter";
export const FETCH_DETAILS = "skuMapping/fetchDetails";
export const FETCH_DETAILS_SUCCESS = "skuMapping/fetchDetailsSuccess";
export const FETCH_FEATURE_DETAILS = "skuMapping/fetchFeatureDetails";
export const FETCH_CLEAR_FEATURE_DETAILS =
  "skuMapping/fetchClearFeatureDetails";
export const FETCH_FEATURE_DETAILS_SUCCESS =
  "skuMapping/fetchFeatureDetailsSuccess";
export const RESET_ALL = "skuMapping/resetAll";

export const initialState: IState = {
  data: [],
  filterText: { value: "", valid: false },
  spCode: "all",
  productCode: "all",
  status: "active",
  page: 0,
  pages: 0,
  pageSize: 25,
  sort: { field: "updated_at", direction: "desc" },
  tenantCode: "",
  error: undefined,
  isFetching: false,
  currentSKUDetails: undefined,
  featureSKUDetails: undefined,
};
