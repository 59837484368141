import { CURRENCY_DATA } from "../../../../services/currency/constants";

export const CREDIT_CARD = "credit_card";
export const PAYPAL = "paypal";
export const AMAZON = "amazon";
export const ACH_BANK_DRAFT = "ACHBankDraft";
export const SEPA_BANK_DRAFT = "SEPABankDraft";

export const PAYMENT_TYPE_LIST = [
  {
    value: CREDIT_CARD,
    label: "creditDebitCard",
    isAvalible: (currency: string) => {
      return true;
    },
  },
  {
    value: PAYPAL,
    label: "paypal",
    isAvalible: (currency: string) => {
      return true;
    },
  },
  {
    value: AMAZON,
    label: "amazon",
    isAvalible: (currency: string) => {
      return (
        currency !== CURRENCY_DATA.EUR.currency &&
        currency !== CURRENCY_DATA.GBP.currency
      );
    },
  },
  {
    value: ACH_BANK_DRAFT,
    label: "checkingSavingsAccount",
    isAvalible: (currency: string) => {
      return (
        currency !== CURRENCY_DATA.EUR.currency &&
        currency !== CURRENCY_DATA.GBP.currency
      );
    },
  },
  {
    value: SEPA_BANK_DRAFT,
    label: "internationalBankAccount",
    isAvalible: (currency: string) => {
      return currency === CURRENCY_DATA.EUR.currency;
    },
  },
];
