import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-xhr-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import ICU from "i18next-icu";
import { config as i18nConfig } from "./constants";

const amsLanguageDetectorConfig = {
  name: "amsLanguageDetector",

  lookup(options: {}) {
    const supportedLangs = i18nConfig.whitelist || [];
    const browserLanguage = navigator.language;
    const completeMatch = supportedLangs.includes(
      browserLanguage.toLowerCase(),
    );

    if (completeMatch) {
      return browserLanguage.toLowerCase();
    }

    const [shortLang, locale] = browserLanguage.toLowerCase().split("-");

    if (shortLang === "zh") {
      return !locale || locale !== "cn" ? "en" : shortLang;
    }
    return supportedLangs.includes(shortLang) ? shortLang : "en";
  },
};
const amsLanguageDetector = new LanguageDetector();
amsLanguageDetector.addDetector(amsLanguageDetectorConfig);

export default i18n
  .use(Backend)
  .use(amsLanguageDetector)
  .use(initReactI18next)
  .use(ICU);
