import React, { FC } from "react";
import classNames from "classnames";

import { INavbarProps } from "./types";

import "./Navbar.scss";

export const Navbar: FC<INavbarProps> = (props) => {
  const classes = classNames("navbar", props.className);

  return <div className={classes}>{props.children}</div>;
};

Navbar.defaultProps = {
  className: "",
  children: null,
};
