import React, { FC } from "react";
import classnames from "classnames";

import { BaseLayoutProps } from "./types";

import "./styles.scss";

export const BaseLayout: FC<BaseLayoutProps> = ({ className, children }) => {
  return <div className={classnames("ams-layout", className)}>{children}</div>;
};
