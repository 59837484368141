import { createContext } from "react";
import { createContextualCan } from "@casl/react";

import abilityService from "../../services/ability";
import { Ability } from "../../models/permissions";

export const AbilityContext = createContext<Ability>(
  abilityService.getAbility(),
);
export const Can = createContextualCan(AbilityContext.Consumer);
