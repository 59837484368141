import { IDateRangeState } from "./types";
import { getDateString } from "../../utils/common";

export const getDateRange = (source: IDateRangeState) => {
  if (source.type === "all") {
    return { startDate: "", endDate: "" };
  }

  if (source.type === "custom") {
    return { startDate: source.startDate, endDate: source.endDate };
  }

  const startDate = new Date();

  startDate.setMonth(startDate.getMonth() - parseInt(source.type));

  return {
    startDate: getDateString(startDate),
    endDate: getDateString(new Date()),
  };
};
