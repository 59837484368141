import { useCallback, useState } from "react";

export const useDialog = (initial = false) => {
  const [isShowModal, setIsShowModal] = useState(initial);

  const open = useCallback(() => {
    return setIsShowModal(true);
  }, [setIsShowModal]);

  const close = useCallback(() => {
    return setIsShowModal(false);
  }, [setIsShowModal]);

  return [isShowModal, open, close] as const;
};
