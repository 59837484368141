import { createSelector } from "reselect";

import { IRootState } from "../reducers";
import { IState } from "./types";
import { IUsage, IUsageSummary } from "../../services/api/usage/types";
import { round } from "../../utils/common";
import { MILLISECOND_IN_DAY } from "../../utils/common/contants";

export const selectUsageState = (state: IRootState): IState => {
  return state.usage;
};

export const selectUsageList = createSelector(
  [selectUsageState],
  (usageState: IState): IUsage[] => {
    return usageState.usageList;
  },
);

export const selectUsageFetching = createSelector(
  [selectUsageState],
  (usageState: IState): boolean => {
    return usageState.isFetching;
  },
);

export const selectUsageError = createSelector(
  [selectUsageState],
  (usageState: IState): boolean => {
    return usageState.error;
  },
);

export const selectCurrentTransfer = createSelector(
  [selectUsageList],
  (usageList: IUsage[]): IUsage | undefined => {
    return usageList.find((item) => {
      return item.is_current;
    });
  },
);

export const selectUsageSummary = createSelector(
  [selectUsageList],
  (usageList: IUsage[]): IUsageSummary | null => {
    if (!usageList.length) {
      return null;
    }

    const oldestStartData = [...usageList].sort((a, b) => {
      return (
        new Date(a.period_starts_at).getTime() -
        new Date(b.period_starts_at).getTime()
      );
    })[0]?.period_starts_at;

    const lastEndData = [...usageList].sort((a, b) => {
      return (
        new Date(b.period_ends_at).getTime() -
        new Date(a.period_ends_at).getTime()
      );
    })[0]?.period_ends_at;

    const days = usageList.reduce((value, item) => {
      const time =
        new Date(item.period_ends_at).getTime() -
        new Date(item.period_starts_at).getTime();
      return value + round(time / MILLISECOND_IN_DAY, 2);
    }, 0);

    const bytes = usageList.reduce((value, item) => {
      return value + item.amount;
    }, 0);

    return {
      days,
      bytes,
      // eslint-disable-next-line @typescript-eslint/camelcase
      period_starts_at: oldestStartData,
      // eslint-disable-next-line @typescript-eslint/camelcase
      period_ends_at: lastEndData,
    };
  },
);
