import React, { FC } from "react";
import classnames from "classnames";

import { LinkProps } from "./types";

import "./styles.scss";

export const Link: FC<LinkProps> = ({ className, children, ...props }) => {
  return (
    <a className={classnames("ams-link", className)} {...props}>
      {children}
    </a>
  );
};
