import React, { FC } from "react";
import { matchPath } from "react-router";
import { Redirect, Route, RouteProps, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

import * as routes from "../../config/router/routes";
import { useAbility } from "../../hooks";
import { selectCustomerData } from "../../store/user";
import { selectBrandCode } from "../../store/metadata";
import { selectCurrentCustomerSubscription } from "../../store/subscriptions";
import { IRedirectAllowedParams } from "./types";
import { getRoutes } from "../../pages/Main/utils";

const routeList = getRoutes(Object.values(routes));

export const ProtectedRoute: FC<RouteProps> = (props) => {
  const location = useLocation();
  const ability = useAbility();
  const customer = useSelector(selectCustomerData);
  const brandCode = useSelector(selectBrandCode);
  const currentSubscription = useSelector(selectCurrentCustomerSubscription);

  return isRedirectAllowed({
    ability,
    pathname: location.pathname,
    customer,
    brandCode,
    currentSubscription,
  }) ? (
    <Route {...props} />
  ) : (
    <Redirect to="/" />
  );
};

export function isRedirectAllowed({
  ability,
  pathname,
  customer,
  brandCode,
  currentSubscription,
}: IRedirectAllowedParams) {
  const routeConfig = routeList.find((route) => {
    return matchPath(pathname, {
      path: route.path,
      exact: true,
      strict: false,
    });
  });

  if (routeConfig) {
    return routeConfig.isRedirectAllowed({
      ability,
      customer,
      brandCode,
      currentSubscription,
    });
  }

  return false;
}
