import { createSelector } from "reselect";

import { IRootState } from "../reducers";
import { IUser, IUserState } from "./types";
import {
  IBillingAccount,
  IBillingInfo,
  ICustomer,
  IUserResponseData,
} from "../../services/api/user/types";
import { RECURLY } from "../../constants/spCode";

const selectUserState = (state: IRootState): IUserState => {
  return state.user;
};

export const selectUserData = createSelector(
  [selectUserState],
  (userState: IUserState): IUser => {
    return userState.data;
  },
);

export const selectIsUserReady = createSelector(
  [selectUserState],
  (user): boolean => {
    return user.isUserReady;
  },
);

export const selectPrincipalData = createSelector(
  [selectUserState],
  (userState: IUserState): IUserResponseData | undefined => {
    return userState.principalData;
  },
);

export const selectCustomerData = createSelector(
  [selectUserState],
  (userState: IUserState): ICustomer | undefined => {
    return userState.customerData;
  },
);

export const selectBillingAccountList = createSelector(
  [selectUserState],
  (userState: IUserState): IBillingAccount[] | undefined => {
    return userState.billingAccountList;
  },
);

export const selectRecurlyBillingAccount = createSelector(
  [selectBillingAccountList],
  (billingAccountList): IBillingAccount | undefined => {
    return billingAccountList?.find((item) => {
      return item.sp_code === RECURLY;
    });
  },
);

export const selectIsFetchingPrincipalAndCustomer = createSelector(
  [selectUserState],
  (userState: IUserState): boolean => {
    return userState.isFetchingPrincilal || userState.isFetchingCustomer;
  },
);

export const selectBillingData = createSelector(
  [selectUserState],
  (userState: IUserState): IBillingInfo | undefined => {
    return userState.billingData;
  },
);

export const selectIsBillingDataLoaded = createSelector(
  [selectUserState],
  (userState: IUserState): boolean => {
    return userState.isBillingDataLoaded;
  },
);

export const selectPaymentCountry = createSelector(
  [selectUserState],
  (userState: IUserState): string | undefined => {
    return userState.country;
  },
);

export const selectPaymentPostalCode = createSelector(
  [selectUserState],
  (userState: IUserState): string | undefined => {
    return userState.postalCode;
  },
);

export const selectPaymentRegion = createSelector(
  [selectUserState],
  (userState: IUserState): string | undefined => {
    return userState.region;
  },
);
