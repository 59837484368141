export const API_PREFIX = "/api";

const ORIGIN = process.env.REACT_APP_API_BASE_URL || window.location.origin;

export const baseURL = `${ORIGIN}${API_PREFIX}`;

export const url = {
  principal: {
    base: "/user/principals",
    block: "/user/principals/actions/block",
    login: "/user/principals/actions/login",
    refresh: "/user/principals/actions/refresh",
    resetPassword: "/user/actions/recover-password",
    profile: "/user/principals/:principalId",
    confirmPasswordRecovery: "/user/actions/confirm-password-recovery",
  },
  brand: "/actions/get-origin-info",
  tenants: "/actions/get-tenants",
  SKU: {
    base: "/product/skus",
    get: "/product/skus/:skuId",
    details: "/product/skus/:skuId/actions/get-details",
  },
  product: {
    base: "/product/products",
  },
  productLine: {
    base: "/product/product-lines",
    default: "/product/product-lines/:productLineCode",
  },
  offering: {
    base: "/product/offerings",
    getDetails: "/product/offerings/:offeringCode/actions/get-details",
  },
  actions: {
    signup: "/actions/signup/signup",
    signupPreview: "/actions/signup/preview",
  },
  subscriptions: {
    get: "/subscription/customers/:customerId/subscriptions",
    getDetail:
      "/subscription/customers/:customerId/subscriptions/:subscriptionId/actions/get-detail",
    update:
      "/subscription/customers/:customerId/subscriptions/:subscriptionId/actions/update",
    create: "/subscription/customers/:customerId/actions/create-subscription",
    previewSubscription:
      "/subscription/customers/:customerId/actions/preview-subscription",
    previewUpdate:
      "/subscription/customers/:customerId/subscriptions/:subscriptionId/actions/preview-update",
    cancel:
      "/subscription/customers/:customerId/subscriptions/:subscriptionId/actions/cancel",
    uncancel:
      "/subscription/customers/:customerId/subscriptions/:subscriptionId/actions/uncancel",
    pause:
      "/subscription/customers/:customerId/subscriptions/:subscriptionId/actions/pause",
    unpause:
      "/subscription/customers/:customerId/subscriptions/:subscriptionId/actions/unpause",
    recycle:
      "/subscription/customers/:customerId/subscriptions/:subscriptionId/actions/recycle",
    options:
      "/subscription/customers/:customerId/subscriptions/:subscriptionId/options",
  },
  user: {
    getPermissions: "/actions/get-permissions",
  },
  customer: {
    base: "/user/customers/:customerId",
    billingInfo: {
      base: "subscription/customers/:customerId/billing-accounts",
      get:
        "/subscription/customers/:customerId/billing-accounts/:spId/actions/get-billing-info",
      set: "/email-confirmation/:customerId/recurly/set-billing-info",
      delete:
        "subscription/customers/:customerId/billing-accounts/:spCode/actions/delete-billing-info",
      invoices:
        "subscription/customers/:customerId/subscriptions/:subscriptionId/actions/get-invoices",
    },
    delete: "/user/customers/:customerId/actions/delete",
    principals: {
      base: "/user/customers/:customerId/principals",
      edit: "/user/customers/:customerId/principals/:principalId",
    },
    licenses:
      "/subscription/customers/:customerId/subscriptions/:subscriptionId/licenses",
  },
  standing: {
    base: "/standing/standings/:standingId",
    all: "/standing/standings",
    watch: "/standing/sapphire",
  },
  account: {
    options:
      "/account/customers/:customerId/accounts/:principalId/:serviceCode/options",
  },
  usage: {
    list: "/usage/customers/:customerId/subscriptions/:subscriptionId",
  },
};
