import { all, call, fork, put, takeLatest } from "redux-saga/effects";

import { FETCH_INVOICE_TRIGGER } from "./constants";

import {
  fetchInvoicesError,
  fetchInvoicesSuccess,
  fetchingInvoices,
} from "./actions";
import { IFetchInvoiceTriggerAction } from "./types";
import userAPIService from "../../services/api/user";
import { Invoice } from "../../types/common";
import { MAX_INVOICES_NUMBER } from "../user";

export function* fetchInvoices({ payload }: IFetchInvoiceTriggerAction) {
  yield put(fetchingInvoices(payload));
  try {
    let invoicesData: Invoice[] = [];

    for (const item of payload.subscriptionIds.reverse()) {
      const SpInvoicesData = yield call(
        [userAPIService, userAPIService.getInvoices],
        {
          customerId: payload.customerId,
          subscriptionId: item,
        },
      );

      invoicesData = [...invoicesData, ...SpInvoicesData];

      if (invoicesData.length > MAX_INVOICES_NUMBER) {
        break;
      }
    }

    yield put(fetchInvoicesSuccess(invoicesData));
  } catch (error) {
    yield put(fetchInvoicesError(error));
  }
}

export function* watchFetchBillingHistory() {
  yield takeLatest(FETCH_INVOICE_TRIGGER, fetchInvoices);
}

export function* billingHistorySaga() {
  yield all([fork(watchFetchBillingHistory)]);
}
