import { InitOptions } from "i18next";

export const config: InitOptions = {
  whitelist: ["en", "es", "fr", "de", "zh"],
  ns: ["translation"], // "account", "profile", "login"],
  defaultNS: "translation",
  detection: {
    order: ["amsLanguageDetector", "navigator"],
  },
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
  },
  lowerCaseLng: true,
  react: {
    useSuspense: false,
    transKeepBasicHtmlNodesFor: ["strong"],
  },
  debug: process.env.NODE_ENV !== "production",
};
