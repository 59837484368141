import axios from "axios";

import { API } from "./api";
import { baseURL } from "./constants";
import { getIdToken } from "../auth";

export const getAccessToken = async () => {
  return getIdToken();
};

export default new API(axios, {
  baseURL,
  getAccessToken,
});
