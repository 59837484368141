import { ActionTypes, IState } from "./types";
import {
  FETCH_FAILURE,
  FETCH_LICENSES_FAILURE,
  FETCH_LICENSES_REQUEST,
  FETCH_LICENSES_SUCCESS,
  FETCH_REQUEST,
  FETCH_SUCCESS,
  PAGINATION_UPDATE,
  RESET_FILTER,
  SET_CUSTOMER_ID,
  SORT_UPDATE,
  UPDATE_FILTER,
  UPDATE_FILTER_SEARCH,
  initialState,
} from "./constants";

const reducer = (prevState: IState, action: ActionTypes) => {
  const state = prevState || initialState;

  switch (action.type) {
    case FETCH_REQUEST:
    case FETCH_LICENSES_REQUEST:
      return { ...state, isFetching: true };
    case FETCH_FAILURE:
    case FETCH_LICENSES_FAILURE:
      return { ...state, isFetching: false, error: action.payload };

    case FETCH_SUCCESS: {
      const { paging, items } = action.payload;
      return {
        ...state,
        items,
        paging: {
          totalItems: paging.total_items,
          page: paging.page - 1,
          pageSize: paging.per_page,
          pages: Math.ceil(paging.total_items / paging.per_page),
        },
        isFetching: false,
      };
    }
    case FETCH_LICENSES_SUCCESS:
      return { ...state, license: action.payload, isFetching: false };
    case SET_CUSTOMER_ID:
      return { ...state, customerId: action.payload };
    case PAGINATION_UPDATE:
      return { ...state, paging: { ...state.paging, ...action.payload } };
    case SORT_UPDATE:
      return { ...state, sort: action.payload };
    case UPDATE_FILTER:
      return {
        ...state,
        filter: { ...state.filter, ...action.payload },
        paging: { ...state.paging, page: 0 },
        isFetching: true,
      };
    case UPDATE_FILTER_SEARCH:
      return {
        ...state,
        filter: { ...state.filter, search: action.payload },
        paging: { ...state.paging, page: 0 },
      };
    case RESET_FILTER:
      return { ...state, filter: initialState.filter, isFetching: true };
    default:
      return state;
  }
};

export default reducer;
