import React, { FC } from "react";

import { LoaderProps } from "./types";

import "./styles.scss";

export const SidebarLoader: FC<LoaderProps> = () => {
  return (
    <aside className="ams-sidebar ams-layout__sidebar ams-layout__sidebar-loader">
      <nav className="navigation">
        <div>
          <div className="navigation__divider" />
          <div className="navigation__item">
            <span className="navigation__item-load-disk" />
            <div className="navigation__item-load-line loading" />
          </div>
          <div className="navigation__divider" />
        </div>
        <div>
          <div className="navigation__item">
            <span className="navigation__item-load-disk" />
            <div className="navigation__item-load-line loading" />
          </div>
          <div className="navigation__divider" />
        </div>
        <div>
          <div className="navigation__item">
            <span className="navigation__item-load-disk" />
            <div className="navigation__item-load-line loading" />
          </div>
          <div className="navigation__divider" />
        </div>
        <div>
          <div className="navigation__item">
            <span className="navigation__item-load-disk" />
            <div className="navigation__item-load-line loading" />
          </div>
          <div className="navigation__divider" />
        </div>
        <div>
          <div className="navigation__item">
            <span className="navigation__item-load-disk" />
            <div className="navigation__item-load-line loading" />
          </div>
          <div className="navigation__divider" />
        </div>
      </nav>
    </aside>
  );
};
