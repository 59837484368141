import { IBillingHistoryState } from "./types";

export const FETCH_INVOICE_TRIGGER = "fetchInvoiceTrigger";
export const FETCHING_INVOICES = "fetchingInvoices";
export const FETCH_INVOICES_SUCCESS = "fetchInvoicesSuccess";
export const FETCH_INVOICES_ERROR = "fetchInvoicesError";

export const initialState: IBillingHistoryState = {
  data: [],
  error: undefined,
  isFetchingInvoices: false,
};
