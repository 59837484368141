import { createSelector } from "reselect";
import {
  selectCurrentCustomerSubscription,
  selectCurrentSubscriptionDetail,
  selectCurrentSubscriptionDetailLoaded,
} from "../subscriptions";
import { selectCurrentSKUDetails, selectFeatureSKUDetail } from "../skuMapping";
import { ISKUDetails } from "../../services/api/offering/types";
import { selectBrandCode } from "../metadata";
import { INextInvoice } from "../../types/common";

export const selectNextInvoiceData = createSelector(
  [selectCurrentSubscriptionDetail, selectFeatureSKUDetail],
  (subscriptionDetail, featureSKU): INextInvoice | undefined => {
    const featurePrice = featureSKU?.sp?.unit_cost.find((item) => {
      return (
        item.currency === subscriptionDetail?.sp.next_invoice_amount?.currency
      );
    });
    const nextInvoicesAmount = featurePrice
      ? featurePrice
      : subscriptionDetail?.sp.next_invoice_amount;

    return {
      nextInvoicesAmount,
      nextInvoicesAt: subscriptionDetail?.sp.next_invoices_at,
    };
  },
);

export const selectCurrentSKUAddon = createSelector(
  [selectCurrentSKUDetails, selectCurrentCustomerSubscription],
  (currentSKUDetails, customerSubscription): ISKUDetails | undefined => {
    if (!currentSKUDetails || !customerSubscription) {
      return undefined;
    }

    const currentSKU = currentSKUDetails?.items.find((item) => {
      return item.sku_id === customerSubscription.sku_id;
    });

    const SKUAddonList = currentSKUDetails?.items.filter((item) => {
      return currentSKU?.addons.includes(item.sku_id);
    });

    // fow now SKU support only one addon;
    return SKUAddonList[0] || undefined;
  },
);

export const selectIsCurrentSubscriptionReady = createSelector(
  [selectCurrentSubscriptionDetailLoaded, selectBrandCode],
  (isSubscriptionLoaded, brandCode): boolean => {
    return brandCode === "ADMIN" || isSubscriptionLoaded;
  },
);
