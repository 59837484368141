import React, { ChangeEvent, FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import FormGroup from "../FormGroup";
import Label from "../Label";
import Input from "../Input";
import Select from "../Select";
import { BILLING_COUNTRIES, REGIONS } from "./contants";
import { AddressFormContentProps } from "./types";
import Link from "../Link";
import { selectCustomerData } from "../../store/user";

import "./index.scss";

export const AddressFormContent: FC<AddressFormContentProps> = ({
  onValidationChange,
  onCountryChangeCallback,
  onPostalCodeChangeCallback,
  onRegionChangeCallback,
}) => {
  const { t } = useTranslation();
  const customer = useSelector(selectCustomerData);
  const [postalCode, setPostalCode] = useState("");
  const [countryName, setCountryName] = useState("");
  const [regionName, setRegionName] = useState("");
  const [isVATNeeded, setIsVATNeeded] = useState(false);

  const handleVATNumber = () => {
    setIsVATNeeded(true);
  };

  const onCountryChanged = (event: ChangeEvent<HTMLSelectElement>) => {
    setCountryName(event.target.value);
    if (onCountryChangeCallback) {
      onCountryChangeCallback(event);
    }
  };

  const onPostalCodeChanged = (event: ChangeEvent<HTMLInputElement>) => {
    setPostalCode(event.target.value);
    if (onPostalCodeChangeCallback) {
      onPostalCodeChangeCallback(event);
    }
  };

  const onRegionChanged = (event: ChangeEvent<HTMLSelectElement>) => {
    setRegionName(event.target.value);
    if (onRegionChangeCallback) {
      onRegionChangeCallback(event);
    }
  };

  const isValid =
    Boolean(countryName) && Boolean(postalCode) && Boolean(regionName);

  useEffect(() => {
    onValidationChange(isValid);
  }, [isValid]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <div className="ams-payment-form__address">
        <FormGroup className="ams-payment-form__country">
          <Label>{t("billingCountry")}</Label>
          <Select
            data-recurly="country"
            id="select-country"
            onChange={onCountryChanged}
          >
            <option value="">{t("chooseBillingCountry")}</option>
            {BILLING_COUNTRIES.map((key: string) => {
              return (
                <option value={key} key={key}>
                  {t(`countries.${key}`)}
                </option>
              );
            })}
          </Select>
        </FormGroup>

        <FormGroup className="ams-payment-form__postal-code">
          <Label>{t("postalCode")}</Label>
          <Input
            id="input-postal-code"
            data-recurly="postal_code"
            value={postalCode}
            onChange={onPostalCodeChanged}
          />
        </FormGroup>

        {countryName && ["US", "CA"].includes(countryName) && (
          <FormGroup className="ams-payment-form__region">
            <Label>{countryName === "US" ? t("state") : t("province")}</Label>
            <Select
              data-recurly="state"
              id="select-region"
              onChange={onRegionChanged}
            >
              <option value="">{t("chooseBillingStateProvince")}</option>
              {REGIONS[countryName].map((key: string) => {
                return (
                  <option value={key} key={key}>
                    {t(`regions.${countryName}.${key}`)}
                  </option>
                );
              })}
            </Select>
          </FormGroup>
        )}
      </div>

      {customer?.is_corporate ? (
        !isVATNeeded ? (
          <div className="ams-payment-form__link">
            <Link onClick={handleVATNumber}>{t("vat.haveVAT")}</Link>
          </div>
        ) : (
          <FormGroup className="ams-payment-form__vat">
            <Label>{t("vat.VATTitle")}</Label>
            <Input id="input-vat-number" data-recurly="vat_number" />
          </FormGroup>
        )
      ) : null}
    </>
  );
};
