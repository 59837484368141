import { createSelector } from "reselect";

import { IRootState } from "../reducers";
import { IState } from "./types";

export const selectState = (state: IRootState): IState => {
  return state.oidc;
};

export const selectUser = createSelector([selectState], (state: IState) => {
  return state.user;
});

export const selectOIDCUserId = createSelector([selectUser], (user) => {
  return user?.profile.sub;
});

export const selectIdToken = createSelector([selectUser], (user) => {
  return user?.id_token;
});

export const selectProfile = createSelector([selectUser], (user) => {
  return user?.profile;
});

export const selectCustomerId = createSelector([selectProfile], (profile) => {
  return profile?.customer_id;
});
