import { ToastContent, ToastOptions } from "react-toastify";

import { IconName } from "../../components/Icon/types";

export enum ToasterType {
  Success = "success",
  Warning = "warning",
  Error = "error",
}

interface IToasterOptions {
  content?: ToastContent;
  options?: ToastOptions;
  translationKey?: string;
}

interface IShowToasterOptions extends IToasterOptions {
  type: ToasterType;
  icon?: IconName;
}

export interface IToaster {
  success(options: IToasterOptions): void;
  error(options: IToasterOptions): void;
  warning(options: IToasterOptions): void;
  showToast(options: IShowToasterOptions): void;
}
