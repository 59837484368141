import { IRequestFormatter, TextRequestSource } from "./types";

export class TextRequestFormatter implements IRequestFormatter {
  private source: TextRequestSource;

  constructor(source: TextRequestSource) {
    this.source = source;
  }

  toString(): string {
    if (!this.source.value) {
      return "";
    }

    return `${this.source.field}:${this.source.value}`;
  }
}
