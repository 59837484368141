import { API } from "../api";
import { url } from "../constants";
import {
  IOfferingDetailsResponse,
  IOfferingPayload,
  IOfferingsResponse,
  Offering,
} from "./types";
import { validateArgs } from "../utils";

export class OfferingService {
  constructor(private api: API) {}

  async getAll(params: any): Promise<IOfferingsResponse> {
    const { data } = await this.api.get<IOfferingsResponse>(url.offering.base, {
      params,
    });
    return data;
  }

  async getDetails(id: string): Promise<IOfferingDetailsResponse> {
    validateArgs(id);
    const { data } = await this.api.get<IOfferingDetailsResponse>(
      url.offering.getDetails.replace(":offeringCode", id),
    );

    return data;
  }

  async create(offering: IOfferingPayload): Promise<Offering> {
    const { data } = await this.api.post<Offering>(url.offering.base, offering);

    return data;
  }

  async edit(
    code: string,
    offering: Partial<IOfferingPayload>,
  ): Promise<Offering> {
    validateArgs(code);
    const { data } = await this.api.patch<Offering>(
      `${url.offering.base}/${code}`,
      offering,
    );

    return data;
  }
}
