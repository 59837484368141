import { ActionTypes, IState } from "./types";
import {
  FETCH_OPTIONS_FAILURE,
  FETCH_OPTIONS_REQUEST,
  FETCH_OPTIONS_SUCCESS,
  UPDATE_OPTIONS_FAILURE,
  UPDATE_OPTIONS_REQUEST,
  UPDATE_OPTIONS_SUCCESS,
  initialState,
} from "./constants";

const reducer = (prevState: IState, action: ActionTypes) => {
  const state = prevState || initialState;

  switch (action.type) {
    case FETCH_OPTIONS_REQUEST:
    case UPDATE_OPTIONS_REQUEST:
      return { ...state, isFetching: true };
    case FETCH_OPTIONS_SUCCESS:
    case UPDATE_OPTIONS_SUCCESS:
      return {
        ...state,
        options: {
          ...state.options,
          isFetching: false,
          data: action.payload,
        },
      };
    case FETCH_OPTIONS_FAILURE:
    case UPDATE_OPTIONS_FAILURE:
      return { ...state, isFetching: false, error: action.payload };
    default:
      return state;
  }
};

export default reducer;
