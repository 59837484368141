import React, { FC } from "react";

import { Ability } from "../../models/permissions";
import abilityService from "../../services/ability";
import { AbilityContext } from "./Can";

type AbilityProviderProps = {
  value?: Ability;
};

export const AbilityProvider: FC<AbilityProviderProps> = ({
  value,
  children,
}) => {
  const ability = value || abilityService.getAbility();

  return (
    <AbilityContext.Provider value={ability}>
      {children}
    </AbilityContext.Provider>
  );
};
