import { ActionTypes, IState } from "./types";
import { CLEAR_STANDING_STORE, UPDATE_DATA } from "./constants";

export const updateData = (payload: Partial<IState>): ActionTypes => {
  return {
    type: UPDATE_DATA,
    payload,
  };
};

export const cleanStandingStore = (): ActionTypes => {
  return {
    type: CLEAR_STANDING_STORE,
  };
};
