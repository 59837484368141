import React, { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { useTranslation } from "react-i18next";

import { PrincipalLayout } from "./PrincipalLayout";
import brandService from "../../services/brand";
import { UserStatus } from "../../services/api/user/types";

import {
  EntityKind,
  IStanding,
  IStandingIdQuery,
  IStandingRequest,
} from "../../services/api/standing/types";
import { useStandingByIdQuery } from "../../hooks";
import { getUserProfile } from "../../services/auth";
import Toaster from "../../services/toaster";
import standingAPIService from "../../services/api/standing";
import {
  clearCustomerData,
  clearPrincipalData,
  fetchCurrentPrincipal,
  selectCustomerData,
  selectPrincipalData,
} from "../../store/user";
import { cleanStandingStore } from "../../store/standing";
import { clearSubscriptionsState } from "../../store/subscriptions";
import { resetAll } from "../../store/skuMapping";
import { fetchDataRequest as fetchTenantListRequest } from "../../store/metadata";
import { entityType } from "./constants";
import { useDialog } from "../../hooks";
import { lockedStanding } from "./utils";
import { ConfirmModal } from "../../components/ConfirmModal/ConfirmModal";
import {
  LOCK_CUSTOMER,
  LOCK_PRINCIPAL,
  UNLOCK_CUSTOMER,
  UNLOCK_PRINCIPAL,
} from "../../constants/common";

export const PrincipalContainer: FC = ({ children }) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { t } = useTranslation();
  const [customerStanding, setCustomerStanding] = useState<
    IStanding | undefined
  >();
  const [principalStanding, setPrincipalStanding] = useState<
    IStanding | undefined
  >();
  const customer = useSelector(selectCustomerData);
  const principal = useSelector(selectPrincipalData);
  const {
    data: standingResponse,
    refetch: refetchStandingList,
  } = useStandingByIdQuery({
    principalId: principal?.id,
    customerId: principal?.customer_id,
  } as IStandingIdQuery);

  const standingList = standingResponse?.items;

  const [customerStatus, setCustomerStatus] = useState<UserStatus>(
    UNLOCK_CUSTOMER,
  );
  const [principalStatus, setPrincipalStatus] = useState<UserStatus>(
    UNLOCK_PRINCIPAL,
  );
  const [
    isShowConfirmLockCustomerModal,
    openConfirmLockCustomerModal,
    closeConfirmLockCustomerModal,
  ] = useDialog();
  const [
    isShowConfirmUnlockCustomerModal,
    openConfirmUnlockCustomerModal,
    closeConfirmUnlockCustomerModal,
  ] = useDialog();
  const [
    isShowConfirmLockPrincipalModal,
    openConfirmLockPrincipalModal,
    closeConfirmLockPrincipalModal,
  ] = useDialog();
  const [
    isShowConfirmUnlockPrincipalModal,
    openConfirmUnlockPrincipalModal,
    closeConfirmUnlockPrincipalModal,
  ] = useDialog();
  const [isFetchingLock, setIsFetchingLock] = useState(false);

  useEffect(() => {
    dispatch(fetchCurrentPrincipal(id));
    dispatch(fetchTenantListRequest());

    return () => {
      dispatch(cleanStandingStore());
      dispatch(clearSubscriptionsState());
      dispatch(dispatch(clearCustomerData()));
      dispatch(dispatch(clearPrincipalData()));
      dispatch(resetAll());
    };
  }, [id, dispatch]);

  useEffect(() => {
    if (principal?.tenant_code) {
      brandService.set(principal.tenant_code);
    }

    return () => {
      brandService.set("ADMIN");
    };
  }, [principal]);

  useEffect(() => {
    if (standingList) {
      const lockedCustomerStanding = lockedStanding(
        entityType.typeCustomer,
        standingList,
      );
      const lockedPrincipalStanding = lockedStanding(
        entityType.typePrincipal,
        standingList,
      );

      if (lockedCustomerStanding) {
        setCustomerStatus(LOCK_CUSTOMER);
        setCustomerStanding(lockedCustomerStanding);
      } else {
        setCustomerStatus(UNLOCK_CUSTOMER);
      }

      if (lockedPrincipalStanding) {
        setPrincipalStatus(LOCK_PRINCIPAL);
        setPrincipalStanding(lockedPrincipalStanding);
      } else {
        setPrincipalStatus(UNLOCK_PRINCIPAL);
      }
    }
  }, [standingList]);

  const onChangeStatus = (status: UserStatus) => {
    switch (status) {
      case LOCK_CUSTOMER: {
        openConfirmLockCustomerModal();
        return;
      }
      case UNLOCK_CUSTOMER: {
        openConfirmUnlockCustomerModal();
        return;
      }
      case LOCK_PRINCIPAL: {
        openConfirmLockPrincipalModal();
        return;
      }
      case UNLOCK_PRINCIPAL: {
        openConfirmUnlockPrincipalModal();
        return;
      }
      default:
        return;
    }
  };

  const handleLockAccount = async (
    entityKind: EntityKind,
    entityId: string,
    modalAction: Function,
  ) => {
    if (principal?.id && principal?.customer_id) {
      const employee = await getUserProfile();

      try {
        setIsFetchingLock(true);
        // BE require props in snake-case
        /* eslint-disable */
        const lockedCustomerStanding: IStandingRequest = {
          entity_kind: entityKind,
          source: "EmployeePortal",
          source_kind: "EmployeeLock",
          customer_id: principal.customer_id,
          entity_id: entityId,
          source_data: JSON.stringify({
            employee: employee?.preferred_username,
            note: "",
          }),
        };

        await standingAPIService.create(lockedCustomerStanding);
        await refetchStandingList();
        modalAction();
      } catch (error) {
        if (error.response.status === 409) {
          Toaster.error({
            content: t("refreshYourPage"),
          });
          return;
        }

        Toaster.error({
          content: error.message || t("somethingWentWrong"),
        });
      } finally {
        setIsFetchingLock(false);
      }
    }
  };
  const handleLockCustomerAccount = () =>
    handleLockAccount(
      entityType.typeCustomer,
      principal?.customer_id as string,
      closeConfirmLockCustomerModal,
    );
  const handleLockPrincipalAccount = () =>
    handleLockAccount(
      entityType.typePrincipal,
      principal?.id as string,
      closeConfirmLockPrincipalModal,
    );

  const handleUnlockAccount = async (
    standing: IStanding | undefined,
    modalAction: Function,
  ) => {
    if (standing) {
      let standingEtag;
      let unlockErrorStatus;

      if (standing.entity_kind === entityType.typeCustomer) {
        await standingAPIService
          .getStandingHeaders(standing.id)
          .then((res) => {
            standingEtag = res.headers.eTag;
          })
          .catch((e) => {
            unlockErrorStatus = e.response.status;
          });
      }
      if (standing.entity_kind === entityType.typePrincipal) {
        await standingAPIService
          .getStandingHeaders(standing.id)
          .then((res) => {
            standingEtag = res.headers.eTag;
          })
          .catch((e) => {
            unlockErrorStatus = e.response.status;
          });
      }

      if (unlockErrorStatus === 404) {
        Toaster.error({
          content: t("refreshYourPage"),
        });
        return;
      }

      try {
        setIsFetchingLock(true);
        await standingAPIService.delete(standing?.id, standingEtag);
        await refetchStandingList();
        modalAction();
      } catch (error) {
        Toaster.error({
          content: error.message || t("somethingWentWrong"),
        });
      } finally {
        setIsFetchingLock(false);
      }
    }
  };
  const handleUnlockCustomerAccount = () =>
    handleUnlockAccount(customerStanding, closeConfirmUnlockCustomerModal);
  const handleUnlockPrincipalAccount = () =>
    handleUnlockAccount(principalStanding, closeConfirmUnlockPrincipalModal);

  return (
    <>
      <PrincipalLayout
        customerStatus={customerStatus}
        principalStatus={principalStatus}
        isCorporate={customer?.is_corporate}
        onChangeStatus={onChangeStatus}
      >
        {children}
      </PrincipalLayout>
      {isShowConfirmLockCustomerModal && (
        <ConfirmModal
          isOpen={isShowConfirmLockCustomerModal}
          title={t("adminHeaderDropdown.customerAccountConfirmationAction", {
            action: "Lock",
          })}
          message={t("adminHeaderDropdown.customerLockConfirmation", {
            action: "lock",
          })}
          submitBtnText={t("adminHeaderDropdown.lockCustomerAccount")}
          isLoading={isFetchingLock}
          onSubmit={handleLockCustomerAccount}
          onDismiss={closeConfirmLockCustomerModal}
          footerClassName={"mt-2xl"}
        />
      )}
      {isShowConfirmUnlockCustomerModal && (
        <ConfirmModal
          isOpen={isShowConfirmUnlockCustomerModal}
          title={t("adminHeaderDropdown.customerAccountConfirmationAction", {
            action: "Unlock",
          })}
          message={t("adminHeaderDropdown.customerLockConfirmation", {
            action: "unlock",
          })}
          submitBtnText={t("adminHeaderDropdown.unlockCustomerAccount")}
          isLoading={isFetchingLock}
          onSubmit={handleUnlockCustomerAccount}
          onDismiss={closeConfirmUnlockCustomerModal}
          footerClassName={"mt-2xl"}
        />
      )}
      {isShowConfirmLockPrincipalModal && (
        <ConfirmModal
          isOpen={isShowConfirmLockPrincipalModal}
          title={t("adminHeaderDropdown.principalAccountConfirmationAction", {
            action: "Lock",
          })}
          message={t("adminHeaderDropdown.principalLockConfirmation", {
            action: "lock",
          })}
          submitBtnText={t("adminHeaderDropdown.lockPrincipalAccount")}
          isLoading={isFetchingLock}
          onSubmit={handleLockPrincipalAccount}
          onDismiss={closeConfirmLockPrincipalModal}
          footerClassName={"mt-2xl"}
        />
      )}
      {isShowConfirmUnlockPrincipalModal && (
        <ConfirmModal
          isOpen={isShowConfirmUnlockPrincipalModal}
          title={t("adminHeaderDropdown.principalAccountConfirmationAction", {
            action: "Unlock",
          })}
          message={t("adminHeaderDropdown.principalLockConfirmation", {
            action: "unlock",
          })}
          submitBtnText={t("adminHeaderDropdown.unlockPrincipalAccount")}
          isLoading={isFetchingLock}
          onSubmit={handleUnlockPrincipalAccount}
          onDismiss={closeConfirmUnlockPrincipalModal}
          footerClassName={"mt-2xl"}
        />
      )}
    </>
  );
};
