import { createSelector } from "reselect";
import pickBy from "lodash/pickBy";

import { IRootState } from "../reducers";
import { IState } from "./types";
import { TableHeaderSort } from "../../components/Table/components/TableHeader/types";
import {
  RequestFormatter,
  TextRequestFormatter,
} from "../../services/request-formatter";
import {
  containsParam,
  getParam,
  getSortParams,
} from "../../services/api/utils";
import { selectProductLines, selectTenantCode } from "../metadata";
import { addOfferingDefaultProp } from "../../utils/offerings";

export const selectOfferingsState = (state: IRootState): IState => {
  return state.offerings;
};

export const selectData = createSelector(
  [selectOfferingsState],
  (offeringsState: IState): Array<any> => {
    return offeringsState.data;
  },
);

export const selectOfferingWithDefaultProp = createSelector(
  [selectData, selectProductLines],
  addOfferingDefaultProp,
);

export const selectIsFetching = createSelector(
  [selectOfferingsState],
  (offeringsState: IState): boolean => {
    return offeringsState.isFetching;
  },
);

export const selectError = createSelector(
  [selectOfferingsState],
  (offeringsState: IState): any => {
    return offeringsState.error;
  },
);

export const selectPage = createSelector(
  [selectOfferingsState],
  (offeringsState: IState): number => {
    return offeringsState.page;
  },
);

export const selectPages = createSelector(
  [selectOfferingsState],
  (offeringsState: IState): number => {
    return offeringsState.pages;
  },
);

export const selectPageSize = createSelector(
  [selectOfferingsState],
  (offeringsState: IState): number => {
    return offeringsState.pageSize;
  },
);

export const selectSort = createSelector(
  [selectOfferingsState],
  (offeringsState: IState): TableHeaderSort => {
    return offeringsState.sort;
  },
);

export const selectSearch = createSelector(
  [selectOfferingsState],
  (offeringsState: IState): string => {
    return offeringsState.filterText.valid
      ? offeringsState.filterText.value
      : "";
  },
);

export const selectSpCode = createSelector(
  [selectOfferingsState],
  (offeringsState: IState): string => {
    return offeringsState.spCode;
  },
);

export const selectProductLineCode = createSelector(
  [selectOfferingsState],
  (offeringsState: IState): string => {
    return offeringsState.productLineCode;
  },
);

export const selectFormattedSearch = createSelector(
  [selectSearch, selectSpCode, selectProductLineCode, selectTenantCode],
  (filterText: string, spCode, productLineCode, tenantCode): string => {
    return new RequestFormatter([
      new TextRequestFormatter({
        field: "name",
        value: containsParam(filterText),
      }),
      new TextRequestFormatter({
        field: "sp_code",
        value: getParam(spCode),
      }),
      new TextRequestFormatter({
        field: "product_line__code",
        value: getParam(productLineCode),
      }),
      new TextRequestFormatter({
        field: "product_line__tenant_code",
        value: getParam(tenantCode),
      }),
    ]).toString();
  },
);

export const selectPayload = createSelector(
  [selectSort, selectPage, selectPageSize, selectFormattedSearch],
  (
    { field, direction }: TableHeaderSort,
    page: number,
    limit: number,
    query: string,
  ) => {
    return pickBy({
      q: encodeURIComponent(query),
      limit,
      sort: getSortParams(direction, field),
      offset: (page + 1) * limit - limit,
      embed: "(skus)",
    });
  },
);
