import React, { FC } from "react";

import Header from "../../components/Header";
import BaseLayout from "./../BaseLayout";

import "./styles.scss";

export const PublicLayout: FC = ({ children }) => {
  return (
    <BaseLayout className="ams-layout--public">
      <Header />
      <main className="ams-layout__content">{children}</main>
    </BaseLayout>
  );
};
