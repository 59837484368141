import React, { FC, FormEvent, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router";
import get from "lodash/get";
import { useRecurly } from "@recurly/react-recurly";
import { TokenPayload } from "@recurly/recurly-js";
import { useDispatch, useSelector } from "react-redux";

import { subscriptions } from "../../config/router/routes";
import Toaster from "../../services/toaster";
import userAPIService from "../../services/api/user";

import { PaymentMethodPayload } from "../../pages/PaymentInformation/components/AddPaymentMethodModal/types";
import {
  ACH_BANK_DRAFT,
  AMAZON,
  CREDIT_CARD,
  PAYPAL,
  SEPA_BANK_DRAFT,
} from "../../pages/PaymentInformation/components/AddPaymentMethodModal/contants";
import { BillingToken } from "../../services/api/user/types";
import { AmazonResponse } from "../PayWithAmazon/types";
import { PAYMENT_STATES } from "../../utils/common/contants";
import {
  fetchBillingData,
  selectBillingData,
  selectPaymentCountry,
  selectPaymentPostalCode,
  selectPaymentRegion,
  selectPrincipalData,
  selectRecurlyBillingAccount,
} from "../../store/user";
import { PaymentInformationForm } from "../../pages/PaymentInformation/components/PaymentInformationForm/PaymentInformationForm";
import { useRecurlyToken } from "../../utils/recurly/useRecurlyToken";
import { PaymentInformationFooter } from "../../pages/PaymentInformation/components/PaymentInformationFooter/PaymentInformationFooter";
import { IPaymentInformationHandler } from "./types";
import {
  PROBLEM_TYPE,
  RECURLY_PAYMENT_METHOD_ERRORS,
} from "../../constants/common";

export const PaymentInformationHandler: FC<IPaymentInformationHandler> = ({
  children,
  confirmButtonText,
  paymentState,
  onDismiss,
  handlePaymentCreate,
  handlePaymentUpdate,
}) => {
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();
  const getRecurlyToken = useRecurlyToken();
  const userData = useSelector(selectPrincipalData);
  const recurly = useRecurly();
  const dispatch = useDispatch();

  const [paymentMethod, setPaymentMethod] = useState<string>("");

  const handlePaymentMethod = (data: string) => {
    setPaymentMethod(data);
  };

  const isAddLicenses = useMemo(() => {
    return get(location, ["state", "isAddLicenses"]);
  }, [location]);
  const selectedPlan = useMemo(() => {
    return get(location, ["state", "selectedPlan"]);
  }, [location]);
  const withPlanSelected = useMemo(() => {
    return !!selectedPlan;
  }, [selectedPlan]);

  const recurlyBillingAccount = useSelector(selectRecurlyBillingAccount);
  const billingInfo = useSelector(selectBillingData);
  const paymentCountry = useSelector(selectPaymentCountry);
  const paymentPostalCode = useSelector(selectPaymentPostalCode);
  const paymentRegion = useSelector(selectPaymentRegion);
  const [isAddPaymentFetching, setIsAddPaymentFetching] = useState(false);
  const [show3DSecure, setShow3DSecure] = useState(false);

  const fetchUserBillingData = ({
    spCode,
    customerId,
  }: {
    spCode?: string;
    customerId?: string;
  }) => {
    if (spCode && customerId) {
      dispatch(
        fetchBillingData({
          customerId,
          spCode,
        }),
      );
    }
  };

  useEffect(() => {
    fetchUserBillingData({
      spCode: recurlyBillingAccount?.sp_code,
      customerId: userData?.customer_id,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recurlyBillingAccount, userData]);

  const add3DSecure = (
    actionTokenId: string,
    recurlyToken: string,
    onSuccess: () => void,
  ) => {
    setShow3DSecure(true);
    // eslint-disable-next-line new-cap
    const risk = recurly.Risk();
    // eslint-disable-next-line new-cap
    const threeDSecure = risk.ThreeDSecure({
      actionTokenId: actionTokenId,
    });
    threeDSecure.on("token", async (token) => {
      try {
        await userAPIService.setBillingInfo(
          {
            // eslint-disable-next-line @typescript-eslint/camelcase
            recurly_token: recurlyToken,
            kind: "RECURLY_TOKEN",
            // eslint-disable-next-line @typescript-eslint/camelcase
            three_d_secure_action_result_token_id: token.id,
          },
          userData?.customer_id || "",
          paymentCountry,
          paymentPostalCode,
          paymentRegion,
        );
        onSuccess();
      } catch (error) {
        defaultErrorHandling(error);
      } finally {
        setShow3DSecure(false);
      }
      (threeDSecure as any).remove();
    });

    threeDSecure.on("error", () => {
      Toaster.error({
        content: t("thereWasAnIssueProcessingYourPayment"),
      });
      setShow3DSecure(false);
      (threeDSecure as any).remove();
    });
    threeDSecure.attach((document as any)?.querySelector("#ams-3d-secure"));
  };

  const handleCreateDismiss = () => {
    onDismiss();
    setShow3DSecure(false);

    if (withPlanSelected) {
      history.push(subscriptions.path);
    }
  };

  const onCreateSuccess = () => {
    handlePaymentCreate && handlePaymentCreate();

    Toaster.success({
      content: t("paymentMethodAddedSuccessfully"),
    });

    onDismiss();

    if (withPlanSelected) {
      history.push(subscriptions.path, { selectedPlan });
    }

    if (isAddLicenses) {
      history.push(subscriptions.path, { isAddLicenses });
    }
  };

  const handleEditDismiss = () => {
    onDismiss();
    setShow3DSecure(false);
  };

  const onUpdateSuccess = () => {
    handlePaymentUpdate && handlePaymentUpdate();

    Toaster.success({
      content: t("paymentMethodUpdatedSuccessfully"),
    });
    fetchUserBillingData({
      spCode: recurlyBillingAccount?.sp_code,
      customerId: userData?.customer_id,
    });
    onDismiss();
  };

  const handleSubmit = async (data: PaymentMethodPayload) => {
    if (data.type === CREDIT_CARD) {
      addCreditCardBillingInfo(data.event, onCreateSuccess);
      return;
    }

    if (data.type === PAYPAL) {
      addPayPalBillingInfo(onCreateSuccess);
      return;
    }

    if (data.type === AMAZON) {
      addAmazonBillingInfo(data.data, onCreateSuccess);
      return;
    }

    if (data.type === ACH_BANK_DRAFT) {
      addBankDraftBillingInfo(data.event, onCreateSuccess);
      return;
    }

    if (data.type === SEPA_BANK_DRAFT) {
      addBankDraftBillingInfo(data.event, onCreateSuccess);
      return;
    }
  };

  const handleUpdateSubmit = async (data: PaymentMethodPayload) => {
    if (data.type === CREDIT_CARD) {
      addCreditCardBillingInfo(data.event, onUpdateSuccess);
      return;
    }

    if (data.type === ACH_BANK_DRAFT) {
      addBankDraftBillingInfo(data.event, onUpdateSuccess);
      return;
    }

    if (data.type === SEPA_BANK_DRAFT) {
      addBankDraftBillingInfo(data.event, onUpdateSuccess);
      return;
    }

    if (data.type === PAYPAL) {
      addPayPalBillingInfo(onUpdateSuccess);
      return;
    }

    if (data.type === AMAZON) {
      addAmazonBillingInfo(data.data, onUpdateSuccess);
      return;
    }
  };

  const recurlyErrorHandling = (error: any) => {
    let recurlyErrorMessage = "";

    if (error.fields) {
      RECURLY_PAYMENT_METHOD_ERRORS[0].forEach((recurlyError, key) => {
        if (error.fields[0] === recurlyError) {
          recurlyErrorMessage = RECURLY_PAYMENT_METHOD_ERRORS[1][key];
        }
      });
    }

    if (recurlyErrorMessage) {
      return recurlyErrorMessage;
    }

    return error.response?.data?.title;
  };

  const defaultErrorHandling = (error: any) => {
    if (error?.response?.status === 429) {
      Toaster.error({
        content: t("tooManyAttempts"),
      });
    } else {
      Toaster.error({
        content: t("thereWasAnIssueProcessingYourPayment"),
      });
    }
  };

  const addBankDraftBillingInfo = async (
    event: FormEvent<HTMLFormElement>,
    onSuccess: () => void,
  ) => {
    setIsAddPaymentFetching(true);

    recurly.bankAccount.token(
      event.target as HTMLFormElement,
      async (error: any, token) => {
        if (error) {
          setIsAddPaymentFetching(false);

          const recurlyError = recurlyErrorHandling(error);
          if (recurlyError) {
            Toaster.error({
              content: t(recurlyError),
            });
            return;
          }

          Toaster.error({
            content: t("thereWasAnIssueProcessingYourPayment"),
          });
          return;
        }

        const billingToken: BillingToken = {
          kind: "RECURLY_TOKEN",
          // eslint-disable-next-line @typescript-eslint/camelcase
          recurly_token: token.id,
        };
        try {
          await userAPIService.setBillingInfo(
            billingToken,
            userData?.customer_id || "",
            paymentCountry,
            paymentPostalCode,
            paymentRegion,
          );
          onSuccess();
        } catch (err) {
          defaultErrorHandling(err);
        } finally {
          setIsAddPaymentFetching(false);
        }
      },
    );
  };

  const addCreditCardBillingInfo = async (
    event: FormEvent<HTMLFormElement>,
    onSuccess: () => void,
  ) => {
    let recurlyId = "";

    try {
      setIsAddPaymentFetching(true);
      const { id } = await getRecurlyToken(event.target as HTMLFormElement);
      recurlyId = id;
      const billingToken: BillingToken = {
        kind: "RECURLY_TOKEN",
        // eslint-disable-next-line @typescript-eslint/camelcase
        recurly_token: id,
      };

      await userAPIService.setBillingInfo(
        billingToken,
        userData?.customer_id || "",
        paymentCountry,
        paymentPostalCode,
        paymentRegion,
      );
      onSuccess();
    } catch (error) {
      if (
        error?.response?.status === 424 &&
        error?.response?.data?.type === PROBLEM_TYPE
      ) {
        add3DSecure(
          error?.response?.data.recovery.three_d_secure_action_result_token,
          recurlyId,
          onSuccess,
        );
        return;
      }

      const recurlyError = recurlyErrorHandling(error);
      if (recurlyError) {
        Toaster.error({
          content: t(recurlyError),
        });
        return;
      }

      defaultErrorHandling(error);
    } finally {
      setIsAddPaymentFetching(false);
    }
  };

  const addAmazonBillingInfo = async (
    data: AmazonResponse,
    onSuccess: () => void,
  ) => {
    try {
      setIsAddPaymentFetching(true);

      const billingToken: BillingToken = {
        kind: "RECURLY_AMAZON",
        // eslint-disable-next-line @typescript-eslint/camelcase
        amazon_billing_agreement_id: data.id,
      };
      await userAPIService.setBillingInfo(
        billingToken,
        userData?.customer_id || "",
        paymentCountry,
        paymentPostalCode,
        paymentRegion,
      );
      onSuccess();
    } catch (error) {
      defaultErrorHandling(error);
    } finally {
      setIsAddPaymentFetching(false);
    }
  };

  const addPayPalBillingInfo = async (onSuccess: () => void) => {
    // eslint-disable-next-line new-cap
    const paypal = recurly.PayPal({
      display: { displayName: "Subscription" },
    });

    paypal.on("error", () => {
      Toaster.error({
        content: t("thereWasAnIssueProcessingYourPayment"),
      });
    });

    paypal.on("token", async ({ id }: TokenPayload) => {
      try {
        setIsAddPaymentFetching(true);
        const billingToken: BillingToken = {
          kind: "RECURLY_PAYPAL",
          // eslint-disable-next-line @typescript-eslint/camelcase
          recurly_token: id,
        };

        await userAPIService.setBillingInfo(
          billingToken,
          userData?.customer_id || "",
          paymentCountry,
          paymentPostalCode,
          paymentRegion,
        );
        onSuccess();
      } catch (error) {
        defaultErrorHandling(error);
      } finally {
        setIsAddPaymentFetching(false);
      }
    });

    paypal.start();
  };

  const handleChangePaymentType = () => {
    setShow3DSecure(false);
  };

  const [isFormValid, setIsFormValid] = useState(false);

  return (
    <>
      {paymentState === PAYMENT_STATES.ADD_PAYMENT_METHOD && (
        <PaymentInformationForm
          onSubmit={handleSubmit}
          billingInfo={billingInfo}
          onChangePaymentType={handleChangePaymentType}
          show3DSecure={show3DSecure}
          setFormPaymentMethod={handlePaymentMethod}
          setIsFormValid={setIsFormValid}
        >
          {children}
          <PaymentInformationFooter
            handleCreateDismiss={handleCreateDismiss}
            show3DSecure={show3DSecure}
            isAddPaymentFetching={isAddPaymentFetching}
            paymentMethod={paymentMethod}
            isFormValid={isFormValid}
            confirmButtonText={confirmButtonText}
          />
        </PaymentInformationForm>
      )}

      {(paymentState === PAYMENT_STATES.UPDATE_PAYMENT_METHOD ||
        paymentState === PAYMENT_STATES.UPDATE_WINBACK_PAYMENT_METHOD) && (
        <PaymentInformationForm
          onSubmit={handleUpdateSubmit}
          billingInfo={billingInfo}
          onChangePaymentType={handleChangePaymentType}
          show3DSecure={show3DSecure}
          setFormPaymentMethod={handlePaymentMethod}
          setIsFormValid={setIsFormValid}
          isWinbackPaymentEdit={
            paymentState === PAYMENT_STATES.UPDATE_WINBACK_PAYMENT_METHOD
          }
        >
          {children}
          <PaymentInformationFooter
            handleCreateDismiss={handleEditDismiss}
            show3DSecure={show3DSecure}
            isAddPaymentFetching={isAddPaymentFetching}
            paymentMethod={paymentMethod}
            isFormValid={isFormValid}
            confirmButtonText={confirmButtonText}
          />
        </PaymentInformationForm>
      )}
    </>
  );
};
