import { ActionTypes, IFetchSkuDetail, IState } from "./types";
import { Value } from "../../components/SearchInput";
import {
  FETCH_CLEAR_FEATURE_DETAILS,
  FETCH_DETAILS,
  FETCH_DETAILS_SUCCESS,
  FETCH_FAILURE,
  FETCH_FEATURE_DETAILS,
  FETCH_FEATURE_DETAILS_SUCCESS,
  FETCH_REQUEST,
  FETCH_SUCCESS,
  FETCH_TRIGGER,
  FILTER_RESET,
  FILTER_TEXT_UPDATE,
  FILTER_UPDATE,
  PAGINATION_UPDATE,
  RESET_ALL,
  SORT_UPDATE,
} from "./constants";
import { TableHeaderSort } from "../../components/Table/components/TableHeader/types";
import { ISKUDetailResponse, ISKUResponse } from "../../services/api/SKU/types";

export const updatePagination = (payload: Partial<IState>): ActionTypes => {
  return {
    type: PAGINATION_UPDATE,
    payload,
  };
};

export const fetchDataTrigger = (): ActionTypes => {
  return {
    type: FETCH_TRIGGER,
  };
};

export const fetchDataRequest = (): ActionTypes => {
  return {
    type: FETCH_REQUEST,
  };
};

export const fetchDataSuccess = (payload: ISKUResponse): ActionTypes => {
  return {
    type: FETCH_SUCCESS,
    payload,
  };
};

export const fetchDataFailure = (payload: any): ActionTypes => {
  return {
    type: FETCH_FAILURE,
    payload,
  };
};

export const updateSort = (payload: TableHeaderSort): ActionTypes => {
  return {
    type: SORT_UPDATE,
    payload,
  };
};

export const updateFilter = (filter: Partial<IState>): ActionTypes => {
  return {
    type: FILTER_UPDATE,
    payload: filter,
  };
};

export const updateTextFilter = (value: Value): ActionTypes => {
  return {
    type: FILTER_TEXT_UPDATE,
    payload: value,
  };
};

export const resetFilter = (): ActionTypes => {
  return {
    type: FILTER_RESET,
  };
};

export const resetAll = (): ActionTypes => {
  return {
    type: RESET_ALL,
  };
};

export const fetchDetailsAction = (payload: IFetchSkuDetail): ActionTypes => {
  return {
    type: FETCH_DETAILS,
    payload,
  };
};

export const fetchDetailsSuccessAction = (
  payload: ISKUDetailResponse,
): ActionTypes => {
  return {
    type: FETCH_DETAILS_SUCCESS,
    payload,
  };
};

export const fetchFeatureDetailsAction = (
  payload: IFetchSkuDetail,
): ActionTypes => {
  return {
    type: FETCH_FEATURE_DETAILS,
    payload,
  };
};

export const fetchClearFeatureDetailsAction = (): ActionTypes => {
  return {
    type: FETCH_CLEAR_FEATURE_DETAILS,
  };
};

export const fetchFeatureDetailsSuccessAction = (
  payload: ISKUDetailResponse,
): ActionTypes => {
  return {
    type: FETCH_FEATURE_DETAILS_SUCCESS,
    payload,
  };
};
