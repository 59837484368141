import { Middleware } from "redux";
import createSagaMiddleware, { Saga } from "redux-saga";

const sagaMiddleware = createSagaMiddleware();

const middlewares: Middleware[] = [sagaMiddleware];

export function runSaga(saga: Saga) {
  sagaMiddleware.run(saga);
}

export default middlewares;
