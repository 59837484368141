import { CURRENCY_DATA, PRIMARY_CURRENCY_LIST } from "./constants";
import { ICurrencyData, ICurrencyDetails } from "./types";

const currencyList: Record<string, ICurrencyData> = {};

const isShowCurrencySymbol = (currency: string): boolean => {
  return !!currencyList[currency].currencySymbol;
};

const isShowCurrencyCode = (currency: string): boolean => {
  if (PRIMARY_CURRENCY_LIST.includes(currency)) {
    return false;
  }

  if (!currencyList[currency].currencySymbol) {
    return true;
  }

  return !currencyList[currency].isUnique;
};

export const getCurrencyDetails = (currency: string): ICurrencyDetails => {
  if (currencyList[currency]) {
    return {
      ...currencyList[currency],
      isShowCurrencySymbol: isShowCurrencySymbol(currency),
      isShowCurrencyCode: isShowCurrencyCode(currency),
    };
  }

  return {
    currency,
    currencySymbol: "",
    decimal: 0,
    isUnique: true,
    isShowCurrencyCode: true,
    isShowCurrencySymbol: false,
  };
};

const isCurrencySymbolUnique = (currency: string) => {
  if (!CURRENCY_DATA[currency].currencySymbol) {
    return true;
  }

  return (
    Object.values(CURRENCY_DATA).filter((currencyItem) => {
      return (
        currencyItem.currencySymbol === CURRENCY_DATA[currency].currencySymbol
      );
    }).length < 2
  );
};

const generateCurrencyList = () => {
  Object.keys(CURRENCY_DATA).forEach((key: string) => {
    currencyList[key] = {
      ...CURRENCY_DATA[key],
      isUnique: isCurrencySymbolUnique(CURRENCY_DATA[key].currency),
    };
  });
};

export const initCurrencyList = () => {
  if (Object.keys(currencyList).length) {
    return;
  }

  generateCurrencyList();
};
