import { SapphireDb, SapphireDbOptions } from "sapphiredb";

import { Socket } from "./socket-factory";

export abstract class SocketBase {
  protected db: SapphireDb | null = null;

  connect(config: SapphireDbOptions) {
    if (this.db) {
      return;
    }
    const _config = {
      useSsl: !(process.env.NODE_ENV === "development"),
      ...config,
    };

    this.db = Socket.create(_config);
  }

  disconnect() {
    if (!this.db) {
      throw new Error("Please connect to DB");
    }

    this.db.reset();
    this.db = null;
  }

  setAuthToken(authToken: string) {
    if (!this.db) {
      throw new Error("Please connect to DB");
    }

    this.db.setAuthToken(authToken);
  }

  isConnected() {
    return !!this.db;
  }
}
