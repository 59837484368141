import { all, fork, takeEvery } from "@redux-saga/core/effects";
import { USER_EXPIRING } from "redux-oidc";

import { RESPONSE_ERROR_KEY } from "../../utils/common/contants";

export function gotToErrorPage() {
  window.location.replace(
    `/error?errorCode=${RESPONSE_ERROR_KEY.SessionHasExpired}`,
  );
}

export function* watchUserExpired() {
  yield takeEvery([USER_EXPIRING], gotToErrorPage);
}

export function* oidcSaga() {
  yield all([fork(watchUserExpired)]);
}
