import React, { FC } from "react";
import { LoaderProps } from "./types";

import "./styles.scss";

export const CommonContentLoader: FC<LoaderProps> = ({ className }) => {
  return (
    <>
      <div className="ams-layout__content-title-loader loading" />
      <div className="ams-layout__content-loader loading">
        <div className="ams-layout__content-loader-inside">
          <div className="ams-layout__content-loader-title" />
          <div className="ams-layout__content-loader-content">
            <div>
              <div className="ams-layout__content-loader-left" />
              <div className="ams-layout__content-loader-right" />
            </div>
            <div>
              <div className="ams-layout__content-loader-left" />
              <div className="ams-layout__content-loader-right" />
            </div>
            <div>
              <div className="ams-layout__content-loader-left" />
              <div className="ams-layout__content-loader-right" />
            </div>
          </div>
        </div>
      </div>
      <div className="ams-layout__content-loader loading">
        <div className="ams-layout__content-loader-inside">
          <div className="ams-layout__content-loader-title" />
          <div className="ams-layout__content-loader-content">
            <div>
              <div className="ams-layout__content-loader-left" />
              <div className="ams-layout__content-loader-right" />
            </div>
            <div>
              <div className="ams-layout__content-loader-left" />
              <div className="ams-layout__content-loader-right" />
            </div>
            <div>
              <div className="ams-layout__content-loader-left" />
              <div className="ams-layout__content-loader-right" />
            </div>
          </div>
        </div>
      </div>
      <div className="ams-layout__content-loader loading">
        <div className="ams-layout__content-loader-inside">
          <div className="ams-layout__content-loader-title" />
          <div className="ams-layout__content-loader-content">
            <div>
              <div className="ams-layout__content-loader-left" />
              <div className="ams-layout__content-loader-right" />
            </div>
            <div>
              <div className="ams-layout__content-loader-left" />
              <div className="ams-layout__content-loader-right" />
            </div>
            <div>
              <div className="ams-layout__content-loader-left" />
              <div className="ams-layout__content-loader-right" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
