import Oidc from "oidc-client";
import { useSelector } from "react-redux";
import { createUserManager } from "redux-oidc";
import { IOidcSettings } from "../../types/common";

let userManager: Oidc.UserManager;

function userIsAuthenticated(user?: Oidc.User | null): boolean {
  return Boolean(user && !user.expired && user.id_token);
}

export async function getUserProfile() {
  const user = await userManager.getUser();
  return user?.profile;
}

export async function getIdToken(): Promise<string | null> {
  const user = await userManager.getUser();

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return userIsAuthenticated(user) ? user!.id_token : null;
}

export async function getIdTokenSubject(): Promise<string | null> {
  const user = await userManager.getUser();

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return userIsAuthenticated(user) ? user!.profile.sub : null;
}

export async function isAuthenticated(): Promise<boolean> {
  const user = await userManager.getUser();

  return userIsAuthenticated(user);
}

export function useIdToken(): string | null {
  return useSelector((state: any) => {
    const user = state.oidc ? state.oidc.user : undefined;

    return userIsAuthenticated(user) ? user.id_token : null;
  });
}

export function useIsAuthenticated(): boolean {
  return useSelector((state: any) => {
    const user = state.oidc ? state.oidc.user : undefined;

    return userIsAuthenticated(user);
  });
}

export function useUserManager(): Oidc.UserManager {
  return userManager;
}

export function configureAppUserManager(settings?: IOidcSettings) {
  const baseUrl = window.location.origin;

  let authority, clientId, clientSecret;

  if (settings) {
    authority = settings.authority;
    clientId = settings.client_id;
    clientSecret = settings.client_secret;
    // unfortunately we have to cheat to set these
    if (settings.scope) {
      (userManager.settings as any)._scope = settings.scope;
    }

    if (settings.load_user_info === true || settings.load_user_info === false) {
      (userManager.settings as any)._loadUserInfo = settings.load_user_info;
    }

    if (settings.prompt_consent === false) {
      (userManager.settings as any)._prompt = undefined;
    }
  } else {
    authority = (process.env.REACT_APP_OIDC_BASE_URL || baseUrl) + "/oidc";
    clientId = "ph-public";

    // This is okay per
    //   <https://tools.ietf.org/html/draft-ietf-oauth-browser-based-apps-04#section-9>
    // as we are not treating the secret as proof of identity. Also, `oidc-client`
    // uses PKCE for additional assurance.
    clientSecret = "ph-public-secret";
  }

  userManager.settings.authority = authority;

  // eslint-disable-next-line @typescript-eslint/camelcase
  userManager.settings.client_id = clientId;

  // unfortunately we have to cheat to set this
  // eslint-disable-next-line @typescript-eslint/camelcase
  (userManager.settings as any)._client_secret = clientSecret;
}

export function createAppUserManager(): Oidc.UserManager {
  if (!userManager) {
    const baseUrl = window.location.origin;
    const userManagerConfig = {
      /* eslint-disable @typescript-eslint/camelcase */
      redirect_uri: `${baseUrl}/oidc-signin-callback`,
      post_logout_redirect_uri: `${baseUrl}/oidc-signout-callback`,
      silent_redirect_uri: `${baseUrl}/oidc-silent-renew`,

      response_type: "code",
      prompt: "consent",
      scope: "openid profile email groups",

      automaticSilentRenew: true,
      filterProtocolClaims: true,
      loadUserInfo: false,
      /* eslint-enable @typescript-eslint/camelcase */
    };

    userManager = createUserManager(userManagerConfig);

    if (process.env.NODE_ENV === "development") {
      Oidc.Log.logger = console;
      Oidc.Log.level = Oidc.Log.DEBUG;

      Object.defineProperty(window, "userManager", {
        get() {
          return userManager;
        },
      });
    }
  }

  return userManager;
}
