import { API } from "../api";
import { url } from "../constants";
import {
  IOptionsRequestData,
  IOptionsResponse,
  IOptionsResponseData,
  IUpdateOptionsRequestData,
} from "./types";
import { getETagCode, validateArgs } from "../utils";

export class AccountAPIService {
  constructor(private api: API) {}

  public async getOptions({
    customerId,
    principalId,
    serviceCode,
  }: IOptionsRequestData): Promise<IOptionsResponse> {
    validateArgs(customerId, principalId, serviceCode);
    const response = await this.api.get<IOptionsResponseData>(
      url.account.options
        .replace(":customerId", customerId)
        .replace(":principalId", principalId)
        .replace(":serviceCode", serviceCode),
    );

    return {
      data: response.data,
      headers: {
        eTag: response.headers.etag,
      },
    };
  }

  public async updateOptions({
    customerId,
    principalId,
    serviceCode,
    body,
    eTagHeader,
  }: IUpdateOptionsRequestData) {
    validateArgs(customerId, principalId, serviceCode);
    const etag = getETagCode(eTagHeader);

    if (etag) {
      this.api.addHeader("If-Match", etag);
    } else {
      this.api.addHeader("If-None-Match", "*");
    }

    const { data } = await this.api.put<IOptionsResponseData>(
      url.account.options
        .replace(":customerId", customerId)
        .replace(":principalId", principalId)
        .replace(":serviceCode", serviceCode),
      body,
    );

    if (etag) {
      this.api.removeHeader("If-Match");
    } else {
      this.api.removeHeader("If-None-Match");
    }
    return data;
  }
}
