import { all, call, fork, put, takeEvery } from "redux-saga/effects";

import offeringAPIService from "../../services/api/offering";
import skuAPIService from "../../services/api/SKU";
import { FETCH_PLANS_REQUEST } from "./constants";
import { IFetchPlansRequestAction } from "./types";
import { ISKU } from "../skuMapping";
import { fetchPlansFailure, fetchPlansSuccess } from "./actions";
import { getOfferingDetailsList } from "../../utils/common";
import { ISKUDetails } from "../../services/api/offering/types";

export function* getPlans({ payload }: IFetchPlansRequestAction) {
  try {
    const offerings = yield call(
      [offeringAPIService, offeringAPIService.getDetails],
      payload,
    );

    const skus = yield all(
      offerings.items.map((item: ISKUDetails) => {
        return call([skuAPIService, skuAPIService.get], item.sku_id);
      }),
    );
    const formattedPlans = skus
      .filter((sku: ISKU) => {
        return sku.licenses && sku.licenses > 1;
      })
      .map((sku: ISKU) => {
        const offering = getOfferingDetailsList(offerings).find(
          (item: ISKUDetails) => {
            return item.sku_id === sku.id;
          },
        );

        return {
          ...offering,
          licenses: sku.licenses,
        };
      });

    yield put(fetchPlansSuccess(formattedPlans));
  } catch (error) {
    yield put(fetchPlansFailure(error));
  }
}

export function* watchFetchPlans() {
  yield takeEvery(FETCH_PLANS_REQUEST, getPlans);
}

export function* corporateBuySaga() {
  yield fork(watchFetchPlans);
}
