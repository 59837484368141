import React, { FC } from "react";
import classnames from "classnames";

import { LabelProps } from "./types";

import "./styles.scss";

export const Label: FC<LabelProps> = ({ className, children }) => {
  return (
    <div className={classnames("ams-plan-label", className)}>{children}</div>
  );
};
