import React, { FC } from "react";
import classnames from "classnames";

import { LoaderProps } from "./types";

import "./styles.scss";

export const LoaderSpiner: FC<LoaderProps> = ({ className }) => {
  return <div className={classnames("ams-loader-spinner", className)} />;
};
