import loadable from "@loadable/component";

import { RouteVisibility } from "./types";
import { ACTION, SUBJECT } from "../../constants/permissions";
import { createRouteConfig } from "./utils";
import PrincipalLayout from "../../layouts/PrincipalLayout";

export const principalDatabaseViewProfile = createRouteConfig({
  path: "/principal-database/:id/profile",
  layout: PrincipalLayout,
  visibility: RouteVisibility.Private,
  component: loadable(() => {
    return import(
      /* webpackChunkName: "principal-database-view-profile" */
      /* webpackPrefetch: true */
      "../../pages/PrincipalDBView"
    );
  }),
  isRedirectAllowed({ ability }) {
    return ability.can(ACTION.READ, SUBJECT.USER);
  },
});

export const principalViewSubscription = createRouteConfig({
  path: "/principal-database/:id/subscriptions",
  visibility: RouteVisibility.Private,
  layout: PrincipalLayout,
  component: loadable(() => {
    return import(
      /* webpackChunkName: "principal-database-view-subscription" */
      /* webpackPrefetch: true */
      "../../pages/Subscriptions"
    );
  }),
  isRedirectAllowed({ ability }) {
    return ability.can(ACTION.READ, SUBJECT.SUBSCRIPTION);
  },
});
