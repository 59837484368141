import React, { FC } from "react";
import classnames from "classnames";

import { BannerProps } from "./types";
import Icon from "../Icon";
import { IconName } from "../Icon/types";

import "./styles.scss";

export const Banner: FC<BannerProps> = ({ type, children }) => {
  return (
    <div className={classnames("ams-banner", `ams-banner--${type}`)}>
      <Icon>{`${type}-inverse` as IconName}</Icon>
      {children}
    </div>
  );
};
