import {
  APPLE_APP_STORE,
  GOOGLE_PLAY_STORE,
  RECURLY,
} from "../../constants/spCode";
import { IExternalPaymentData } from "../../types/common";

export const PROVIDERS = [RECURLY, APPLE_APP_STORE, GOOGLE_PLAY_STORE];

export const RECURLY_INPUT_STYLE: any = {
  fontWeight: "300",
  transition: "background-color 50000s ease-in-out 0s",
};

export const DEFAULT_CURRENCY = "USD";

export const EXTERNAL_SUBSCRIPTION_DETAILS: Record<
  string,
  IExternalPaymentData
> = {
  [GOOGLE_PLAY_STORE]: {
    company: "Google",
    supportLInk: "https://support.google.com/googleplay/answer/7018481",
    label: "Google Play",
  },
  [APPLE_APP_STORE]: {
    company: "Apple",
    supportLInk: "https://support.apple.com/en-us/HT202039",
    label: "Apple Store",
  },
};

export const validationRules = {
  number: {
    size: {
      uint: 4294967295,
    },
    pattern: {
      noLeadingZeroInt: /^(?:[1-9][0-9]*|0)$/,
      // eslint-disable-next-line prefer-named-capture-group
      onlyNumber: /^[0-9]\d*(\.\d+)?$/,
      wholeNumber: /^[0-9]+$/,
    },
  },
  // eslint-disable-next-line @typescript-eslint/camelcase
  capitalizeLetters_AndNumbers: /^[A-Z0-9][A-Z0-9_]+[A-Z0-9]$/,
};

export const SERVICE_CODE = {
  VPN: "VPN",
  USENET: "USENET",
};

export const FEATURE_CODE = {
  FEATURE_NATFIREWALL: "FEATURE_NATFIREWALL",
};

export const RESPONSE_ERROR_KEY = {
  canceledSubscriptionCanNotBeChanged: "CanceledSubscriptionCanNotBeChanged",
  PrincipalWithEmailAlreadyExists: "PrincipalWithEmailAlreadyExists",
  PrincipalWithUsernameAlreadyExists: "usernameAlreadyExists",
  Unauthorized: "Unauthorized",
  SessionHasExpired: "SessionHasExpired",
  Illegal: "ILLEGAL_CHARACTERS",
};

export const RESPONSE_ERROR_PROBLEM = {
  EmailValidator: "EmailValidator",
  AlreadyExists: "ALREADY_EXISTS",
  InvalidValue: "INVALID_VALUE",
  PasswordMismatch: "PASSWORD_MISMATCH",
  Illegal: "ILLEGAL_CHARACTERS",
  OwnerAssigned: "OWNER_ALREADY_ASSIGNED",
  OutOfRange: "OUT_OF_RANGE",
};

export const COUPON_TYPE = {
  couponCode: "COUPON_CODE",
};

export const PLAN_DESCRIPTIONS = {
  all: [
    "connectionsPerAccount",
    "noLogFullyOwned",
    "globalServerLocations",
    "accessToStreamingLibraries",
  ],
  treeYear: ["bestValueOfPlans"],
};

const MILLISECONDS_IN_SECOND = 1000;
const SECONDS_IN_MINUTE = 60;
const MINUTE_IN_HOUR = 60;
const HOURS_IN_DAY = 24;

export const MILLISECOND_IN_DAY =
  HOURS_IN_DAY * MINUTE_IN_HOUR * SECONDS_IN_MINUTE * MILLISECONDS_IN_SECOND;
export const MILLISECOND_IN_MINUTE = SECONDS_IN_MINUTE * MILLISECONDS_IN_SECOND;
export const BYTES_IN_GB = 1000000000;

export const PAYMENT_STATES = {
  ADD_PAYMENT_METHOD: "AddPaymentMethod",
  UPDATE_PAYMENT_METHOD: "UpdatePaymentMethod",
  UPDATE_WINBACK_PAYMENT_METHOD: "UpdateWinbackPaymentMethod",
};

export const SUBSCRIPTION_STATUS = {
  pendingPayment: "pendingPayment",
  paymentFailure: "paymentFailure",
  active: "active",
  notActive: "notActive",
  pendingCancellation: "pendingCancellation",
  cancelled: "cancelled", // it is mean that subscription is not active and expiration date has come
  paused: "paused",
  subscriptionTerminated: "subscriptionTerminated",
  onHold: "onHold",
  expired: "expired",
};
