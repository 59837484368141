import { IAdminAction } from "../../services/api/user/types";
import {
  LOCK_CUSTOMER,
  LOCK_PRINCIPAL,
  UNLOCK_CUSTOMER,
  UNLOCK_PRINCIPAL,
} from "../../constants/common";

export const ACCOUNT_ACTIONS = "accountActions";
export const ACTIONS: IAdminAction[] = [
  {
    label: "adminHeaderDropdown.accountActions",
    value: ACCOUNT_ACTIONS,
    getIsEnabled: () => {
      return false;
    },
  },
  {
    label: "adminHeaderDropdown.lockCustomerAccount",
    value: LOCK_CUSTOMER,
    getIsEnabled: (customerStatus) => {
      return customerStatus === UNLOCK_CUSTOMER;
    },
  },
  {
    label: "adminHeaderDropdown.unlockCustomerAccount",
    value: UNLOCK_CUSTOMER,
    getIsEnabled: (customerStatus) => {
      return customerStatus === LOCK_CUSTOMER;
    },
  },
  {
    label: "adminHeaderDropdown.lockPrincipalAccount",
    value: LOCK_PRINCIPAL,
    getIsEnabled: (customerStatus, principalStatus, isCorporate) => {
      return (
        isCorporate &&
        customerStatus === UNLOCK_CUSTOMER &&
        principalStatus === UNLOCK_PRINCIPAL
      );
    },
  },
  {
    label: "adminHeaderDropdown.unlockPrincipalAccount",
    value: UNLOCK_PRINCIPAL,
    getIsEnabled: (customerStatus, principalStatus, isCorporate) => {
      return (
        isCorporate &&
        customerStatus === UNLOCK_CUSTOMER &&
        principalStatus === LOCK_PRINCIPAL
      );
    },
  },
];
