import React, { FC } from "react";
import classnames from "classnames";

import { TitleProps } from "./types";

import "./styles.scss";

export const Title: FC<TitleProps> = ({ className, children }) => {
  return (
    <div className={classnames("ams-plan-title", className)}>{children}</div>
  );
};
