import { Action, Subject } from "../models/permissions";

export const SUBJECT: Record<string, Subject> = {
  USER: "user",
  SUBSCRIPTION: "subscription",
  PRODUCT: "product",
  BILLING: "billing",
  TECHNICAL: "technical",
  MANAGE: "manage",
  OWNER: "owner",
  ADMIN: "admin",
};

export const ACTION: Record<string, Action> = {
  READ: "read",
  WRITE: "write",
};

export const PERMISSION_ALIASES: Record<string, string> = {
  "permission:billing": `${ACTION.WRITE}:${SUBJECT.BILLING}`,
  "permission:manage": `${ACTION.WRITE}:${SUBJECT.MANAGE}`,
  "permission:technical": `${ACTION.READ}:${SUBJECT.TECHNICAL}`,
  "permission:owner": `${ACTION.WRITE}:${SUBJECT.OWNER}`,
  "permission:admin": `${ACTION.WRITE}:${SUBJECT.ADMIN}`,
};
