import { AxiosError } from "axios";

import { ActionTypes, fetchInvoicePayload } from "./types";
import {
  FETCHING_INVOICES,
  FETCH_INVOICES_ERROR,
  FETCH_INVOICES_SUCCESS,
  FETCH_INVOICE_TRIGGER,
} from "./constants";
import { Invoice } from "../../types/common";

export const fetchInvoiceTrigger = (
  payload: fetchInvoicePayload,
): ActionTypes => {
  return {
    type: FETCH_INVOICE_TRIGGER,
    payload,
  };
};

export const fetchingInvoices = (payload: fetchInvoicePayload): ActionTypes => {
  return {
    type: FETCHING_INVOICES,
    payload,
  };
};

export const fetchInvoicesSuccess = (invoices: Invoice[]): ActionTypes => {
  return {
    type: FETCH_INVOICES_SUCCESS,
    payload: invoices,
  };
};

export const fetchInvoicesError = (error: AxiosError): ActionTypes => {
  return {
    type: FETCH_INVOICES_ERROR,
    payload: error,
  };
};
