import { IStanding } from "../../services/api/standing/types";

export const lockedStanding = (
  entityType: string,
  standingList: IStanding[],
) => {
  return standingList.find(
    (currentStanding: { source_kind: string; entity_kind: string }) => {
      return (
        currentStanding.source_kind === "EmployeeLock" &&
        currentStanding.entity_kind === entityType
      );
    },
  );
};
