import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

import { Modal } from "../Modal/Modal";
import { ModalHeader } from "../Modal/ModalHeader";
import { ModalContent } from "../Modal/ModalContent";
import { ModalFooter } from "../Modal/ModalFooter";
import { Button } from "../Button/Button";
import { ButtonPrimary } from "../ButtonPrimary/ButtonPrimary";
import { IConfirmModal } from "./types";

import "./styles.scss";

export const ConfirmModal: FC<IConfirmModal> = ({
  title,
  message,
  cancelBtnText,
  submitBtnText,
  isLoading,
  isOpen,
  onSubmit,
  onDismiss,
  className,
  headerClassName,
  footerClassName,
  submitBtnClassName,
}) => {
  const { t } = useTranslation();

  return (
    <Modal id="confirm-modal" className={className} isOpen={isOpen}>
      <ModalHeader className={headerClassName}>{title}</ModalHeader>

      <ModalContent className="ams-confirm-modal__content">
        {message}
      </ModalContent>

      <ModalFooter className={footerClassName}>
        <Button
          onClick={onDismiss}
          className="ams-modal__action"
          id="cancel-button"
        >
          {cancelBtnText ? cancelBtnText : t("cancel")}
        </Button>

        {submitBtnText && (
          <ButtonPrimary
            onClick={onSubmit}
            className={classNames("ams-modal__action", submitBtnClassName)}
            id="submit-button"
            isLoading={isLoading}
          >
            {submitBtnText}
          </ButtonPrimary>
        )}
      </ModalFooter>
    </Modal>
  );
};
