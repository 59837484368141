import React from "react";
import { Trans } from "react-i18next";
import { Link } from "react-router-dom";

import { paymentInformation } from "../../config/router/routes";

export const PaymentIssueMessage = () => {
  return (
    <div>
      {/* without this wrapper the whitespaces around the link are disappeared
        and adaptivity is incorrect */}
      <Trans i18nKey="paymentFailure">
        There was an issue with your payment. Please check
        <Link className="ams-payment-banner__link" to={paymentInformation.path}>
          your payment details
        </Link>
        and try again.
      </Trans>
    </div>
  );
};
