import {
  all,
  call,
  debounce,
  fork,
  put,
  select,
  takeEvery,
} from "@redux-saga/core/effects";

import {
  FETCH_LICENSES_REQUEST,
  FETCH_REQUEST,
  PAGINATION_UPDATE,
  RESET_FILTER,
  SORT_UPDATE,
  UPDATE_FILTER,
  UPDATE_FILTER_SEARCH,
} from "./constants";
import {
  fetchDataFailure,
  fetchDataSuccess,
  fetchLicensesFailure,
  fetchLicensesSuccess,
} from "./actions";
import userService from "../../services/api/user";
import { selectCustomerId, selectPayload } from "./selectors";
import { IFetchLicenseRequest } from "./types";
import { selectPrincipalData } from "../user/selectors";
import { ICustomerPrincipalListResponse } from "../../services/api/user/types";

function* fetchData() {
  const customerId = yield select(selectCustomerId);
  const params = yield select(selectPayload);

  try {
    const data: ICustomerPrincipalListResponse = yield call(
      [userService, userService.getCustomerPrincipals],
      customerId,
      params,
    );
    yield put(fetchDataSuccess(data));
  } catch (error) {
    yield put(fetchDataFailure(error));
  }
}

function* fetchLicense({
  payload: activeSubscriptionId,
}: IFetchLicenseRequest) {
  const principal = yield select(selectPrincipalData);

  try {
    if (activeSubscriptionId) {
      const data = yield call([userService, userService.getLicense], {
        customerId: principal.customer_id,
        subscriptionId: activeSubscriptionId,
      });
      yield put(fetchLicensesSuccess(data));
    }
  } catch (error) {
    yield put(fetchLicensesFailure(error));
  }
}

function* watchFetch() {
  yield takeEvery(
    [
      FETCH_REQUEST,
      UPDATE_FILTER,
      RESET_FILTER,
      PAGINATION_UPDATE,
      SORT_UPDATE,
    ],
    fetchData,
  );
}

function* watchFetchLicense() {
  yield takeEvery([FETCH_LICENSES_REQUEST], fetchLicense);
}

export function* debounceSearch() {
  yield debounce(
    500,
    (action: any) => {
      return action.type === UPDATE_FILTER_SEARCH && action.payload.valid;
    },
    fetchData,
  );
}

export function* manageUsersSaga() {
  yield all([fork(watchFetch), fork(watchFetchLicense), fork(debounceSearch)]);
}
