import React, { FC } from "react";
import classnames from "classnames";

import { LabelProps } from "./types";

import "./styles.scss";

export const Label: FC<LabelProps> = ({ className, children, ...props }) => {
  return (
    <label className={classnames("ams-label", className)} {...props}>
      {children}
    </label>
  );
};
