import React, { FC } from "react";
import classnames from "classnames";

import { FormGroupProps } from "./types";

import "./styles.scss";

export const FormGroup: FC<FormGroupProps> = ({
  className,
  children,
  ...props
}) => {
  return (
    <div className={classnames("ams-form-group", className)} {...props}>
      {children}
    </div>
  );
};
