import { createSelector } from "reselect";

import { IRootState } from "../reducers";
import { IState } from "./types";
import { IStanding } from "../../services/socket/standing/types";
import { selectCurrentSubscriptionDetail } from "../subscriptions";

export const selectStandingState = (state: IRootState): IState => {
  return state.standing;
};

export const selectStandingData = createSelector(
  [selectStandingState],
  (standingState: IState): IStanding[] => {
    return standingState.data;
  },
);

export const selectActiveSubscriptionStandings = createSelector(
  [selectStandingData, selectCurrentSubscriptionDetail],
  (standingList, activeSubscription): IStanding[] => {
    if (!activeSubscription) {
      return [];
    }

    return standingList.filter((item: IStanding) => {
      return item.entityId === activeSubscription.subscription_id;
    });
  },
);

export const selectPaymentFailureStanding = createSelector(
  [selectStandingData, selectCurrentSubscriptionDetail],
  (data: IStanding[], activeSubscription) => {
    if (!activeSubscription) {
      return undefined;
    }

    return data.find((item) => {
      return (
        item.entityId === activeSubscription.subscription_id &&
        item.entityKind === "Subscription" &&
        item.sourceKind === "PaymentFailure"
      );
    });
  },
);
