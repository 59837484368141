import { createSelector } from "reselect";

import { IRootState } from "../reducers";
import { IState } from "./types";
import { Options } from "./types";

export const selectAccountState = (state: IRootState): IState => {
  return state.account;
};

export const selectOptions = createSelector(
  [selectAccountState],
  (AccountState: IState): Options => {
    return AccountState.options;
  },
);

export const selectOptionsData = createSelector(
  [selectOptions],
  (options: Options) => {
    return options.data;
  },
);

export const selectOptionsDataFirewall = createSelector(
  [selectOptions],
  (options: Options) => {
    if (
      !options.data?.data?.options ||
      !options.data?.data?.options?.hasOwnProperty("nat_firewall")
    ) {
      return options.data?.data?.schema?.properties?.nat_firewall?.default;
    }
    return options.data?.data?.options?.nat_firewall;
  },
);

export const selectOptionsEntitlements = createSelector(
  [selectOptions],
  (options: Options) => {
    return options.data?.data?.schema.properties.nat_firewall["x-requirements"]
      .entitlements;
  },
);

export const selectOptionsIsFetching = createSelector(
  [selectOptions],
  (options: Options) => {
    return options.isFetching;
  },
);

export const selectOptionsLimit = createSelector(
  [selectOptionsData],
  (options) => {
    return options?.data.options.max_visible_articles || 0;
  },
);

export const selectETagHeader = createSelector(
  [selectOptionsData],
  (options) => {
    return options?.headers.eTag;
  },
);
