import { all, call, fork, put, select, takeEvery } from "redux-saga/effects";

import {
  fetchOptionsFailure,
  fetchOptionsRequest,
  fetchOptionsSuccess,
  updateOptionsFailure,
  updateOptionsRequest,
  updateOptionsSuccess,
} from "./actions";
import { FETCH_OPTIONS_TRIGGER, UPDATE_OPTIONS_TRIGGER } from "./constants";
import accountAPIService from "../../services/api/account";
import { selectCustomerId } from "../oidc";
import { getIdTokenSubject } from "../../services/auth";
import {
  IFetchOptionsTriggerAction,
  IUpdateOptionsTriggerAction,
} from "./types";
import { selectETagHeader } from "./selectors";

export function* fetchOptions({
  payload: serviceCode,
}: IFetchOptionsTriggerAction) {
  try {
    yield put(fetchOptionsRequest());
    const params = {
      customerId: yield select(selectCustomerId),
      principalId: yield call(getIdTokenSubject),
      serviceCode,
    };
    const response = yield call(
      [accountAPIService, accountAPIService.getOptions],
      params,
    );
    yield put(fetchOptionsSuccess(response));
  } catch (error) {
    yield put(fetchOptionsFailure(error));
  }
}

export function* updateOptions({
  payload: { serviceCode, limit, firewall },
  onSuccess,
  onError,
}: IUpdateOptionsTriggerAction) {
  try {
    yield put(updateOptionsRequest());

    const params = {
      customerId: yield select(selectCustomerId),
      principalId: yield call(getIdTokenSubject),
      serviceCode: serviceCode,
      body: {
        // eslint-disable-next-line @typescript-eslint/camelcase
        max_visible_articles: limit,
        // eslint-disable-next-line @typescript-eslint/camelcase
        nat_firewall: firewall,
      },
      eTagHeader: yield select(selectETagHeader),
    };

    yield call([accountAPIService, accountAPIService.updateOptions], params);

    const response = yield call(
      [accountAPIService, accountAPIService.getOptions],
      params,
    );

    yield put(updateOptionsSuccess(response));
    yield call(onSuccess);
  } catch (error) {
    yield put(updateOptionsFailure(error));
    yield call(onError, error);
  }
}

export function* watchFetchOptions() {
  yield takeEvery(FETCH_OPTIONS_TRIGGER, fetchOptions);
}

export function* watchUpdateOptions() {
  yield takeEvery(UPDATE_OPTIONS_TRIGGER, updateOptions);
}

export function* accountSaga() {
  yield all([fork(watchFetchOptions), fork(watchUpdateOptions)]);
}
