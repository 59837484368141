import { ICurrency } from "./types";

export const PRIMARY_CURRENCY_LIST = ["USD", "GBP"];

export const CURRENCY_DATA: Record<string, ICurrency> = {
  ARS: { currency: "ARS", currencySymbol: "$", decimal: 2 },
  AUD: { currency: "AUD", currencySymbol: "$", decimal: 2 },
  CAD: { currency: "CAD", currencySymbol: "$", decimal: 2 },
  CLP: { currency: "CLP", currencySymbol: "$", decimal: 0 },
  COP: { currency: "COP", currencySymbol: "$", decimal: 2 },
  HKD: { currency: "HKD", currencySymbol: "$", decimal: 2 },
  MXN: { currency: "MXN", currencySymbol: "$", decimal: 2 },
  NZD: { currency: "NZD", currencySymbol: "$", decimal: 2 },
  SGD: { currency: "SGD", currencySymbol: "$", decimal: 2 },
  USD: { currency: "USD", currencySymbol: "$", decimal: 2 },
  BBD: { currency: "BBD", currencySymbol: "$", decimal: 2 },
  BMD: { currency: "BMD", currencySymbol: "$", decimal: 2 },
  BND: { currency: "BND", currencySymbol: "$", decimal: 2 },
  BSD: { currency: "BSD", currencySymbol: "$", decimal: 2 },
  BZD: { currency: "BZD", currencySymbol: "$", decimal: 2 },
  CUP: { currency: "CUP", currencySymbol: "$", decimal: 2 },
  CVE: { currency: "CVE", currencySymbol: "$", decimal: 2 },
  DOP: { currency: "DOP", currencySymbol: "$", decimal: 2 },
  FJD: { currency: "FJD", currencySymbol: "$", decimal: 2 },
  GYD: { currency: "GYD", currencySymbol: "$", decimal: 2 },
  JMD: { currency: "JMD", currencySymbol: "$", decimal: 2 },
  KYD: { currency: "KYD", currencySymbol: "$", decimal: 2 },
  LRD: { currency: "LRD", currencySymbol: "$", decimal: 2 },
  NAD: { currency: "NAD", currencySymbol: "$", decimal: 2 },
  SBD: { currency: "SBD", currencySymbol: "$", decimal: 2 },
  SRD: { currency: "SRD", currencySymbol: "$", decimal: 2 },
  SVC: { currency: "SVC", currencySymbol: "$", decimal: 2 },
  TTD: { currency: "TTD", currencySymbol: "$", decimal: 2 },
  TWD: { currency: "TWD", currencySymbol: "$", decimal: 2 },
  UYU: { currency: "UYU", currencySymbol: "$", decimal: 2 },
  XCD: { currency: "XCD", currencySymbol: "$", decimal: 2 },
  ZWL: { currency: "ZWL", currencySymbol: "$", decimal: 2 },
  EGP: { currency: "EGP", currencySymbol: "£", decimal: 2 },
  FKP: { currency: "FKP", currencySymbol: "£", decimal: 2 },
  GBP: { currency: "GBP", currencySymbol: "£", decimal: 2 },
  GIP: { currency: "GIP", currencySymbol: "£", decimal: 2 },
  LBP: { currency: "LBP", currencySymbol: "£", decimal: 2 },
  SDG: { currency: "SDG", currencySymbol: "£", decimal: 2 },
  SHP: { currency: "SHP", currencySymbol: "£", decimal: 2 },
  SYP: { currency: "SYP", currencySymbol: "£", decimal: 2 },
  BIF: { currency: "BIF", currencySymbol: "F", decimal: 0 },
  CDF: { currency: "CDF", currencySymbol: "F", decimal: 2 },
  CHF: { currency: "CHF", currencySymbol: "F", decimal: 2 },
  DJF: { currency: "DJF", currencySymbol: "F", decimal: 0 },
  GNF: { currency: "GNF", currencySymbol: "F", decimal: 0 },
  RWF: { currency: "RWF", currencySymbol: "₣", decimal: 0 },
  XAF: { currency: "XAF", currencySymbol: "F", decimal: 0 },
  XPF: { currency: "XPF", currencySymbol: "F", decimal: 0 },
  LKR: { currency: "LKR", currencySymbol: "Rs", decimal: 2 },
  MUR: { currency: "MUR", currencySymbol: "Rs", decimal: 2 },
  NPR: { currency: "NPR", currencySymbol: "Rs", decimal: 2 },
  PKR: { currency: "PKR", currencySymbol: "Rs", decimal: 2 },
  SCR: { currency: "SCR", currencySymbol: "Rs", decimal: 2 },
  SEK: { currency: "SEK", currencySymbol: "Kr", decimal: 2 },
  ISK: { currency: "ISK", currencySymbol: "Kr", decimal: 2 },
  DKK: { currency: "DKK", currencySymbol: "Kr", decimal: 2 },
  NOK: { currency: "NOK", currencySymbol: "Kr", decimal: 2 },
  KES: { currency: "KES", currencySymbol: "Sh", decimal: 2 },
  SOS: { currency: "SOS", currencySymbol: "Sh", decimal: 2 },
  TZS: { currency: "TZS", currencySymbol: "Sh", decimal: 2 },
  UGX: { currency: "UGX", currencySymbol: "Sh", decimal: 2 },
  KPW: { currency: "KPW", currencySymbol: "₩", decimal: 2 },
  KRW: { currency: "KRW", currencySymbol: "₩", decimal: 0 },
  BRL: { currency: "BRL", currencySymbol: "R$", decimal: 2 },
  CNY: { currency: "CNY", currencySymbol: "¥", decimal: 2 },
  HRK: { currency: "HRK", currencySymbol: "Kn", decimal: 2 },
  CZK: { currency: "CZK", currencySymbol: "Kč", decimal: 2 },
  EUR: { currency: "EUR", currencySymbol: "€", decimal: 2 },
  JPY: { currency: "JPY", currencySymbol: "¥", decimal: 0 },
  HUF: { currency: "HUF", currencySymbol: "Ft", decimal: 2 },
  INR: { currency: "INR", currencySymbol: "₹", decimal: 2 },
  ILS: { currency: "ILS", currencySymbol: "₪", decimal: 2 },
  PLN: { currency: "PLN", currencySymbol: "zł", decimal: 2 },
  RUB: { currency: "RUB", currencySymbol: "₽", decimal: 2 },
  ZAR: { currency: "ZAR", currencySymbol: "R", decimal: 2 },
  THB: { currency: "THB", currencySymbol: "฿", decimal: 2 },
  VEF: { currency: "VEF", currencySymbol: "Bs", decimal: 2 },
  AED: { currency: "AED", currencySymbol: "د.إ", decimal: 2 },
  AFN: { currency: "AFN", currencySymbol: "؋", decimal: 2 },
  ALL: { currency: "ALL", currencySymbol: "L", decimal: 2 },
  AMD: { currency: "AMD", currencySymbol: "֏", decimal: 2 },
  AOA: { currency: "AOA", currencySymbol: "Kz", decimal: 2 },
  AWG: { currency: "AWG", currencySymbol: "ƒ", decimal: 2 },
  AZN: { currency: "AZN", currencySymbol: "₼", decimal: 2 },
  BAM: { currency: "BAM", currencySymbol: "KM", decimal: 2 },
  BDT: { currency: "BDT", currencySymbol: "৳", decimal: 2 },
  BGN: { currency: "BGN", currencySymbol: "", decimal: 2 },
  BOB: { currency: "BOB", currencySymbol: "Bs.", decimal: 2 },
  BTN: { currency: "BTN", currencySymbol: "", decimal: 2 },
  BWP: { currency: "BWP", currencySymbol: "P", decimal: 2 },
  CRC: { currency: "CRC", currencySymbol: "₡", decimal: 2 },
  DZD: { currency: "DZD", currencySymbol: "دج", decimal: 2 },
  ERN: { currency: "ERN", currencySymbol: "Nfk", decimal: 2 },
  ETB: { currency: "ETB", currencySymbol: "", decimal: 2 },
  GEL: { currency: "GEL", currencySymbol: "ლ", decimal: 2 },
  GHS: { currency: "GHS", currencySymbol: "¢", decimal: 2 },
  GMD: { currency: "GMD", currencySymbol: "D", decimal: 2 },
  GTQ: { currency: "GTQ", currencySymbol: "Q", decimal: 2 },
  HNL: { currency: "HNL", currencySymbol: "L", decimal: 2 },
  HTG: { currency: "HTG", currencySymbol: "G", decimal: 2 },
  IDR: { currency: "IDR", currencySymbol: "Rp", decimal: 2 },
  IRR: { currency: "IRR", currencySymbol: "﷼", decimal: 2 },
  KGS: { currency: "KGS", currencySymbol: "", decimal: 2 },
  KHR: { currency: "KHR", currencySymbol: "៛", decimal: 2 },
  KMF: { currency: "KMF", currencySymbol: "CF", decimal: 0 },
  KZT: { currency: "KZT", currencySymbol: "〒", decimal: 2 },
  LAK: { currency: "LAK", currencySymbol: "₭", decimal: 2 },
  LSL: { currency: "LSL", currencySymbol: "L", decimal: 2 },
  MAD: { currency: "MAD", currencySymbol: "د.م", decimal: 2 },
  MDL: { currency: "MDL", currencySymbol: "L", decimal: 2 },
  MGA: { currency: "MGA", currencySymbol: "", decimal: 2 },
  MKD: { currency: "MKD", currencySymbol: "", decimal: 2 },
  MMK: { currency: "MMK", currencySymbol: "K", decimal: 2 },
  MNT: { currency: "MNT", currencySymbol: "₮", decimal: 2 },
  MOP: { currency: "MOP", currencySymbol: "P", decimal: 2 },
  MVR: { currency: "MVR", currencySymbol: "Rf", decimal: 2 },
  MWK: { currency: "MWK", currencySymbol: "MK", decimal: 2 },
  MYR: { currency: "MYR", currencySymbol: "RM", decimal: 2 },
  MZN: { currency: "MZN", currencySymbol: "MTn", decimal: 2 },
  NGN: { currency: "NGN", currencySymbol: "₦", decimal: 2 },
  NIO: { currency: "NIO", currencySymbol: "C$", decimal: 2 },
  PAB: { currency: "PAB", currencySymbol: "B/.", decimal: 2 },
  PEN: { currency: "PEN", currencySymbol: "S/.", decimal: 2 },
  PGK: { currency: "PGK", currencySymbol: "K", decimal: 2 },
  PHP: { currency: "PHP", currencySymbol: "₱", decimal: 2 },
  PYG: { currency: "PYG", currencySymbol: "₲", decimal: 0 },
  QAR: { currency: "QAR", currencySymbol: "﷼", decimal: 2 },
  RON: { currency: "RON", currencySymbol: "L", decimal: 2 },
  RSD: { currency: "RSD", currencySymbol: "din", decimal: 2 },
  SAR: { currency: "SAR", currencySymbol: "﷼", decimal: 2 },
  SLL: { currency: "SLL", currencySymbol: "Le", decimal: 2 },
  SZL: { currency: "SZL", currencySymbol: "L", decimal: 2 },
  TJS: { currency: "TJS", currencySymbol: "ЅM", decimal: 2 },
  TMT: { currency: "TMT", currencySymbol: "m", decimal: 2 },
  TOP: { currency: "TOP", currencySymbol: "T$", decimal: 2 },
  TRY: { currency: "TRY", currencySymbol: "TRY ₺.", decimal: 2 },
  UAH: { currency: "UAH", currencySymbol: "₴", decimal: 2 },
  UZS: { currency: "UZS", currencySymbol: "", decimal: 2 },
  VND: { currency: "VND", currencySymbol: "₫", decimal: 0 },
  VUV: { currency: "VUV", currencySymbol: "VT", decimal: 0 },
  WST: { currency: "WST", currencySymbol: "T", decimal: 2 },
  XOF: { currency: "XOF", currencySymbol: "", decimal: 0 },
  YER: { currency: "YER", currencySymbol: "﷼", decimal: 2 },
  ZMW: { currency: "ZMW", currencySymbol: "ZK", decimal: 2 },
  BHD: { currency: "BHD", currencySymbol: "ب.د", decimal: 3 },
  IQD: { currency: "IQD", currencySymbol: "ع.د", decimal: 3 },
  JOD: { currency: "JOD", currencySymbol: "د.ا", decimal: 3 },
  KWD: { currency: "KWD", currencySymbol: "د.ك", decimal: 3 },
  OMR: { currency: "OMR", currencySymbol: "ر.ع.", decimal: 3 },
  TND: { currency: "TND", currencySymbol: "د.ت", decimal: 3 },
};
