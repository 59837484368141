import { ActionTypes, IState } from "./types";
import { CLEAR_STANDING_STORE, UPDATE_DATA, initialState } from "./constants";

const reducer = (prevState: IState, action: ActionTypes) => {
  const state = prevState || initialState;

  switch (action.type) {
    case UPDATE_DATA:
      return { ...state, ...action.payload };
    case CLEAR_STANDING_STORE:
      return initialState;
    default:
      return state;
  }
};

export default reducer;
