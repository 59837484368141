import {
  all,
  call,
  debounce,
  fork,
  put,
  select,
  takeEvery,
  takeLatest,
} from "redux-saga/effects";

import {
  fetchDataFailure,
  fetchDataRequest,
  fetchDataSuccess,
  fetchDetailsSuccessAction,
  fetchFeatureDetailsSuccessAction,
} from "./actions";
import {
  FETCH_DETAILS,
  FETCH_FEATURE_DETAILS,
  FETCH_TRIGGER,
  FILTER_RESET,
  FILTER_TEXT_UPDATE,
  FILTER_UPDATE,
  PAGINATION_UPDATE,
  SORT_UPDATE,
} from "./constants";
import SKUsAPIService from "../../services/api/SKU";
import { selectSKUPayload } from "./selectors";
import { IFetchDetailsAction, IFetchFeatureDetailsAction } from "./types";

export function* getSearchResults() {
  try {
    yield put(fetchDataRequest());

    const params = yield select(selectSKUPayload);
    const data = yield call([SKUsAPIService, SKUsAPIService.getAll], params);
    yield put(fetchDataSuccess(data));
  } catch (error) {
    yield put(fetchDataFailure(error));
  }
}

export function* watchGetSearchResults() {
  yield takeEvery(
    [
      FILTER_UPDATE,
      FILTER_RESET,
      PAGINATION_UPDATE,
      FETCH_TRIGGER,
      SORT_UPDATE,
    ],
    getSearchResults,
  );
}

export function* debounceSearch() {
  yield debounce(
    500,
    (action: any) => {
      return action.type === FILTER_TEXT_UPDATE && action.payload.valid;
    },
    getSearchResults,
  );
}

function* fetchSKUDetail({ payload }: IFetchDetailsAction) {
  try {
    const skuDetails = yield call(
      [SKUsAPIService, SKUsAPIService.getDetails],
      payload.skuId,
    );
    yield put(fetchDetailsSuccessAction(skuDetails));
  } catch (error) {
    yield put(fetchDataFailure(error));
  }
}

function* fetchFeatureSKUDetail({ payload }: IFetchFeatureDetailsAction) {
  try {
    const skuDetails = yield call(
      [SKUsAPIService, SKUsAPIService.getDetails],
      payload.skuId,
    );
    yield put(fetchFeatureDetailsSuccessAction(skuDetails));
  } catch (error) {
    yield put(fetchDataFailure(error));
  }
}

function* watchGetSkuDetails() {
  yield takeLatest(FETCH_DETAILS, fetchSKUDetail);
}

function* watchGetFeatureSkuDetails() {
  yield takeLatest(FETCH_FEATURE_DETAILS, fetchFeatureSKUDetail);
}

export function* skuMappingSaga() {
  yield all([
    fork(watchGetSearchResults),
    fork(debounceSearch),
    fork(watchGetSkuDetails),
    fork(watchGetFeatureSkuDetails),
  ]);
}
