import { useCallback, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";

const PATHNAME_KEY = "ams:returnTo";
const DEFAULT_ROUTE_PATH = "/";

export function useReturnToLocation() {
  const location = useLocation();
  const pathnameRef = useRef(location.pathname);
  const pathname = sessionStorage.getItem(PATHNAME_KEY) || DEFAULT_ROUTE_PATH;

  useEffect(() => {
    pathnameRef.current = location.pathname;
  }, [location.pathname]);

  const saveCurrentLocation = useCallback(() => {
    sessionStorage.setItem(PATHNAME_KEY, pathnameRef.current);
  }, []);

  return { pathname, saveCurrentLocation };
}
